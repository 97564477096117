import { ModalModuleState, ModalModuleEvents } from "../../store/StoreModules/ModalModule";
import { ModalType, ModalPropsTypeMap } from "../../store/StoreModules/ModalModule";
import { useCallback } from "react";
import { useStoreon } from "storeon/react";

type UseModalControls = {
    openModal<MType extends ModalType>(type: MType, props?: ModalPropsTypeMap<MType>): void;
};

const useModal = (): UseModalControls => {
    const { dispatch } = useStoreon<ModalModuleState, ModalModuleEvents>();

    const openModal = useCallback(
        <MType extends ModalType>(type: MType, props: ModalPropsTypeMap<MType>) => {
            dispatch("modal/set/props", props);
            dispatch("modal/set/type", type);
            dispatch("modal/toggle", true);
        },
        [dispatch]
    );

    return { openModal };
};

export default useModal;
