import React from "react";
import Layout from "../components/Common/Layout";
import Home from "../components/Home";

const HomePage: React.FunctionComponent = () => {
    return (
        <Layout>
            <Home />
        </Layout>
    );
};

export default HomePage;
