import React from 'react'
import { SelectProps } from "@mui/material/Select/Select";
import { MenuItem, Select } from "@mui/material"
import { SelectItem } from '../../constants';

interface PropsTypes extends SelectProps {
  options: Array<SelectItem>;
}

const BaseSelect: React.FunctionComponent<PropsTypes> = ({
  options = [],
  onChange,
  value,
  label,
  name,
  ...props
}) => {
  return (
    <Select
      sx={{ }}
      size="small"
      margin='dense'
      labelId={name}
      id={name}
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      {...props}
    >
      {options.map((item) => <MenuItem key={item?.id} value={item?.value}>{item?.name}</MenuItem>)}
    </Select>
  )
}

export default BaseSelect