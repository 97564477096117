import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabsProps {
  children?: React.ReactNode;
  tabs: Array<String>;
  value: number;
  handleChangeTabs: any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props: TabsProps) {
  const { children, tabs, handleChangeTabs, value } = props;

  return (
    <Box sx={{ width: '100%', marginBottom: "60px" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
          {tabs.map((el, idx) => {
            return (
              <Tab key={`tab-${idx}`} label={el} {...a11yProps(idx)} />
            )
          })}
        </Tabs>
      </Box>
      {children}
    </Box>
  );
}