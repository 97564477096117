import {Button, DialogActions, DialogContent} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {WithModalComponentProps} from '../../../store/StoreModules/ModalModule';
import {useLoading} from '../../../hooks';
import api from '../../../api';
import RichTextEditor from '../../Common/Input/RichTextEditor';
import {useLocation} from 'react-router-dom';
import {ContextHelp} from '../../../api/models';

type ModalProps = {
    type: "view" | "edit",
    onUpdate():void
}
type DialogContextProps = WithModalComponentProps<ModalProps>

const ContextHelpDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { type = "view", onUpdate} = modalProps
    const { pathname } = useLocation()
    const { enqueueSnackbar } = useSnackbar();
    const [content, setContent] = useState("");
    const [context, setContext] = useState<ContextHelp | null | undefined >(undefined)

    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const handleGetContextHelpList = async () => {
        setSubmitLoadingStatus("loading");
        try {
            const data = await api.getContextHelpList({url:pathname})
            const context = data.results.length ? data.results[0] : null
            setContext(context)
        } catch (err:any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка при загрузки справки", { variant: "error" });
        }
        setSubmitLoadingStatus("loaded");
    }

    const handleCreateContextHelp = async () => {
        setSubmitLoadingStatus("loading");
        try {
            await api.createContextHelp({url: pathname, content})
            enqueueSnackbar("Справка успешно добавлена", {
                variant: "success",
            });
            onClose();
            await onUpdate()
        } catch (err:any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка при добавлении справки", { variant: "error" });
        }
        setSubmitLoadingStatus("loaded");
    }


    const handleUpdateContextHelp = async () => {
        if(!context) return
        setSubmitLoadingStatus("loading");
        try {
            await api.updateContextHelp(context.id, content);
            enqueueSnackbar("Справка успешно обновлена", {
                variant: "success",
            });
            onClose();
            await onUpdate()
        } catch (err:any){
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка при обновлении справки", { variant: "error" });
        }
        setSubmitLoadingStatus("loaded");
        }

    const handleDeleteContextHelp = async () => {
        if (!context) return;
        setSubmitLoadingStatus("loading");
        try {
            await api.deleteContextHelp(context.id);
            enqueueSnackbar("Справка успешно удалена", {
                variant: "success",
            });
            onClose();
            await onUpdate();
        } catch (err:any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка при удалении справки", { variant: "error" });
            }
        setSubmitLoadingStatus("loaded");
        }

    const action = context ? handleUpdateContextHelp : handleCreateContextHelp;

    useEffect(()=> {
        handleGetContextHelpList()
    },[pathname])

    if(context === undefined)return null

    return (
        <>
            <DialogContent>
                    <RichTextEditor
                        name="context_help"
                        height={600}
                        disable={type === "view"}
                        showToolbar={type !== "view"}
                        value={context ? context.content : ""}
                        onChange={setContent}
                    />
            </DialogContent>
            <DialogActions>
                {type === "edit" && (
                    <Button
                        color="secondary"
                        disabled={!!!context || submitLoadingStatus === "loading"}
                        onClick={handleDeleteContextHelp}
                        variant="outlined"
                        style={{ marginRight: "auto" }}
                    >
                        Удалить справку
                    </Button>
                )}

                <Button color={"primary"} onClick={onClose}>
                    Отмена
                </Button>

                {type === "edit" && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={action}
                        disabled={submitLoadingStatus === "loading"}
                    >
                        Сохранить
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default ContextHelpDialogContext;
