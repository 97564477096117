import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import ReturnTable from "../Return";
import FormControl from "../../Common/FormControl";
import { useModal } from "../../../hooks";
import dayjs from "../../../utils/dayjs";
import { OrderStatus } from "../../../api/models/Order.model";
import { Order } from "../../../api/models";
import api from "../../../api";
import { TableCellStyles, TableHeadStyles } from "../../../styles";

interface RowPropTypes {
    row: Order,
    updateData(): void
}

const Row: React.FunctionComponent<RowPropTypes> = ({ row, updateData }) => {
    const { openModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [disabledBtn, setDisabledBtn] = useState<any>(true);
    
    useEffect(() => {
        const { items } = row;
        const isNotReceivedElem = items.find(el => !el.isReceived)
        const disabled = isNotReceivedElem ? true : false;
        setDisabledBtn(disabled);
    }, [row])

    const handleReadyAssemble = () => {
        openModal("ASSEMBLE_ORDER", { id: row.id, email: row.email, onUpdate: updateData });
    }

    const handleAcceptProduct = ({ id }) => {
        api.updateOrderProduct({ id, is_received: true })
            .then(res => {
                updateData();
                enqueueSnackbar("Товар успешно подтвержден", { variant: "success" });
            })
            .catch(err => {
                const { data } = err?.response;
                enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
            })
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.orderNumber}
                </TableCell>
                <TableCell align="left">{row.cityName}</TableCell>
                <TableCell align="left">{row.deliveryServicesDisplay}</TableCell>
                <TableCell align="left">{row.deliveryMethodDisplay}</TableCell>
                <TableCell align="right">
                    {row.status === OrderStatus.pending_product ? (
                        <Button disabled={disabledBtn} onClick={handleReadyAssemble}>
                            Готов к сборке
                        </Button>
                    ) : null}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Артикул</TableCell>
                                        <TableCell>Штрих-код</TableCell>
                                        <TableCell>Склад</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items.map((item => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>{item?.product?.attr?.sku?.value || ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{item.store}</TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        disabled={item.isReceived}
                                                        onClick={() => handleAcceptProduct({ id: item.id })}>
                                                        {!item.isReceived ? "Принять товар" : "Товар принят"}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const Acceptance: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(dayjs().format('YYYY-MM-DD'));

    const { data } = useQuery(
        ['invoice_dates'],
        api.getInvoiceDates
    );

    const handleDate = (e) => setCurrentDate(e.target.value);

    const { data: orders, refetch } = useQuery(
        ['acceptance-orders', currentDate],
        () => api.getAllOrders({
            invoice__created_at: currentDate,
            page_size: 100,
        })
    )

    return (
        <div>
            <h1>Приемка</h1>
            <Box sx={{ maxWidth: 250, marginBottom: 3 }}>
                <FormControl name="date" label="Выберите дату">
                    <Select
                        labelId="date"
                        id="demo-simple-select"
                        value={currentDate}
                        label="Выберите дату"
                        onChange={handleDate}
                    >
                        {data?.dates.map((item) => {
                            return (
                                <MenuItem key={item.created_at} value={item.created_at}>
                                    {dayjs(item.created_at).format('DD.MM.YYYY')} - {item.count}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Box>
            <TableContainer sx={{ marginBottom: 5 }} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{ ...TableHeadStyles }}>
                        <TableRow>
                            <TableCell sx={{ ...TableCellStyles }} />
                            <TableCell sx={{ ...TableCellStyles }} align="left">Заказ</TableCell>
                            <TableCell sx={{ ...TableCellStyles }} align="left">Город</TableCell>
                            <TableCell sx={{ ...TableCellStyles }} align="left">Способ доставки</TableCell>
                            <TableCell sx={{ ...TableCellStyles }} align="left">Способ оплаты</TableCell>
                            <TableCell sx={{ ...TableCellStyles }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders?.results?.length ? (
                            <>
                                {orders?.results.map((row) => (
                                    <Row updateData={refetch} key={row.id} row={row} />
                                ))}
                            </>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">Ничего не найдено</TableCell>
                            </TableRow>
                        )}
                        
                    </TableBody>
                </Table>
            </TableContainer>
            <ReturnTable />
        </div>
    );
};

export default Acceptance;
