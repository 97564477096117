import { Card, CardContent, CardActions, Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import { Menu } from "../../constants";
import { useAuth } from "../../hooks";

const Home: React.FunctionComponent = () => {
    const { permissions } = useAuth();

    return (
        <Grid container spacing={2}>
            {Menu.map((item) => {
                if (!permissions?.permissions.includes(item.permission)) return null;

                return (
                    <Grid key={item.url} item xs={6} md={4} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.title}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" href={item.url}>
                                    Перейти
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Home;
