import React from 'react'
import { Box, Typography, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Row from '../Row';
import { Order } from '../../../api/models';

interface PropsTypes {
  order: Order;
  children?: any;
}

const ConsignmentNote: React.FunctionComponent<PropsTypes> = ({ order, children }) => {
  return (
    <Card variant="outlined" sx={{ padding: "10px", paddingTop: "0", marginTop: "20px" }}>
      <Row>
        <Box sx={{ marginTop: "0" }} gridColumn="span 12">
          <Typography variant='h6'>Накладная для отгрузки товара</Typography>
        </Box>
        <Row>
          <Box gridColumn="span 3"><b>Заказ №</b></Box>
          <Box gridColumn="span 9">{order.orderNumber}</Box>
        </Row>
        <Row>
          <Box gridColumn="span 3"><b>Получатель</b></Box>
          <Box gridColumn="span 9">{order.fullName}</Box>
        </Row>
        <Row>
          <Box gridColumn="span 3"><b>E-mail</b></Box>
          <Box gridColumn="span 9">{order.email}</Box>
        </Row>
        <Row>
          <Box gridColumn="span 3"><b>Телефон</b></Box>
          <Box gridColumn="span 9">{order.phone}</Box>
        </Row>
        <Row>
          <Box gridColumn="span 9">
            <TableContainer sx={{ marginTop: "20px" }} variant="outlined" component={Card}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Артикул</TableCell>
                    <TableCell>Количество</TableCell>
                    <TableCell>Цена</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.items && order.items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row?.product?.attr?.sku?.value}</TableCell>
                      <TableCell>{row.count}</TableCell>
                      <TableCell>{row.salePrice} руб.</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Row>
      </Row>
      {children}
    </Card>
  )
}

export default ConsignmentNote
    