import React from 'react';
import Layout from '../components/Common/Layout';
import Courier from '../components/Courier';


const CourierPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <Courier/>
        </Layout>
    );
};

export default CourierPage;
