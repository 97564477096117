import React from "react";
import { AppBar as MuiAppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu, Quiz } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { Settings } from "../../../constants";
import {useAuth, useModal, useTable} from '../../../hooks';
import UserMenu from "./UserMenu";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: any) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${Settings.DRAWER_WIDTH}px)`,
        marginLeft: `${Settings.DRAWER_WIDTH}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

type HeaderProps = {
    open: boolean;
    onOpenSidebar(e: React.MouseEvent): void;
    disableSidebar: boolean;
};

const Header: React.FunctionComponent<HeaderProps> = ({
    open,
    onOpenSidebar,
    disableSidebar,
}: HeaderProps) => {
    const { isAuthenticated, user, deauthenticate,permissions } = useAuth();
    const { openModal } = useModal();
    const { updateData } = useTable();
    const type = permissions?.is_superuser ? "edit" : "view";
    const onContextHelperClick = () => {
        openModal("CONTEXT_HELPER", { type, onUpdate:updateData })
    }
    return (
        <AppBar position="fixed" open={!disableSidebar && open}>
            <Toolbar>
                {!disableSidebar ? (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onOpenSidebar}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <Menu />
                    </IconButton>
                ) : null}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {Settings.APP_NAME}
                </Typography>
                {isAuthenticated ? (
                    <IconButton color="inherit" onClick={onContextHelperClick}>
                        <Quiz/>
                    </IconButton>
                ): null}
                {!isAuthenticated ? (
                    <Button color="inherit" component={NavLink} to="/login">
                        Войти
                    </Button>
                ) : null}
                {isAuthenticated ? (
                    <UserMenu email={user?.email || ""} onLogout={deauthenticate} />
                ) : null}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
