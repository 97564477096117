import { Exclude, Expose, Transform, Type } from "class-transformer";
import dayjs from "../../utils/dayjs";

@Exclude()
class CreatePayment {
    @Expose() order: number;

    @Expose() price: number;

    @Expose() status: string;

    @Expose() notes: string;

    @Expose({ name: "paymentMethod" }) payment_method: string;

    @Expose({ name: "paymentUuid" }) payment_uuid: string;

    @Expose({ name: "paymentDate" })
    @Transform(({ value }) => {
      const date = new Date(value)
      return dayjs(date).format("DD.MM.YYYY")
    })
    date_of_payment: string;
}

export default CreatePayment;
