import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Order, { OrderStatus } from "../../../api/models/Order.model";

type ModalProps = { orderNumber: string; onUpdate(): void };

type UndeliveredOrderModalProps = WithModalComponentProps<ModalProps>;

const schema = yup.object({
    commentaryForManager: yup.string().required("Обязательное поле"),
}).required();

type UpdateOrderDetail = Partial<Order>

const UndeliveredOrderModal: React.FunctionComponent<UndeliveredOrderModalProps> = ({
    onClose,
    modalProps,
}: UndeliveredOrderModalProps) => {
    const { id, orderNumber, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<UpdateOrderDetail>({
        resolver: yupResolver(schema),
        defaultValues: {
            commentaryForManager: "",
            status: OrderStatus.undelivered
        }
    });

    const handleSave: SubmitHandler<UpdateOrderDetail> = async (data) => {
        if (!id) return;

        try {
            await api.updateOrderDetail(id, data);
            enqueueSnackbar("Статус успешно изменен", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Закрыть заказ пользователя с номером <b>{orderNumber}</b>?
                </Typography>
                <Box gridColumn="span 9" sx={{ marginTop: "30px" }}>
                    <TextField
                        rows={3}
                        multiline
                        fullWidth
                        label="Комментарий для менеджера"
                        {...register("commentaryForManager", { required: true })}
                        error={Boolean(errors.commentaryForManager?.message)}
                        helperText="По какой причине заказ не был доставлен?"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSubmit(handleSave)} color="primary" variant="contained">
                    Не доставлен
                </Button>
            </DialogActions>
        </>
    );
};

export default UndeliveredOrderModal;
