import React from "react";
import { Box } from "@mui/material";

interface RowProps {
  children?: React.ReactNode;
  other?: object;
  columnGap?: number;
}

const Row: React.FunctionComponent<RowProps> = ({ children, other, columnGap = 1 }) => {
  
  return (
    <Box
      display="grid"
      gridColumn="span 12"
      columnGap={columnGap}
      alignItems="center"
      gridTemplateColumns="repeat(12, 1fr)"
      marginTop='20px'
      {...other}
    >
      {children}
    </Box>
  )
}

export default Row