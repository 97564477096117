import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import api from "../../../api";
import { Order } from "../../../api/models";
import { Paths } from "../../../constants";
import { useModal, useTable } from "../../../hooks";
import dayjs from "../../../utils/dayjs";
import DataTable, { DataTableColumn, DataTableProps } from "../../Common/DataTable";
import Row from "../../Common/Row";
import { TableHeadStyles, } from '../../../styles';
import {numToRubFormat} from '../../../utils';

const CashlessTable: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();

    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "order_number",
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "site",
            title: "Сайт"
        },
        {
            field: "created_at",
            title: "Дата создания",
            render: (rowData) => Order.dateFormat(rowData.createdAt),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "full_name",
            title: "Полное имя клиента",
            render: (rowData) => rowData.fullName,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "email",
            title: "Email",
        },
        {
            field: "phone",
            title: "Телефон",
        },
        {
            field: "total_price",
            title: "Сумма",
            render: (rowData) => numToRubFormat(rowData.totalPrice),
        },
        {
            field: "status",
            title: "Статус",
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        {
            field: "payment_method",
            title: "Способ оплаты",
            render: (rowData) => rowData.paymentMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_method",
            title: "Способ доставки",
            render: (rowData) => rowData.deliveryMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            title: "Сервис доставки",
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_date",
            title: "Дата и время доставки",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{dayjs(rowData.deliveryDate).format("DD.MM.YYYY")}</div>
                        <div>{dayjs(rowData.deliveryTimeFrom).format("HH:mm")} - {dayjs(rowData.deliveryTimeTo).format("HH:mm")}</div>
                    </div>
                );
            },
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "address",
            title: "Адрес доставки",
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.cityDisplay},</div>
                        <div>{rowData.street},</div>
                        <div>д. {rowData.house}</div>
                        {rowData.hullBuilding && <div>корп. {rowData.hullBuilding}</div>}
                        <div>кв/офис {rowData.apartmentOffice}</div>
                    </div>
                );
            },
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
    ];

    const DetailPanel: DataTableProps<Order>["detailPanel"] = ({ rowData }) => {
        return (
            <>
                {rowData.items.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <img
                                        src={item.product?.attr?.images?.display?.[0]}
                                        height="100px"
                                        width="100px"
                                        alt="Фото товара"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    Кол-во: {item.count}
                                </Grid>
                                <Grid item xs={2}>
                                    Артикул: {item.product?.attr?.sku?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Размер: {item.product?.attr?.size?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Цвет: {item.product?.attr?.color?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    {`Скидка: ${numToRubFormat(0)}`}
                                </Grid>
                                <Grid item xs={2}>
                                    {`Цена продажи: ${numToRubFormat(item.salePrice)}`}
                                </Grid>
                            </Grid>
                            <Divider />
                        </React.Fragment>
                    )
                })}
                <Grid sx={{ marginTop: "20px" }} container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Ранее было куплено товаров на сумму: {numToRubFormat(rowData.sumPastOrders)}</Typography>
                    </Grid>
                    {rowData.commentaryForClient && rowData.commentaryForClient !== "" && (
                        <>
                            <Grid item xs={12}>Комментарий для пользователя:</Grid>
                            <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={12}>{rowData.commentaryForClient}</Grid>
                        </>
                    )}
                    {rowData.commentaryForManager && rowData.commentaryForManager !== "" && (
                        <>
                            <Grid item xs={12}>Комментарий для менеджера:</Grid>
                            <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={12}>{rowData.commentaryForManager}</Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    };

    const getOrders = (params) => api.getAllOrders({ ...params, status: 12, })

    return (
        <>
            <Row>
                <Box gridColumn="span 2">
                    <Button variant="outlined" href={Paths.CASHLESS_PAID.path}>Оплачены по безналу</Button>
                </Box>
                <Box gridColumn="span 2">
                    <Button variant="outlined" href={Paths.PAYMENT_INVOICES.path}>Оплата по счетам</Button>
                </Box>
            </Row>
            <div style={{ marginTop: "30px" }}>
                <DataTable<Order>
                    title="Ожидает оплаты"
                    columns={columns}
                    fetchFn={getOrders}
                    detailPanel={DetailPanel}
                    sorting
                    filtering={false}
                    options={{
                        headerStyle: { ...TableHeadStyles }
                    }}
                />
            </div>
            
        </>
    );
};

export default CashlessTable;
