import React from "react";
import { Divider, Drawer, IconButton, List } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { Menu, Settings } from "../../../constants";
import SidebarListItem from "./SidebarListItem";
import { useAuth } from "../../../hooks";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

type SidebarProps = { open: boolean; onClose(e: React.MouseEvent): void; disableSidebar: boolean };

const Sidebar: React.FunctionComponent<SidebarProps> = ({
    open,
    onClose,
    disableSidebar,
}: SidebarProps) => {
    const { permissions } = useAuth();
    const theme = useTheme();
    const location = useLocation();

    return (
        <Drawer
            sx={{
                width: Settings.DRAWER_WIDTH,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: Settings.DRAWER_WIDTH,
                    boxSizing: "border-box",
                },
            }}
            variant="persistent"
            anchor="left"
            open={!disableSidebar && open}
        >
            <DrawerHeader>
                <IconButton onClick={onClose}>
                    {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {Menu.map((item) => {
                    if (!permissions?.permissions.includes(item.permission)) return null;
                    if (item.isSuperuser) {
                        if (!permissions?.is_superuser) {
                            return null;
                        }
                    }

                    return (
                        <SidebarListItem
                            key={item.url}
                            text={item.title}
                            href={item.url}
                            icon={item.icon}
                            selected={item.url === location.pathname}
                        />
                    );
                })}
            </List>
        </Drawer>
    );
};

export default Sidebar;
