import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";
import { useAuth } from "../../../hooks";

type ModalProps = { email: string; onUpdate(): void };

type DeliveryModalProps = WithModalComponentProps<ModalProps>;

const DeliveryModal: React.FunctionComponent<DeliveryModalProps> = ({
    onClose,
    modalProps,
}: DeliveryModalProps) => {
    const { user } = useAuth();
    const { id, email, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (!id || !user) return;

        try {
            await api.transitionDelivery(id);
            enqueueSnackbar("Успешно передано", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Передать заказ пользователя <b>{email}</b> с номером <b>{id}</b> в доставку?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Передать
                </Button>
            </DialogActions>
        </>
    );
};

export default DeliveryModal;
