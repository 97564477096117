import React from "react";
import { MenuItem, Select } from "@mui/material"
import { useQuery } from "react-query";
import { SelectProps } from "@mui/material/Select/Select";
import FormControl from "../Common/FormControl";
import api from "../../api";

const SiteSelect: React.FunctionComponent<SelectProps> = ({ onChange, value, label, name, ...props }) => {
    const { data } = useQuery(
        [],
        api.getAllSite
    );
    return (
        <FormControl name={name} label={label}>
            <Select
                sx={{ maxHeight: "40px", }}
                margin='dense'
                labelId={name}
                id={name}
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                {...props}
            >
                {data?.results.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export {
    SiteSelect
}