import { Typography, Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Layout from "../components/Common/Layout";

const NotFoundPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <Typography variant="h5">Тут ничего нет</Typography>
            <Typography variant="subtitle1" component="span">
                ты сюда не ходи, ты{" "}
                <Link component={RouterLink} to="/">
                    сюда
                </Link>{" "}
                ходи
            </Typography>
        </Layout>
    );
};

export default NotFoundPage;
