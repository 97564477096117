import { Route, RouteProps, Redirect } from "react-router-dom";
import { Paths } from "../../../constants";
import { useAuth } from "../../../hooks";

const PrivateRoute = (props: RouteProps) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? <Route {...props} /> : <Redirect to={Paths.LOGIN.path} />;
};

export default PrivateRoute;
