import React, { PropsWithChildren, useState } from "react";
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "../../Common/Header";
import Sidebar from "../../Common/Sidebar";
import { Settings } from "../../../constants";
import Modal from "../Modal";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open, disableSidebar }: any) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${Settings.DRAWER_WIDTH}px`,
        ...(!disableSidebar &&
            open && {
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            }),
    })
);

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

type LayoutProps = Partial<{ disableSidebar: boolean }>;

const Layout: React.FC<LayoutProps> = ({
    children,
    disableSidebar = false,
}: PropsWithChildren<LayoutProps>) => {
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Header open={open} onOpenSidebar={handleDrawerOpen} disableSidebar={disableSidebar} />
            <Sidebar open={open} onClose={handleDrawerClose} disableSidebar={disableSidebar} />
            <Main open={open} disableSidebar={disableSidebar}>
                <DrawerHeader />
                <Container maxWidth={false}>{children}</Container>
                <Modal />
            </Main>
        </Box>
    );
};

export default Layout;
