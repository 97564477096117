import React from "react";
import { SelectProps } from "@mui/material/Select/Select";
import BaseSelect from "./BaseSelect";
import FormControl from "../Common/FormControl";
import { PaymentMethods, PaymentMethodsShort, PaymentMethodsFull } from "../../constants";

const OPTIONS = {
    order: PaymentMethods,
    paymentShort: PaymentMethodsShort,
    paymentFull: PaymentMethodsFull
}

interface PropsTypes extends SelectProps {
    variant?: any;
    helperText?: string;
    error?: any;
}

const PaymentSelect: React.FunctionComponent<PropsTypes> = ({
    onChange,
    value,
    label,
    name,
    variant = "order",
    helperText,
    error = false,
    ...props
}) => {

    return (
        <FormControl error={error} name={name} label={label} helperText={helperText}>
            <BaseSelect
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                options={OPTIONS[variant]}
                {...props}
            />
        </FormControl>
    )
}

export {
    PaymentSelect
}