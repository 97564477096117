import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
    HomePage,
    LoginPage,
    NewOrdersPage,
    NotFoundPage,
    LogisticPage,
    ReturnRequestsPage,
    SuperManagerPage,
    IntegrationPage,
    CashlessPage,
    OrderDetailPage,
    OrderChangelogPage,
    OrderPaymentPage,
    AcceptancePage,
    AssemblyPage,
    ReadyDeliveryPage,
    InDeliveryPage,
    CashlessPaidPage,
    CashlessOrderDetailPage,
    CashlessInvoicesDetailPage,
    InDeliveryDeliveryServicesPage,
    InDeliveryPickupPage,
    ReturnRequestCreatePage,
    CourierPage
} from "./pages";
import { Button, CssBaseline, ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import PrivateRoute from "./components/Common/PrivateRoute";
import withAuth from "./hoc/withAuth";
import theme from "./theme";
import GlobalStyles from "./globalStyles";
import { Paths } from "./constants";
import CashlessPaidInvoicesPage from "./pages/CashlessPaidInvoicesPage";
import React, { createRef } from "react";
import { Close } from "@material-ui/icons";

const App: React.FC = () => {
    const notistackRef = createRef<any>();
    const onClickDismiss = key => () => { 
        notistackRef?.current?.closeSnackbar(key);
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <SnackbarProvider
                maxSnack={3}
                ref={notistackRef}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                action={(key) => (
                    <Button onClick={onClickDismiss(key)}>
                        <Close htmlColor="white" />
                    </Button>
                )}
            >
                <BrowserRouter>
                    <Switch>
                        <Route path={Paths.LOGIN.path} component={LoginPage} exact />
                        <PrivateRoute path={Paths.HOME.path} component={HomePage} exact />
                        <PrivateRoute path={Paths.MANAGER.path} component={NewOrdersPage} exact />
                        <PrivateRoute path={Paths.LOGISTIC.path} component={LogisticPage} exact />
                        <PrivateRoute path={Paths.CASHLESS.path} component={CashlessPage} exact />
                        <PrivateRoute path={Paths.ACCEPTANCE.path} component={AcceptancePage} exact />
                        <PrivateRoute path={Paths.ASSEMBLY.path} component={AssemblyPage} exact />
                        <PrivateRoute path={Paths.READY_DELIVERY.path} component={ReadyDeliveryPage} exact />
                        <PrivateRoute path={Paths.IN_DELIVERY.path} component={InDeliveryPage} exact />
                        <PrivateRoute path={Paths.IN_DELIVERY_DELIVERY_SERVICES.path} component={InDeliveryDeliveryServicesPage} exact />
                        <PrivateRoute path={Paths.IN_DELIVERY_PICKUP.path} component={InDeliveryPickupPage} exact />
                        <PrivateRoute
                            path={Paths.RETURN_REQUESTS.path}
                            component={ReturnRequestsPage}
                            exact
                        />
                        <PrivateRoute
                            path={Paths.RETURN_REQUESTS_CREATE.path}
                            component={ReturnRequestCreatePage}
                            exact
                        />
                        <PrivateRoute
                            path={Paths.SUPER_MANAGER.path}
                            component={SuperManagerPage}
                            exact
                        />
                        <PrivateRoute
                          path={Paths.CORE_CMS.path}
                          component={IntegrationPage}
                          exact
                        />
                        <PrivateRoute
                          path={Paths.ORDER_DETAIL.path}
                          component={OrderDetailPage}
                          exact
                        />
                        <PrivateRoute
                            path={Paths.ORDER_CHANGELOG.path}
                            component={OrderChangelogPage}
                            exact
                        />
                        <PrivateRoute
                            path={Paths.ORDER_PAYMENT.path}
                            component={OrderPaymentPage}
                            exact
                        />
                        <PrivateRoute
                            path={Paths.CASHLESS_PAID.path}
                            component={CashlessPaidPage}
                            exact
                        />
                        <PrivateRoute
                            path={Paths.CASHLESS_PAID_ORDER.path}
                            component={CashlessOrderDetailPage}
                            exact
                        />
                        <PrivateRoute
                            path={Paths.PAYMENT_INVOICES.path}
                            component={CashlessPaidInvoicesPage}
                            exact
                        />

                        <PrivateRoute
                            path={Paths.PAYMENT_INVOICES_DETAIL.path}
                            component={CashlessInvoicesDetailPage}
                            exact
                        />
                        <PrivateRoute
                            path={Paths.COURIER.path}
                            component={CourierPage}
                            exact
                        />
                        <Route component={NotFoundPage} />
                    </Switch>
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default withAuth(App);
