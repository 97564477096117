import { Button, Checkbox, FormControlLabel } from "@mui/material";
import React, {useEffect, useState} from "react";
import { apiCore, AttributeTypeMini } from "../../api";
import { useDebouncedEffect } from "../../hooks";

interface Attr extends AttributeTypeMini {
    isFilter?: boolean;
    isSale?: boolean;
    isShowUser?: boolean;
}

interface IProps {
    id: string | number;
    handleChange: (value: Attr[]) => void;
    initUsed: Attr[]
}

const Core: React.FunctionComponent<IProps> = ({id, handleChange, initUsed = []}) => {
    const [attrs, setAttrs] = useState<Attr[]>([])
    const [usedAttrs, setUsedAttrs] = useState<Attr[]>([])

    useDebouncedEffect(() => {
        if(id) {
            apiCore.getAttrs({
                type_id: id
            }).then(data => {
                const ids = initUsed.map((item) => item.id);
                setAttrs(initUsed.length === 0 ? data : data.filter((item) => !ids.includes(item.id)))
                setUsedAttrs(initUsed);
            });
        } else {
            setAttrs([]);
            setUsedAttrs([]);
        }
    }, [id], 300);

    useEffect(() => {
        handleChange(usedAttrs);
    }, [usedAttrs])

    const addToUse = (attr) => {
        setAttrs([...attrs.filter((item) => item.id !== attr.id)]);
        setUsedAttrs([...usedAttrs, attr]);
    }

    const removeUse = (attr) => {
        setUsedAttrs([...usedAttrs.filter((item) => item.id !== attr.id)]);
        setAttrs([...attrs, attr]);
    }

    const updateElement = (index: number, value: any) => {
        const newResult = [...usedAttrs];
        newResult[index] = {...newResult[index], ...value};
        setUsedAttrs(newResult)
    }

    const setAllAttrs = () => {
        setUsedAttrs(usedAttrs.map((item) => {
            return {
                ...item,
                isFilter: true,
                isSale: true,
                isShowUser: true
            }
        }))
    }

    return (
        <div>

            {attrs.map((attr) => {
                return (
                    <div key={attr.id}>
                        <div>{attr.id} - {attr.name} - {attr.display_name}</div>
                        <Button onClick={() => addToUse(attr)}>Использовать</Button>
                    </div>
                )
            })}
            <hr />
            <div><b>Используемые</b></div>
            {usedAttrs.length > 0 ? (
                <Button onClick={setAllAttrs}>Выбрать всё</Button>
            ) : null}
            {usedAttrs.map((attr, index) => {
                return (
                    <div key={attr.id}>
                        <div>{attr.id} - {attr.name} - {attr.display_name}</div>
                        <FormControlLabel control={<Checkbox
                            value={attr.id}
                            checked={Boolean(attr.isFilter)}
                            onChange={() => updateElement(index, { isFilter: !attr.isFilter })}
                        />}
                        label="Участвует в фильтрации"
                        />
                        <FormControlLabel control={<Checkbox
                            value={attr.id}
                            checked={Boolean(attr.isSale)}
                            onChange={() => updateElement(index, { isSale: !attr.isSale })}
                        />} label="Участвует в продаже" />
                        <FormControlLabel control={<Checkbox
                            value={attr.id}
                            checked={Boolean(attr.isShowUser)}
                            onChange={() => updateElement(index, { isShowUser: !attr.isShowUser })}
                        />} label="Показывать пользователю" />
                        <Button onClick={() => removeUse(attr)}>Удалить</Button>
                    </div>
                )
            })}
        </div>
    )
}

export {
    Core
}