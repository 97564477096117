import React from "react";
import CashlessOrderDetail from "../components/CashlessOrderDetail";
import Layout from "../components/Common/Layout";

const CashlessOrderDetailPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <CashlessOrderDetail />
        </Layout>
    );
};

export default CashlessOrderDetailPage;
