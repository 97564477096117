import React from 'react'
import { useParams, generatePath } from 'react-router'
import { Box, Button } from "@mui/material";
import OrderForm from '../../Forms/OrderForm';
import Row from '../Row';
import { Paths } from "../../../constants";

const OrderDetail: React.FunctionComponent<any> = ({ order }) => {
  const { id } : any = useParams();

  if(order === null) {
    return null;
  }

  return (
    <div>
      <Row>
        <Box gridColumn="span 6">
          <h2 style={{ margin: "0" }}>Заказ {order.orderNumber}</h2>
        </Box>
        <Box gridColumn="span 3">
          <Button href={generatePath(Paths.ORDER_PAYMENT.path, { id })} fullWidth variant="outlined">
            Оплата
          </Button>
        </Box>
        <Box gridColumn="span 3">
          <Button
            href={generatePath(Paths.ORDER_CHANGELOG.path, { id })}
            fullWidth
            variant="outlined">
            Журнал
          </Button>
        </Box>
      </Row>
      <OrderForm order={order} />
    </div>
  )
}

export default OrderDetail