import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularPreloader() {
  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center"
      }}>
      <CircularProgress />
    </Box>
  );
}