import {
    Button,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, {useState} from "react";
import { generatePath, useHistory } from "react-router-dom";
import api from "../../../api";
import { Order } from "../../../api/models";
import { useModal, useTable } from "../../../hooks";
import DataTable, { DataTableColumn, DataTableProps, RowAction } from "../../Common/DataTable";
import {useQuery} from "react-query";
import {OrderStatus} from "../../../api/models/Order.model";
import { Paths } from "../../../constants";
import { TableHeadStyles, TableCellStyles, TextNowrapStyle } from '../../../styles';

const LogisticTable: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();
    const [sendExpectDoods, setSendExpectDoods] = useState(false);

    const handleCancelOrder: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CANCEL_ORDER", { id: rowData.id, email: rowData.email, onUpdate: updateData });
    };

    const handleCheckOrder: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        const path = generatePath(Paths.ORDER_DETAIL.path, { id: rowData.id })
        history.push(path)
    };

    const { data, refetch } = useQuery(
        ["sku_for_invoices"],
        () => api.getSkuForInvoices()
    );

    const manyExpectGoods = async () => {
        setSendExpectDoods(true);
        await api.manyExpectGoods();
        await refetch();
        setSendExpectDoods(false);
    }

    const skuInvoices = data?.sku_for_invoices;

    const columns: DataTableColumn<Order>[] = [
        {
            field: "order_number",
            filtering: false,
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "shop",
            filtering: false,
            title: "Магазин",
            render: (rowData) => <div>{rowData.shop?.title}</div>,
        },
        {
            field: "full_name",
            filtering: false,
            title: "Полное имя",
            render: (rowData) => rowData.fullName,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "status",
            filtering: false,
            title: "Статус",
            /** Таблица мутирует исходные данные, поэтому геттер класса вызвать невозможно */
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        {
            field: "payment_method",
            filtering: false,
            title: "Способ оплаты",
            render: (rowData) => rowData.paymentMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "phone",
            filtering: false,
            title: "Телефон",
        },
        {
            field: "delivery_method",
            filtering: false,
            title: "Способ доставки",
            render: (rowData) => rowData.deliveryMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            filtering: false,
            title: "Сервис доставки",
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "date_delivery",
            filtering: false,
            title: "Дата и время доставки",
            render: (rowData) => Order.deliveryDateTime(rowData),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
    ];

    const DetailPanel: DataTableProps<Order>["detailPanel"] = ({ rowData }) => {
        return rowData.items.map((item) => {
            return (
                <React.Fragment key={item.id}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <img
                                src={item.product?.attr?.images?.display?.[0]}
                                height="100px"
                                width="100px"
                                alt="Фото товара"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            Кол-во: {item.count}
                        </Grid>
                        <Grid item xs={2}>
                            Артикул: {item.product?.attr?.sku?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Размер: {item.product?.attr?.size?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Цвет: {item.product?.attr?.color?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Скидка: 0
                        </Grid>
                        <Grid item xs={2}>
                            Цена продажи: {item.salePrice}
                        </Grid>
                    </Grid>
                    <Divider />
                </React.Fragment>
            );
        });
    };

    if (sendExpectDoods) {
        return (
            <div>Отправляем товары в приемку</div>
        )
    }
    return (
        <div>
            <DataTable<Order>
                title="Проверка и заказ сборки"
                columns={columns}
                fetchFn={(params) => api.getAllOrders({ ...params, status__in: [OrderStatus.confirmed, OrderStatus.paid_up].join(',') })}
                detailPanel={DetailPanel}
                sorting
                pageSize={10}
                filtering={false}
                options={{
                    rowStyle: (rowData) => ({
                        backgroundColor: Order.getStatusColor(rowData.createdAt, !!rowData.logistician),
                    }),
                    headerStyle: { ...TableHeadStyles }
                }}
                customActions={[
                    {
                        tooltip: "Уточнить",
                        name: "take",
                        onClick: handleCheckOrder,
                        icon: "settings_backup_restore",
                        color: "primary",
                        // isDisabled: (rowData) => !!rowData.logistician,
                    },
                    {
                        tooltip: "Отменить",
                        name: "cancel",
                        onClick: handleCancelOrder,
                        icon: "cancel",
                        color: "secondary",
                        // isDisabled: (rowData) => rowData.status !== 4,
                    },
                ]}
            />

            <Button onClick={manyExpectGoods} sx={{ marginTop: 3 }} variant={"contained"} size={"large"} color={"success"}>
                Ожидание товаров на складе (накладные сформированы)
            </Button>

            {data ? (
                <>
                    <h2>Товары для накладных</h2>
                    <TableContainer  sx={{ marginTop: 2 }} component={Paper}>
                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                            <TableHead sx={{ ...TableHeadStyles }}>
                                <TableRow>
                                    {skuInvoices?.head.map((item, index) => {
                                        return (
                                            <TableCell sx={{ ...TableCellStyles }} key={index}>{item ? item : 'Нет информации'}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {skuInvoices.body?.length ? (
                                    <>
                                        {skuInvoices?.body.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                {row.map((el, index) => {
                                                    return (
                                                        <TableCell key={index} align="left">{el ? el : 'Нет информации'}</TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}
                                    </>
                                ) : (
                                    <TableRow>
                                       <TableCell colSpan={3} align="center">Ничего не найдено</TableCell> 
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <h2>Склады</h2>
                    {data.store.map((item, index) => {
                        return (
                            <div key={index}>
                                <h3>{item.name}</h3>
                                <TableContainer  sx={{ marginTop: 2 }} component={Paper}>
                                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                        <TableHead sx={{ ...TableHeadStyles }}>
                                            <TableRow>
                                                <TableCell sx={{ ...TableCellStyles }}>Артикул</TableCell>
                                                <TableCell sx={{ ...TableCellStyles }}>Количество</TableCell>
                                                <TableCell sx={{ ...TableCellStyles }}>Заказ</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {item.sku.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left">{row.sku}</TableCell>
                                                    <TableCell align="left">{row.quantity}</TableCell>
                                                    <TableCell align="left">{row.order}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )
                    })}
                    <h2>Товары для комплектации</h2>
                    <div>
                        <TableContainer  sx={{ marginTop: 2 }} component={Paper}>
                            <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                <TableHead sx={{ ...TableHeadStyles }}>
                                    <TableRow>
                                        <TableCell sx={{ ...TableCellStyles }}>Город</TableCell>
                                        <TableCell sx={{ ...TableCellStyles }}>Способ доставки</TableCell>
                                        <TableCell sx={{ ...TableCellStyles }}>Способ оплаты</TableCell>
                                        <TableCell sx={{ ...TableCellStyles }}>Номер заказа</TableCell>
                                        <TableCell sx={{ ...TableCellStyles }}>Артикул</TableCell>
                                        <TableCell sx={{ ...TableCellStyles }}>Штрих-код</TableCell>
                                        <TableCell sx={{ ...TableCellStyles }}>Склад</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.sku_for_picking?.length ? (
                                        <>
                                        {data.sku_for_picking.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row['city']}</TableCell>
                                                <TableCell>{row.delivery_services}</TableCell>
                                                <TableCell>{row.payment_method}</TableCell>
                                                <TableCell>{row.order_number}</TableCell>
                                                <TableCell>{row.sku}</TableCell>
                                                <TableCell>{row.upc}</TableCell>
                                                <TableCell>{row.store}</TableCell>
                                            </TableRow>
                                        ))}
                                        </>
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">Ничего не найдено</TableCell> 
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>

            ) : null}
        </div>
    );
};

export default LogisticTable;
