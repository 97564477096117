import React from 'react';
import {CouriersTable} from '../Tables';


const Courier: React.FunctionComponent = () => {
    return (
        <div>
            <CouriersTable/>
        </div>
    )
};

export default Courier;
