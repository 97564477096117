import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import Input from './Input';
import ruLocale from 'date-fns/locale/ru';

interface TimeProps {
  value: any;
  name: string;
  onChange: any;
  error?: boolean;
  touched?: boolean | undefined;
  label?: string;
  fullWidth?: boolean;
}
const localeMap = {
  ru: ruLocale,
};

export const TimeInput: React.FunctionComponent<TimeProps> = ({ value, name, onChange, error = false, label, fullWidth }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap["ru"]}>
      <TimePicker
        value={value}
        label={label}
        onChange={onChange}
        renderInput={(params) => (
          <Input {...params} fullWidth={fullWidth} name={name} error={error} />
        )}
      />
    </LocalizationProvider>
  );
}