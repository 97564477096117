import React, { useMemo } from "react";
import * as Yup from "yup";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import {useHistory} from "react-router-dom";
import { useSnackbar } from "notistack";
import { Formik, Form, FormikConfig } from "formik";
import BasicTabs from "../../Common/Tabs/BasicTabs";
import DeliveryForm from "../DeliveryForm";
import OrderDetailTable from "../../Tables/OrderDetailTable";
import { PaymentSelect } from "../../Select/Payment"
import { StatusSelect } from "../../Select/Status";
import Row from "../../Common/Row";
import TabPanel from "../../Common/Tabs/TabPanel";
import { PhoneInput } from "../../Common/Input/Phone";
import { OrderDetailTabs, Paths } from "../../../constants";
import { useModal } from "../../../hooks";
import { Order } from "../../../api/models";
import api from "../../../api";
import Input from "../../Common/Input/Input";

interface OrderTypes {
  order: Order;
}

interface FormikTypes {
  apartmentOffice: string;
  cityName: string;
  commentaryForClient: string;
  commentaryForMagazine: string;
  commentaryForManager: string;
  commentToTheCourier: string;
  costOfDelivery: number;
  deliveryDate: any;
  deliveryServices: number;
  deliveryTimeFrom: any;
  deliveryTimeTo: any;
  email: string;
  fullName: string;
  house: number;
  hullBuilding: string;
  index: string;
  paymentMethod: any;
  phone: string;
  region: string;
  shop: number;
  status: any;
  street: string | number;
  totalPrice: any;
  userFullName: string;
  userPhone: string;
}

const OrderSchema = Yup.object().shape({
});

export type OrderFormProps = Pick<FormikConfig<FormikTypes>, "onSubmit">;

const OrderForm = (props: OrderTypes) => {
  const { order } = props;
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [goodsList, setGoodsList] = React.useState<any>(order.items);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const handleSuccessRequest = (message) => {
    enqueueSnackbar(message, { variant: "success" });
  }

  const handleErrorRequest = (err) => {
    const { data } = err?.response;
    enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
  }

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const goBackOrdersTable = (event: React.SyntheticEvent) => {
    history.push(Paths.MANAGER.path)
  }

  const orderCancel = (event: React.SyntheticEvent) => {
    const { id } = order;
    api.transitionCancel(id)
      .then(res => history.push(Paths.MANAGER.path))
      .catch(err => handleErrorRequest(err))
  }

  const submitOrderData = (values: FormikTypes, actions) => {
    api.updateOrderDetail(order.id, values)
      .then(res => {
        actions.setSubmitting(false);
        handleSuccessRequest("Заказ успешно обновлен")
      })
      .catch(err => {
        handleErrorRequest(err)
        actions.setSubmitting(false);
      })
  }

  const updateGoodsList = () => {
    api.getOrderGoodsList({ order: order.id })
      .then(res => setGoodsList(res.results))
      .catch(err => handleErrorRequest(err))
  }

  const openModalAddGoods = () => {
    openModal("ADD_GOODS_ORDER", {
      id: Number(order.id),
      onUpdate: updateGoodsList
    });
  }

  const totalPrice = useMemo(() => goodsList.reduce((prev, curr) => prev += (Number(curr.salePrice) * curr.count), 0), [goodsList])

  return (
    <Formik<FormikTypes>
      initialValues={{
        apartmentOffice: order.apartmentOffice,
        cityName: order.cityName,
        commentaryForClient: order.commentaryForClient,
        commentaryForMagazine: order.commentaryForMagazine,
        commentaryForManager: order.commentaryForManager,
        commentToTheCourier: order.commentToTheCourier,
        costOfDelivery: order.costOfDelivery,
        deliveryDate: order.deliveryDate,
        deliveryServices: order.deliveryServices,
        deliveryTimeFrom: order.deliveryTimeFrom,
        deliveryTimeTo: order.deliveryTimeTo,
        email: order.user.email,
        fullName: order.fullName,
        house: order.house,
        index: order.index,
        hullBuilding: order.hullBuilding,
        paymentMethod: order.paymentMethod,
        phone: order.phone,
        region: order.region,
        shop: order.shopId,
        status: order.status,
        street: order.street,
        totalPrice: totalPrice ?? order.totalPrice,
        userFullName: `${order.user.lastName} ${order.user.firstName}`,
        userPhone: order.user.phone,
      }}
      validationSchema={OrderSchema}
      onSubmit={submitOrderData}
      validateOnMount
      enableReinitialize
    >
      {({ touched, errors, handleChange, setFieldValue, handleSubmit, values, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{marginBottom: "30px"}}
              display="grid"
              gridColumn="span 12"
              columnGap={1}
              rowGap={2}
              gridTemplateColumns="repeat(12, 1fr)"
            >
              <Box gridColumn="span 12">
                <h3 style={{ display: "inline-block", marginBottom: "0"}}>Оплата</h3>
              </Box>
              <Box gridColumn="span 6">
                <PaymentSelect
                  label="Способ оплаты"
                  name="paymentMethod"
                  onChange={handleChange}
                  value={values.paymentMethod}
                />
              </Box>
              <Row>
                <Box gridColumn="span 9">
                  <h3 style={{ margin: "0" }}>Товары</h3>
                </Box>
                <Box gridColumn="span 3">
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={openModalAddGoods}
                    disabled={isSubmitting}
                  >
                    Добавить товар
                  </Button>
                </Box>
              </Row>

              <Box gridColumn="span 12">
                <OrderDetailTable orderId={order.id} updateGoodsList={updateGoodsList} items={goodsList} />
              </Box>
              <Row>
                <Box gridColumn="span 3">
                  <InputLabel>Итоговая сумма</InputLabel>
                </Box>
                <Box gridColumn="span 6">
                  <Input type="number" fullWidth value={values.totalPrice} name="totalPrice" onChange={handleChange} />
                </Box>
              </Row>
              <Box gridColumn="span 12">
                <h3>Получатель</h3>
              </Box>
              <Row>
                <Box gridColumn="span 3">
                  <InputLabel>ФИО</InputLabel>
                </Box>
                <Box gridColumn="span 6">
                  <Input fullWidth value={values.fullName} name="fullName" onChange={handleChange} />
                </Box>
              </Row>
              <Row>
                <Box gridColumn="span 3">
                  <InputLabel>Телефон</InputLabel>
                </Box>
                <Box gridColumn="span 6">
                  <PhoneInput
                    value={values.phone}
                    error={Boolean(errors.phone && touched.phone)}
                    helperText={errors.phone}
                    name="userPhone"
                    onChange={handleChange}
                  />
                </Box>
              </Row>

              <DeliveryForm
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                values={values}
                touched={touched}
                errors={errors}
              />

              <Box gridColumn="span 12">
                <BasicTabs value={value} handleChangeTabs={handleChangeTabs} tabs={OrderDetailTabs}>
                  <TabPanel value={value} index={0}>
                    <Box
                      display="grid"
                      gridColumn="span 12"
                      columnGap={1}
                      rowGap={2}
                      gridTemplateColumns="repeat(12, 1fr)"
                    >
                      <Box gridColumn="span 6">
                        <StatusSelect
                          label="Статус"
                          value={values.status}
                          name="status"
                          onChange={handleChange}
                        />
                      </Box>
                      <Box gridColumn="span 9">
                        <TextField
                          rows={3}
                          value={values.commentaryForMagazine}
                          name="commentaryForMagazine"
                          onChange={handleChange}
                          multiline
                          fullWidth
                          label="Комментарий для журнала"
                        />
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Box
                      display="grid"
                      marginTop="16px"
                      gridColumn="span 9"
                      columnGap={1}
                      rowGap={2}
                      gridTemplateColumns="repeat(9, 1fr)"
                    >
                      <Box gridColumn="span 5">
                        <Input
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          value={values.userFullName}
                          name="userFullName"
                          label="ФИО"
                        />
                      </Box>
                      <Box gridColumn="span 5">
                        <PhoneInput
                          value={values.userPhone}
                          name="userPhone"
                          label="Телефон"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Box>
                      <Box gridColumn="span 5">
                        <Input
                          fullWidth
                          value={values.email}
                          InputProps={{
                            readOnly: true,
                          }}
                          label="E-mail"
                        />
                      </Box>
                    </Box>
                  </TabPanel>
                </BasicTabs>
              </Box>
              <Box gridColumn="span 3">
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Сохранить
                </Button>
              </Box>
              <Box gridColumn="span 3">
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={goBackOrdersTable}
                  disabled={isSubmitting}
                >
                  Отменить изменения
                </Button>
              </Box>
              <Box gridColumn="span 3">
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={orderCancel}
                  disabled={isSubmitting}
                >
                  Отменить заказ
                </Button>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OrderForm
