import React from 'react';
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import Input from '../../Common/Input/Input';
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from '../../../api';
import { Box, Typography } from '@mui/material';
import Row from '../../Common/Row';

type ModalProps = { item: any; orderId?: any; onUpdate(): void; }

const schema = yup.object({
  count: yup.string().required("Обязательное поле"),
  sale_price: yup.string().required("Обязательное поле"),
}).required();

const EditGoodsOrderModal: React.FunctionComponent<WithModalComponentProps<ModalProps>> = ({ onClose, modalProps }) => {

  const { item, orderId, onUpdate } = modalProps;
  const { enqueueSnackbar } = useSnackbar();
  const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
          count: item?.count || "",
          sale_price: item?.salePrice || "",
          order: orderId,
        }
    });
  const handleErrorRequest = (err) => {
    const { data } = err?.response;
    enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
  }

  const handleSuccessRequest = (message = "") => {
    enqueueSnackbar(message, { variant: "success" });
  }

  const onSubmit = (data) => {
    if(!item.id) return false;

    api.updateOrderProduct({ id: item?.id, ...data })
      .then(res => {
        handleSuccessRequest("Товар успешно изменен")
        onClose()
        onUpdate()
      })
      .catch(err => handleErrorRequest(err))
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Typography>
            {item?.title || ""} {item.skuValue ? `(${item.skuValue})` : null}
          </Typography>
          <Row>
            <Box gridColumn="span 6">
              <Input
                fullWidth
                type="number"
                label="Количество"
                {...register("count", { required: true })}
                value={watch("count")}
                helperText={errors.count?.message}
                error={Boolean(errors.count?.message)}
              />
            </Box>
            <Box gridColumn="span 6">
              <Input
                fullWidth
                type="number"
                label="Цена"
                {...register("sale_price", { required: true })}
                value={watch("sale_price")}
                helperText={errors.sale_price?.message}
                error={Boolean(errors.sale_price?.message)}
              />
            </Box>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Отмена</Button>
          <Button type="submit" disabled={isSubmitting} color="primary" variant="contained">
            Сохранить изменения
          </Button>
        </DialogActions>
      </form>
    </>
  )
}

export default EditGoodsOrderModal