import React from "react";
import {generatePath, useHistory} from "react-router";
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import DataTable, {DataTableColumn, DataTableProps, RowAction} from "../../Common/DataTable";
import {useModal, useTable} from "../../../hooks";
import {Paths} from "../../../constants";
import {Order} from "../../../api/models";
import api from "../../../api";
import {OrderStatus} from "../../../api/models/Order.model";
import { TableHeadStyles, } from "../../../styles";
import {numToRubFormat} from '../../../utils';
import Row from "../../Common/Row";

const Assembly: React.FC = () => {
    const {openModal} = useModal();
    const {updateData} = useTable();
    const history = useHistory();

    const handleWaitProcessing: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("WAIT_PROCESSING_ORDER", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
        });
    };

    const handleConfirm: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("READY_DELIVERY", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
        });
    };

    const handleTakeToWork: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("TAKE_TO_WORK", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
            type: "manager",
        });
    };

    const handleCancel: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CANCEL_ORDER", {id: rowData.id, email: rowData.email, onUpdate: updateData});
    };

    const toOrderDetail = async (e, rowData) => {
        const path = generatePath(Paths.ORDER_DETAIL.path, {id: rowData.id})
        history.push(path)
    }

    function printElem(elem) {
        const mywindow = window.open('', 'PRINT', 'height=800,width=800');
        const el = document.getElementById(elem)
        if (mywindow && el) {
            mywindow.document.write(`<html><head><title>${document.title}</title>`);
            mywindow.document.write(`
                <style>
                    .box {
                        text-align: center;
                        max-width: 300px;
                    }
                </style>
            `);
            mywindow.document.write('</head><body>');
            mywindow.document.write('<div class="box">');
            mywindow.document.write(el.innerHTML);
            mywindow.document.write('</div></body></html>');

            mywindow.document.close();
            mywindow.focus();

            mywindow.print();
            mywindow.close();
        }

        return true;
    }

    const barcodePrint = (id) => {
        printElem(`barcode-${id}`)
    }

    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            filtering: false,
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "user_info",
            filtering: false,
            title: "Клиент",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.fullName}</div>
                        <div>{rowData.email}</div>
                        <div>
                            <b>{rowData.phone}</b>
                        </div>
                        <div>{rowData.address}</div>
                    </div>
                );
            },
        },
        {
            field: "email",
            filtering: false,
            title: "Email",
        },
        {
            field: "full_name",
            filtering: false,
            title: "Полное имя",
            render: (rowData) => rowData.fullName,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "order_number",
            filtering: false,
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "status",
            filtering: false,
            title: "Статус",
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        // {
        //     field: "address",
        //     title: "Адрес",
        //     sorting: false,
        // },
        {
            field: "payment_method",
            filtering: false,
            title: "Способ оплаты",
            render: (rowData) => rowData.paymentMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "phone",
            filtering: false,
            title: "Телефон",
        },
        {
            field: "delivery_method",
            filtering: false,
            title: "Способ доставки",
            render: (rowData) => rowData.deliveryMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            filtering: false,
            title: "Сервис доставки",
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "promo_code",
            filtering: false,
            title: "Промокод",
            render: () => "Не указан",
        },
        {
            field: "amount_discount",
            filtering: false,
            title: "Скидка",
            render: (rowData) => numToRubFormat(rowData?.amountDiscount || 0),
        },
        {
            field: "total_price",
            filtering: false,
            title: "Общая цена",
            render: (rowData) => numToRubFormat(rowData.totalPrice),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "created_at",
            filtering: false,
            title: "Дата создания",
            render: (rowData) => Order.dateFormat(rowData.createdAt),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "sum_past_orders",
            filtering: false,
            title: "sum_past_orders",
            render: (rowData) => numToRubFormat(rowData.sumPastOrders),
            hidden: true,
        },
    ];

    const DetailPanel: DataTableProps<Order>["detailPanel"] = ({rowData}) => {
        return (
            <>
                {rowData.items.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <img
                                        src={item.product?.attr?.images?.display?.[0]}
                                        height="100px"
                                        width="100px"
                                        alt="Фото товара"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    Кол-во: {item.count}
                                </Grid>
                                <Grid item xs={2}>
                                    Артикул: {item.product?.attr?.sku?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Размер: {item.product?.attr?.size?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Цвет: {item.product?.attr?.color?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Скидка: 0
                                </Grid>
                                <Grid item xs={2}>
                                    Цена продажи: {item.salePrice}
                                </Grid>
                            </Grid>
                            <Divider/>
                        </React.Fragment>
                    );
                })}
                
                <Row>
                    <Box gridColumn="span 12">
                        <Typography>
                            <b>Комментарии:</b>
                        </Typography>
                    </Box>
                </Row>
                <Divider />
                <Grid container sx={{ marginBottom: "10px", marginTop: "5px" }} alignItems="center" spacing={1}>
                    <Grid item xs={2}>
                        <Typography>
                            Коментарии для пользователя:
                        </Typography>
                    </Grid>
                    <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={2}>
                        {rowData.commentaryForClient}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container sx={{ marginBottom: "10px", marginTop: "5px" }} alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <Typography>
                            Коментарий администратора:
                        </Typography>
                    </Grid>
                    <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={2}>
                        {rowData.commentaryForManager}
                    </Grid>
                </Grid>
                <Divider />
                <Box sx={{marginTop: 2}}>
                    <Button onClick={() => barcodePrint(rowData.id)}>Печать</Button>
                </Box>
                <Box sx={{marginTop: 2, display: 'flex', alignContent: 'start'}}>
                    <Box id={`barcode-${rowData.id}`} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        maxWidth: '500px',
                        border: '1px solid',
                        padding: '10px 15px'
                    }}>
                        <Box>
                            День сурка | Транспортная компания {rowData.deliveryServicesDisplay}
                        </Box>
                        <Box><img width={300} src={rowData.barcode} alt={'Штрихкод'}/></Box>
                        <Box>{rowData.fullName}</Box>
                        <Box>{Order.makeAddress(rowData)}</Box>
                    </Box>
                </Box>
            </>

        );
    };

    return (
        <DataTable<Order>
            title="Сборка"
            columns={columns}
            fetchFn={(params) => api.getAllOrders({...params, status__in: [OrderStatus.assembling].join(',')})}
            detailPanel={DetailPanel}
            onRowClick={toOrderDetail}
            sorting
            filtering={false}
            options={{
                headerStyle: { ...TableHeadStyles }
            }}
            customActions={[
                {
                    tooltip: "Уточнить",
                    name: "take",
                    onClick: toOrderDetail,
                    icon: "settings_backup_restore",
                    color: "primary",
                },
                {
                    tooltip: "Собран",
                    name: "confirm",
                    onClick: handleConfirm,
                    icon: "check",
                    color: "primary",
                },
                {
                    tooltip: "Отменить",
                    name: "cancel",
                    onClick: handleCancel,
                    icon: "cancel",
                    color: "secondary",
                },
            ]}
        />
    );
};

export default Assembly;