import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CircularPreloader from '../CircularPreloader';
import api from '../../../api';
import { Order } from '../../../api/models';
import { LoadingStatuses } from "../../../constants";

interface LayoutProps {
  children?: any;
  other?: object;
}

const OrderDetailLayout: React.FunctionComponent = (props: LayoutProps) => {
  const { id } : any = useParams();
  const { children } = props;
  const [order, setOrder] = useState<Order | null>(null);
  const [status, setStatus] = useState(LoadingStatuses.loading)

  const getOrder = () => {
    setStatus(LoadingStatuses.loading)
    if(id) {
      api.getOrderDetail(id)
        .then(res => {
          setStatus(LoadingStatuses.loaded)
          setOrder(res)
        })
        .catch(err => setStatus(LoadingStatuses.failed))
    }
  }

  useEffect(() => getOrder(), [])

  return (
    <>
      {order && status === LoadingStatuses.loaded && (
        <>{React.cloneElement(children, { order, getOrder })}</>
      )}
      {!order || status === LoadingStatuses.loading && (
        <CircularPreloader />
      )}
      {!order || status === LoadingStatuses.failed && (
        <div>Произошла ошибка загрузки</div>
      )}
    </>
  )

}

export default OrderDetailLayout