import React from 'react';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { Box } from '@mui/material';
import { DateInput } from '../../Common/Input/Date';
import Row from '../../Common/Row';
import { PaymentSelect } from '../../Select/Payment';
import { StatusSelect } from '../../Select/Status';
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from '../../../api';
import Input from '../../Common/Input/Input';
import { PaymentMethodsValues } from '../../../constants';

type ModalProps = {
  onUpdate(): void;
  id?: number | null;
}

const sx = {
  marginTop: "20px"
}

const PaymentSchema = Yup.object().shape({
  paymentDate: Yup.date().nullable().required("Обязательное поле"),
  paymentMethod: Yup.string().required("Обязательное поле"),
  // paymentUuid: Yup.string().required("Обязательное поле"),
  price: Yup.number().min(1, "Сумма должна быть больше 0").required("Обязательное поле"),
  notes: Yup.string().required("Обязательное поле"),
  status: Yup.string().nullable().required("Обязательное поле"),
});

const AddOrderPayment: React.FunctionComponent<WithModalComponentProps<ModalProps>> = ({ onClose, modalProps }) => {

  const { id, onUpdate } = modalProps;
  const { enqueueSnackbar } = useSnackbar();

  const handleErrorRequest = (err, action) => {
    const { data } = err?.response;
    enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
    action.setSubmitting(false);
  }

  const submitPayment = (values, action) => {
    api.createOrderPayment(values)
      .then(res => {
        action.resetForm();
        action.setSubmitting(false);
        onClose();
        onUpdate();
      })
      .catch(err => handleErrorRequest(err, action))
  }

  const formik = useFormik({
    initialValues: {
      order: id,
      paymentDate: null,
      paymentMethod: null,
      paymentUuid: "",
      price: 0,
      notes: "",
      status: null,
    },
    validationSchema: PaymentSchema,
    onSubmit: submitPayment,
  })

  const setDateTime = (value: string, name: string) => {
    formik.setFieldValue(name, value)
  }

  const handleChangeMethod = (e) => {
    formik.handleChange(e);
    formik.setFieldValue("paymentUuid", "")
  }

  if(formik) {
    return (
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ paddingTop: "0" }}>
          <Row>
            <Box gridColumn="span 12">
              <StatusSelect
                variant='payment'
                fullWidth
                label="Статус"
                name="status"
                helperText={formik.touched.status ? formik.errors.status : ""}
                error={Boolean(formik.touched.status && formik.errors.status)}
                value={formik.values.status}
                onChange={formik.handleChange}
              />
            </Box>
            <Box {...sx} gridColumn="span 12">
              <DateInput
                fullWidth
                label="Дата оплаты"
                name="paymentDate"
                helperText={formik.touched.paymentDate ? formik.errors.paymentDate : ""}
                error={Boolean(formik.touched.paymentDate && formik.errors.paymentDate)}
                value={formik.values.paymentDate}
                onChange={val => setDateTime(val, "paymentDate")}
              />
            </Box>
            <Box {...sx} gridColumn="span 12">
              <PaymentSelect
                label="Способ оплаты"
                name="paymentMethod"
                helperText={formik.touched.paymentMethod ? formik.errors.paymentMethod : ""}
                error={Boolean(formik.touched.paymentMethod && formik.errors.paymentMethod)}
                variant="paymentFull"
                value={formik.values.paymentMethod}
                onChange={handleChangeMethod}
              />
            </Box>
            {formik.values.paymentMethod === PaymentMethodsValues.ONLINE ? (
              <Box {...sx} gridColumn="span 12">
                <Input
                  fullWidth
                  label="Удаленный ID"
                  name="paymentUuid"
                  helperText={formik.touched.paymentUuid ? formik.errors.paymentUuid : ""}
                  error={Boolean(formik.touched.paymentUuid && formik.errors.paymentUuid)}
                  value={formik.values.paymentUuid}
                  onChange={formik.handleChange}
                />
              </Box>
            ) : null}
            
            <Box {...sx} gridColumn="span 12">
              <Input
                fullWidth
                label="Сообщение о результате"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
              />
            </Box>
            <Box {...sx} gridColumn="span 12">
              <Input
                fullWidth
                type="number"
                label="Сумма"
                name="price"
                helperText={formik.touched.price ? formik.errors.price : ""}
                error={Boolean(formik.touched.price && formik.errors.price)}
                value={formik.values.price}
                onChange={formik.handleChange}
              />
            </Box>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Отмена</Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            color="primary"
            variant="contained">
            Добавить оплату
          </Button>
        </DialogActions>
      </form>
    )
  }
  return null
}

export default AddOrderPayment