import React from "react";
import { SelectProps } from "@mui/material/Select/Select";
import BaseSelect from "./BaseSelect";
import FormControl from "../Common/FormControl";
import { OrderStatuses, PaymentStatuses } from "../../constants";

const TYPES = {
    order: OrderStatuses,
    payment: PaymentStatuses
}

interface PropsTypes extends SelectProps {
    variant?: any;
    helperText?: string;
    error?: boolean;
}

const StatusSelect: React.FunctionComponent<PropsTypes> = ({
    onChange,
    value,
    label,
    name,
    variant="order",
    helperText,
    error=false,
    ...props
}) => {
    return (
        <FormControl error={error} name={name} label={label} helperText={helperText}>
            <BaseSelect
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                options={TYPES[variant]}
                {...props}
            />
        </FormControl>
    )
}

export {
  StatusSelect
}