import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import { StoreContext } from "storeon/react";
import store from "./store";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <StoreContext.Provider value={store}>
                <App />
            </StoreContext.Provider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
