import { useStoreon } from "storeon/react";
import api from "../../api";
import { User } from "../../api/models";
import { AuthModuleEvents, AuthModuleState } from "../../store/StoreModules/AuthModule";
import Permissions from "../../api/models/Permission.model";

const useAuth = () => {
    const { isAuthenticated, user, permissions, dispatch } = useStoreon<
        AuthModuleState,
        AuthModuleEvents
    >("isAuthenticated", "user", "permissions");

    const setUser = (user: User | null) => {
        dispatch("auth/set/user", user);
    };
    const setPermissions = (permissions: Permissions) => {
        dispatch("auth/set/permissions", permissions);
    };

    const authenticate = () => {
        dispatch("auth/set/is_authenticated", true);
    };

    const deauthenticate = () => {
        api.removeAuthToken();
        api.removeRefreshToken();
        dispatch("auth/set/is_authenticated", false);
        dispatch("auth/set/user", null);
    };

    return {
        isAuthenticated,
        user,
        permissions,
        setUser,
        setPermissions,
        authenticate,
        deauthenticate,
    };
};

export default useAuth;
