import date from './dayjs'

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  try {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } catch (e) {
    return null;
  }
}

function removeCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

const valuesElement = ['Дата создания', 'Дата изменения', 'Дата удаления']

function getData(elem) {
  if(Object.keys(elem.data).length > 0) {
    const data = JSON.parse(elem.data)
    const keys = Object.keys(data)
    const keysValue = {};
    keys.forEach(element => {
      keysValue[element] = [Object.keys(data[element])[0], Object.values(data[element])[0]]
    });
    const readyArr = []
    let actionDelete = false;
    Object.keys(keysValue).forEach(element => {
      let newVal = keysValue[element][1];
      let oldVal = keysValue[element][0];
      if (element === 'ID' && !newVal) {
        actionDelete = true;
      }
      if (valuesElement.includes(element)) {
        newVal = date(keysValue[element][1])
        if (keysValue[element][0] !== 'None' && keysValue[element][0] !== 'null') {
          oldVal = date(keysValue[element][0])
        }
      }
      readyArr.push(
        `${element}: <span style="color: red">${oldVal}</span> <span class='char-arrow'>&rarr;</span> <span style="color: green">${newVal}</span><br />`
      )
    })

    return {
      ...elem,
      changeFormat: readyArr.join(''),
      isDelete: actionDelete,
    }
  }

  return {
    ...elem,
    changeFormat: null
  }
}

function numToRubFormat (n) {
  return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay:'symbol' }).format(n)
}


export {
  setCookie,
  getCookie,
  getData,
  removeCookie,
  numToRubFormat
};
