import React from "react";
import Layout from "../components/Common/Layout";
import InDelivery from "../components/Tables/InDelivery";
import InDeliveryDeliveryServices from "../components/Tables/InDelivery/DeliveryServices";

const InDeliveryDeliveryServicesPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <InDeliveryDeliveryServices />
        </Layout>
    );
};

export default InDeliveryDeliveryServicesPage;
