import * as React from 'react';
import ruLocale from 'date-fns/locale/ru';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Input from './Input';

const localeMap = {
  ru: ruLocale,
};

const maskMap = {
  ru: '__.__.____',
};

interface DateProps {
  value: any;
  name: string;
  onChange: any;
  error?: boolean;
  touched?: boolean | undefined;
  label?: string;
  fullWidth?: boolean;
  helperText?: string;
}

const DateInput: React.FunctionComponent<DateProps> = ({ value, name, onChange, error = false, helperText, label, fullWidth }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap["ru"]}>
      <DatePicker
        label={label}
        value={value}
        mask={maskMap["ru"]}
        onChange={onChange}
        renderInput={(params) => (
          <Input
            {...params}
            error={error}
            fullWidth={fullWidth}
            name={name}
            helperText={helperText}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export { DateInput }