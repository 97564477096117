import CashlessTable from "../Tables/CashlessTable";

const Cashless = () => {
    return (
        <div>
            <CashlessTable />
        </div>
    )
}

export default Cashless;
