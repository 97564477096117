import React, { useEffect, useState } from "react";
import { Autocomplete, Chip } from '@mui/material';
import Input from "../Common/Input/Input";

interface OptionTypes {
  label: string,
  id: number
}

const OrderProductsAutocomplete: React.FunctionComponent<any> = (props) => {
  const [options, setOptions] = useState<any>([]);
  const { setValue, value, name, label = "", id, items, user } = props;

  useEffect(() => {
    if(items) {
      setOptions(items)
    }
  }, [items])

  const handleChange = (event: any, newValue: OptionTypes | any) => {
    if(newValue) {
      setValue(name, newValue)
    }
  }

  const handleDelete = (index) => {
    const val = [...value];
    const firstPart = val.slice(0, index)
    const secondPart = val.slice(index + 1);
    const newValue = firstPart.concat(secondPart)
    setValue(name, newValue)
  }

  return (
    <Autocomplete
      multiple
      size="small"
      disablePortal
      id={id}
      options={options}
      disabled={!user}
      value={value}
      getOptionLabel={option => option?.label}
      onChange={handleChange}
      fullWidth
      disableClearable
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: OptionTypes | any, index: number) => {
          let label = option?.label
          if(typeof option === "number") {
            items.find(el => {
              label = el?.product?.attr?.sku?.value
            });
          }
          return (
            <Chip
              variant="outlined"
              label={label}
              {...getTagProps({ index })}
              onDelete={() => handleDelete(index)}
            />
          )
        })
      }
      renderInput={(params) => (
        <Input
          {...params}
          label={label}
          fullWidth
        />
      )}
    />
  )
}

export default OrderProductsAutocomplete;