import { Divider, Grid } from "@mui/material";
import React from "react";
import api from "../../../api";
import { Order } from "../../../api/models";
import { OrderStatus } from "../../../api/models/Order.model";
import { TableHeadStyles } from "../../../styles";
import DataTable, { DataTableColumn, DataTableProps } from "../../Common/DataTable";
import {numToRubFormat} from '../../../utils';

const SuperManagerTable: React.FC = () => {
    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            title: "#",
            defaultSort: "desc",
            filtering: false,
        },
        {
            field: "user_info",
            title: "Клиент",
            sorting: false,
            filtering: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.fullName}</div>
                        <div>{rowData.email}</div>
                        <div>
                            <b>{rowData.phone}</b>
                        </div>
                        <div>{rowData.address}</div>
                    </div>
                );
            },
        },
        {
            field: "email",
            title: "Email",
            filtering: false,
        },
        {
            field: "full_name",
            title: "Полное имя",
            filtering: false,
            render: (rowData) => rowData.fullName,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "order_number",
            title: "№ Заказа",
            filtering: false,
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "status",
            title: "Статус",
            lookup: Object.values(OrderStatus)
                .filter((key) => typeof key === "number")
                .reduce(
                    (obj, value) => ({
                        ...obj,
                        [value]: Order.getStatusName(value as OrderStatus),
                    }),
                    {}
                ),
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        // {
        //     field: "address",
        //     title: "Адрес",
        //     sorting: false,
        // },
        {
            field: "payment_method",
            title: "Способ оплаты",
            filtering: false,
            render: (rowData) => rowData.paymentMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "phone",
            title: "Телефон",
            filtering: false,
        },
        {
            field: "delivery_method",
            title: "Способ доставки",
            filtering: false,
            render: (rowData) => rowData.deliveryMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            title: "Сервис доставки",
            filtering: false,
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "promo_code",
            title: "Промокод",
            filtering: false,
            render: () => "Не указан",
        },
        {
            field: "amount_discount",
            title: "Скидка",
            filtering: false,
            render: (rowData) => numToRubFormat(rowData?.amountDiscount || 0),
        },
        {
            field: "total_price",
            title: "Общая цена",
            filtering: false,
            render: (rowData) => numToRubFormat(rowData.totalPrice),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "created_at",
            title: "Дата создания",
            filtering: false,
            render: (rowData) => Order.dateFormat(rowData.createdAt),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "sum_past_orders",
            title: "sum_past_orders",
            filtering: false,
            render: (rowData) => numToRubFormat(rowData.sumPastOrders),
            hidden: true,
        },
    ];

    const DetailPanel: DataTableProps<Order>["detailPanel"] = ({ rowData }) => {
        return rowData.items.map((item) => {
            return (
                <React.Fragment key={item.id}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <img
                                src={item.product?.attr?.images?.display?.[0]}
                                height="100px"
                                width="100px"
                                alt="Фото товара"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            Кол-во: {item.count}
                        </Grid>
                        <Grid item xs={2}>
                            Артикул: {item.product?.attr?.sku?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Размер: {item.product?.attr?.size?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Цвет: {item.product?.attr?.color?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Скидка: 0
                        </Grid>
                        <Grid item xs={2}>
                            Цена продажи: {item.salePrice}
                        </Grid>
                    </Grid>
                    <Divider />
                </React.Fragment>
            );
        });
    };

    return (
        <DataTable<Order>
            title="Все заказы"
            columns={columns}
            fetchFn={api.getSuperManagers}
            filtering
            detailPanel={DetailPanel}
            sorting
            options={{
                headerStyle: { ...TableHeadStyles }
            }}
        />
    );
};

export default SuperManagerTable;
