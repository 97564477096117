import { Exclude, Expose, Type } from "class-transformer";

@Exclude()
class Address {
    @Expose() id: number;

    @Expose() address: string;

    @Expose({ name: "apartment_office" }) apartmentOffice: string;

    @Expose() city: number;

    @Expose({ name: "comment_to_the_courier" }) commentToTheCourier: string;

    @Expose({ name: "format_full_address" }) formatFullAddress: string;

    @Expose() house: string;

    @Expose({ name: "is_main" }) isMain: boolean;

    @Expose() street: string;
}

@Exclude()
class User {
    @Expose() id: number;

    @Expose() email: string;

    @Expose() username: string;

    @Expose({ name: "first_name" }) firstName: string;

    @Expose({ name: "last_name" }) lastName: string;

    @Expose() phone: string;

    @Expose({ name: "address_obj" })
    @Type(() => Address)
    address: Address | any;

    @Expose()
    get fullName(): string {
        return `${this.firstName} ${this.lastName}`.trim();
    }
}

export default User;
