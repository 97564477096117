import { StoreonModule } from "storeon";
import { User } from "../../api/models";
import Permissions from "../../api/models/Permission.model";

export interface AuthModuleState {
    isAuthenticated: boolean;
    user: User | null;
    permissions: Permissions | null;
}

export interface AuthModuleEvents {
    "auth/set/user": User | null;
    "auth/set/is_authenticated": boolean;
    "auth/set/permissions": Permissions;
}

export const authModule: StoreonModule<AuthModuleState, AuthModuleEvents> = (store) => {
    store.on("@init", () => ({
        isAuthenticated: false,
        user: null,
        permissions: null,
    }));

    store.on("auth/set/user", (state, user) => ({ user }));

    store.on("auth/set/is_authenticated", (state, isAuthenticated) => ({ isAuthenticated }));

    store.on("auth/set/permissions", (state, permissions) => ({ permissions }));
};
