import React from "react";
import Layout from "../components/Common/Layout";
import ReadyDelivery from "../components/Tables/ReadyDelivery";

const ReadyDeliveryPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <ReadyDelivery />
        </Layout>
    );
};

export default ReadyDeliveryPage;
