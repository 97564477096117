import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";

type ModalProps = { name: string; onUpdate(): void };

type DeleteCourierModalProps = WithModalComponentProps<ModalProps>;

const DeleteCourier: React.FunctionComponent<DeleteCourierModalProps> = ({
                                                                              onClose,
                                                                              modalProps,
                                                                          }) => {
    const { id, name, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (!id) return;

        try {
            await api.deleteCourier(id);
            enqueueSnackbar("Успешно удалено", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Удалить курьера <b>{name}</b> с номером <b>{id}</b>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Удалить
                </Button>
            </DialogActions>
        </>
    );
};

export default DeleteCourier;
