import React, {useState} from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Pagination,
    Typography
} from "@mui/material";
import IntegrationForm from "./Form";
import {useQuery} from "react-query";
import api, {Integration as InterfaceIntegration} from "../../api";
import usePagination from "../../hooks/usePagination";
import {CoreLogin} from "./Login";


const Integration: React.FunctionComponent = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [editObj, setEditObj] = useState<InterfaceIntegration | undefined>(undefined);
    const {page, pageSize, handleChangePage} = usePagination({
        initialPageSize: 16,
    });

    const {data, refetch} = useQuery(
        ["getIntegrations", page, pageSize],
        () => api.getIntegrations({page, page_size: pageSize}),
    );
    const handleClose = async () => {
        setOpen(false);
        setEditObj(undefined);
        await refetch();
    };
    const handleOpen = () => setOpen(true);
    const handleUpdate = (obj: InterfaceIntegration) => {
        handleOpen();
        setEditObj(obj);
    }
    const handleDelete = async (id: string | number) => {
        await api.deleteIntegration(id);
        await refetch();
    }


    return (
        <CoreLogin>
            <div>
                <Button sx={{mb: 2}} onClick={handleOpen} variant="contained">Добавить</Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Добавить элемент</DialogTitle>
                    <DialogContent>
                        {open ? (
                            <IntegrationForm initData={editObj ? editObj : undefined} handleSuccess={handleClose}/>
                        ) : null}
                    </DialogContent>
                </Dialog>
                <Grid container spacing={2}>
                    {data?.results.map((item) => {
                        return (
                            <Grid key={item.id} item xs={6} md={3} lg={3}>
                                <Card>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            ID типа сущности - {item.core_id}
                                        </Typography>
                                    </CardContent>
                                    <CardContent>
                                        <Typography component="div">
                                            Модель в CMS - {item.content_type}, Сайт - {item?.site_name || ''}
                                        </Typography>
                                    </CardContent>
                                    <CardActions disableSpacing={true}>
                                        <Button size="small" onClick={() => {
                                            handleUpdate(item);
                                        }}>
                                            Изменить
                                        </Button>
                                        <Button size="small" color={"error"} variant={"text"} onClick={() => {
                                            if (item.id) {
                                                handleDelete(item.id)
                                            }
                                        }}>
                                            Удалить
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
                {data?.count ? (
                    <Pagination
                        sx={{mt: 2}}
                        defaultPage={page}
                        count={Math.ceil(data.count / pageSize)}
                        onChange={(_, value) => handleChangePage(value)}
                    />
                ) : null}
            </div>
        </CoreLogin>
    );
};

export default Integration;
