import { createStoreon, StoreonModule } from "storeon";
import { storeonDevtools } from "storeon/devtools";
import { authModule } from "./StoreModules/AuthModule";
import { modalModule } from "./StoreModules/ModalModule";
import { tableModule } from "./StoreModules/TableModule";

import { UnionToIntersection } from "../types";

type ExtractStoreonTypes<T, R extends "state" | "events"> = UnionToIntersection<
    T extends StoreonModule<infer S, infer E> ? (R extends "state" ? S : E) : never
>;

const storeModules = [authModule, modalModule, tableModule];

type StoreState = ExtractStoreonTypes<typeof storeModules[0], "state">;
type StoreEvents = ExtractStoreonTypes<typeof storeModules[0], "events">;

const store = createStoreon<StoreState, StoreEvents>([
    ...storeModules,
    process.env.NODE_ENV !== "production" && storeonDevtools,
]);

export default store;
export { storeModules };
export type { StoreState, StoreEvents };
