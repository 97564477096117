import {useEffect, useState} from "react";
import {apiCore} from "../../api";

const CoreLogin = ({
    children
}) => {
    const [isLogin, setLogin] = useState(false);
    useEffect(() => {
        apiCore.login().then(data => {
            setLogin(true);
        });
    }, []);
    if(isLogin) {
        return children
    }
    return null;
}

export {
    CoreLogin
}