import React, { useState } from 'react';
import { useSnackbar } from "notistack";
import { Button, DialogActions, DialogContent, FormControlLabel, Checkbox, FormGroup } from "@material-ui/core";
import SearchForm from '../../Forms/SearchForm';
import Input from '../../Common/Input/Input';
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from '../../../api';

type ModalProps = { onUpdate(): void; }

const AddGoodsOrder: React.FunctionComponent<WithModalComponentProps<ModalProps>> = ({ onClose, modalProps }) => {

  const { id: orderId, onUpdate } = modalProps;
  const { enqueueSnackbar } = useSnackbar();
  const [goods, setGoods] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState<any>({
    id: 0,
    count: 1
  })

  const handleErrorRequest = (err) => {
    const { data } = err?.response;
    enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
  }

  const handleSuccessRequest = (message = "") => {
    enqueueSnackbar(message, { variant: "success" });
  }

  const sendSearch = (values, actions) => {
    api.getGoodsItems(values)
      .then(res => {
        actions.setSubmitting(false)
        setGoods(res?.results || [])
      })
      .catch(err => {
        handleErrorRequest(err)
        actions.setSubmitting(false)
      })
  }

  const setProduct = (id: number) => {
    const newProduct = { ...selectedProduct }
    newProduct.id = id;
    setSelectedProduct(newProduct)
  }

  const setCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newProduct = { ...selectedProduct }
    newProduct.count = Number(e.target.value) > 0 ? Number(e.target.value) : 1;
    setSelectedProduct(newProduct)
  }

  const addProduct = () => {
    const params = {
      count: selectedProduct.count,
      order: orderId,
      sku_id: selectedProduct.id
    }
    api.createOrderProduct(params)
      .then(res => {
        onUpdate();
        onClose();
        handleSuccessRequest("Товар успешно добавлен к заказу")
      })
      .catch(err => handleErrorRequest(err))
  }

  return (
    <>
      <DialogContent>
        <div style={{padding: "10px 0px"}}>
          <SearchForm
            helpText="Введите название или артикул товара"
            onSubmit={sendSearch}
          />
        </div>
        {goods && (
          <>
            <div style={{ overflowY: "auto", maxHeight: "300px" }}>
              <FormGroup>
                {goods.map(elem => {
                  const { color, size, sku, product } = elem.attr;
                  const label = `${product.value} (артикул: ${sku.value}, размер: ${size.value}, цвет: ${color.value})`;
                  return (
                    <FormControlLabel
                      key={elem.id}
                      control={(
                        <Checkbox
                          checked={elem.id === selectedProduct.id}
                          onChange={() => setProduct(elem.id)}
                        />
                      )}
                      label={label}
                    />
                  )
                })}
              </FormGroup>
            </div>
            <Input
              sx={{ marginTop: "20px"}}
              value={selectedProduct.count}
              onInput={setCount}
              type="number"
              label="Количество"
             />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button onClick={addProduct} disabled={!selectedProduct.id} color="primary" variant="contained">
          Добавить товар
        </Button>
      </DialogActions>
    </>
  )
}

export default AddGoodsOrder