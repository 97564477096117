import React from "react";
import Layout from "../components/Common/Layout";
import OrderDetailLayout from "../components/Common/Layout/OrderDetailLayout";
import { OrderChangelogTable } from "../components/Tables";

const OrderChangelogPage: React.FunctionComponent = () => {
  return (
    <Layout>
      <OrderDetailLayout>
        <OrderChangelogTable />
      </OrderDetailLayout>
    </Layout>
  );
};

export default OrderChangelogPage;