import React from "react";
import Layout from "../components/Common/Layout";
import NewOrders from "../components/NewOrders";

const NewOrdersPage: React.FunctionComponent = (props) => {
    return (
        <Layout>
            <NewOrders />
        </Layout>
    );
};

export default NewOrdersPage;
