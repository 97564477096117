import React from "react";
import DataTable, { DataTableColumn, RowAction } from "../../Common/DataTable";
import { useModal, useTable } from "../../../hooks";
import api from "../../../api";
import { Order } from "../../../api/models";
import { generatePath, useHistory } from "react-router-dom";
import { Paths } from "../../../constants";
import { TableHeadStyles, } from '../../../styles';

const CashlessPaidInvoicesTable: React.FC<any> = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();

    const handleOpenPayment: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        const path = generatePath(Paths.PAYMENT_INVOICES_DETAIL.path, { id: rowData.id })
        history.push(path)
    };

    const handleDelete: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("DELETE_PAYMENT", { id: rowData.id, onUpdate: updateData });
    };

    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "orderNumber",
            title: "№ Заказа",
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "orders",
            title: "Другие заказы клиента",
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "cityDisplay",
            title: "Город",
        },
    ];

    const getOrders = (params) => api.getAllOrders({
        ...params,
        status: 12,
        payment_method__in: "2,3"
    })

    return (

        <DataTable<Order>
            title="Оплата по счетам"
            columns={columns}
            fetchFn={getOrders}
            sorting
            filtering={false}
            options={{
                headerStyle: { ...TableHeadStyles }
            }}
            customActions={[
                {
                    tooltip: "Открыть",
                    name: "open",
                    onClick: handleOpenPayment,
                    icon: "visibility",
                    color: "primary",
                },
                {
                    tooltip: "Удалить",
                    name: "delete",
                    onClick: handleDelete,
                    icon: "delete",
                    color: "secondary",
                },
            ]}
        />
    );
};

export default CashlessPaidInvoicesTable;
