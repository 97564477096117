import {FormControl} from "@mui/material"
import AsyncSelect from 'react-select/async';
import api from "../../api";
import React from "react";

const OrderSetsSelect: React.FunctionComponent<any> = ({ onChange, value, label, name }) => {
    const promiseOptions = (inputValue: string) => { 
        return new Promise<any[]>((resolve) => {
            api.getOrderSets({ search: inputValue })
            .then((data) => { 
                resolve(data.results.map((item) => ({
                    label: item.id,
                    value: item.id
                })));
            })
        });
    }
    return (
        <FormControl size="small" margin={'normal'} fullWidth>
            <AsyncSelect
                value={value}
                placeholder={label}
                styles={{ menuPortal: base => ({ ...base, zIndex: 1400 }) }}
                menuPortalTarget={document.body}
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                onChange={onChange}
            />
        </FormControl>
    )
}

export {
  OrderSetsSelect
}