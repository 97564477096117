import React from 'react';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { Box } from '@mui/material';
import { DateInput } from '../../Common/Input/Date';
import Row from '../../Common/Row';
import { PaymentSelect } from '../../Select/Payment';
import { StatusSelect } from '../../Select/Status';
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from '../../../api';
import Input from '../../Common/Input/Input';

type ModalProps = {
    onUpdate(): void;
    name?:string;
}

type CourierModalProps = WithModalComponentProps<ModalProps>;

const sx = {
    marginTop: "20px"
}

const CourierSchema = Yup.object().shape({
    name: Yup.string().required("Обязательное поле"),
});

const AddCourier: React.FunctionComponent<CourierModalProps> = ({ onClose, modalProps }) => {

    const { onUpdate,name,id } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleErrorRequest = (err) => {
        const { data } = err?.response;
        enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
    }

    const submitCourier = (values, action) => {
        const res = id ? api.updateCourier(id, values.name): api.createCourier(values.name)
        res.then(res => {
            action.resetForm();
            action.setSubmitting(false);
            onClose();
            onUpdate();
        }).catch(err => handleErrorRequest(err))
    }

    const formik = useFormik({
        initialValues: {
            name: name,
        },
        validationSchema: CourierSchema,
        onSubmit: submitCourier,
    })

    if(formik) {
        return (
            <form onSubmit={formik.handleSubmit}>
                <DialogContent sx={{ paddingTop: "0" }}>
                    <Row>
                        <Box {...sx} gridColumn="span 12">
                            <Input
                                fullWidth
                                label="Имя"
                                name="name"
                                helperText={formik.touched.name ? formik.errors.name : ""}
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                        </Box>

                    </Row>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Отмена</Button>
                    <Button
                        type="submit"
                        disabled={formik.isSubmitting}
                        color="primary"
                        variant="contained">
                        Принять
                    </Button>
                </DialogActions>
            </form>
        )
    }
    return null
}

export default AddCourier;