import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { generatePath, useHistory } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { Box } from '@mui/material';
import { useSnackbar } from "notistack";
import { CitySelect } from '../../Select/City';
import { PaymentSelect } from '../../Select/Payment';
import { TransportCompanySelect } from '../../Select/TransportCompany';
import { ShopSelect } from '../../Select/Shop';
import { DeliveryMethodSelect } from '../../Select/DeliveryMethod';
import UserAutocomplete from '../../Autocomplete/User';
import Row from '../../Common/Row';
import { PhoneInput } from '../../Common/Input/Phone';
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import { Paths } from '../../../constants';
import Order, { DeliveryMethod } from '../../../api/models/Order.model';
import api from '../../../api';
import Input from '../../Common/Input/Input';

interface PropTypes {
  isExchanged: boolean;
  orderId?: any;
  onUpdate: any;
}

const defaultValues = {
  full_name: "",
  phone: "",
  email: "",
  delivery_method: 0,
  delivery_services: 0,
  shop: null,
  pick_up_point: "",
  // city: 0,
  city_name: "",
  street: "",
  house: "",
  apartment_office: "",
  comment_to_the_courier: "",
  promo_code: "",
  index: "",
  region: "",
  hull_building: "",
  user: null,
}

const schema = yup.object().shape({
  payment_method: yup.number().nullable().required("Обязательное поле"),
  full_name: yup.string().required("Обязательное поле"),
  phone: yup.string().required("Обязательное поле"),
  email: yup.string().email("Введите корректный email").required("Обязательное поле"),
  // city: yup.number().nullable().required("Обязательное поле"),
  city_name: yup.string().required("Обязательное поле"),
  street: yup.string().required("Обязательное поле"),
  delivery_method: yup.number().nullable().required("Обязательное поле"),
  delivery_services: yup.number().nullable().when('delivery_method', {
    is: (val) => val === DeliveryMethod.delivery,
    then: yup.number().nullable().required("Обязательное поле"),
    otherwise: yup.number().nullable().notRequired(),
  }),
  shop: yup.number().nullable().required("Обязательное поле"),
  user: yup.object({
    label: yup.string().required("Обязательное поле"),
  }).required("Обязательное поле"),
}).required();

const OrderCreateModal: React.FunctionComponent<WithModalComponentProps<PropTypes>> = ({ onClose, modalProps }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isExchanged = false, orderId = null, onUpdate } = modalProps;

  const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        getValues,
        formState: { errors, isSubmitting, touchedFields }
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
          payment_method: isExchanged ? 4 : null,
          ...defaultValues
        }
    });

    useEffect(() => {
      if(orderId) {
        api.getOrderDetail(orderId)
          .then(res => {
            reset({
              ...defaultValues,
              apartment_office: res?.apartmentOffice || "" ,
              // city: res?.city || 0,
              comment_to_the_courier: res?.commentToTheCourier || "",
              delivery_method: res?.deliveryMethod || 0,
              delivery_services: res?.deliveryServices || 0,
              email: res?.email || "",
              full_name: res?.fullName || "",
              house: res?.house || "",
              hull_building: res?.hullBuilding || "",
              index: res?.index || "",
              payment_method: isExchanged ? 4 : null,
              pick_up_point: res?.pickUpPoint || "",
              phone: res?.phone || "",
              promo_code: res?.promoCode || "",
              region: res?.region || "",
              shop: res?.shop,
              street: res?.street || "",
              user: {
                label: res?.user?.fullName || res?.user?.username,
                value: {
                  id: res?.user?.id
                }
              },
              city_name: res?.cityName || "",
            })
          })
          .catch(err => {
            reset({
              payment_method: isExchanged ? 4 : null,
              ...defaultValues
            })
          })
      }
    }, [orderId])

    const handleErrorRequest = (err) => {
      const { data } = err?.response;
      enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
    }

    const deliveryMethod = watch("delivery_method")
    useEffect(() => {
      setValue("delivery_services", 0)
    }, [deliveryMethod])

    const onSubmit = (data) => {
      console.log(data, 'data')
      api.createOrder({
        ...data,
        user: data.user.value.id
      })
        .then(res => {
          onClose();
          if(res.id && !isExchanged) {
            const path = generatePath(Paths.ORDER_DETAIL.path, { id: res.id })
            history.push(path)
          } else if(res.id && isExchanged) {
            onUpdate(res.id)
          }
        })
        .catch(err => handleErrorRequest(err))
    }

    const handleChange = (value) => {
      setValue("user", value)
    }
  
    // console.log(getValues())
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: "0" }}>
          <Row>
            <Box gridColumn="span 12">
              <PaymentSelect
                label="Способ оплаты"
                {...register("payment_method", { required: true })}
                value={isExchanged ? 4 : watch("payment_method")}
                disabled={isExchanged}
                helperText={errors.payment_method?.message}
                error={Boolean(errors.payment_method?.message && touchedFields.payment_method)}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 12">
              <UserAutocomplete
                label="Пользователь"
                {...register("user", { required: true })}
                value={watch("user")}
                isFullInfo={false}
                name="user"
                handleChange={handleChange}
                helperText={errors.user?.label?.message}
                error={Boolean(errors.user?.label?.message)}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 12">
              <Input
                fullWidth
                label="ФИО получателя"
                helperText={errors.full_name?.message}
                error={Boolean(errors.full_name?.message && touchedFields.full_name)}
                {...register("full_name", { required: true })}
                value={watch("full_name")}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 6">
            <Controller
              render={({ field }) => {
                const {value, ref, onChange} = field
                return (
                  <PhoneInput
                    control={control}
                    label="Телефон получателя"
                    helperText={errors.phone?.message}
                    error={Boolean(errors.phone?.message && touchedFields.phone)}
                    onChange={({formattedValue}) => onChange(formattedValue)}
                    value={value}
                    ref={ref}
                  />
                );
              }}
              name="phone"
              control={control}
            />
              
            </Box>
            <Box gridColumn="span 6">
              <Input
                fullWidth
                label="Email получателя"
                helperText={errors.email?.message}
                error={Boolean(errors.email?.message && touchedFields.email)}
                type="email"
                {...register("email", { required: true })}
                value={watch("email")}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 12">
              <Input
                fullWidth
                label="Индекс"
                {...register("index", { required: false })}
                value={watch("index")}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 12">
              <Input
                label="Регион"
                {...register("region", { required: false })}
                value={watch("region")}
                fullWidth
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 6">
              <Input
                fullWidth
                label="Город"
                helperText={errors.city_name?.message}
                error={Boolean(errors.city_name?.message && touchedFields.city_name)}
                {...register("city_name", { required: true })}
                value={watch("city_name")}
              />
            </Box>
            <Box gridColumn="span 6">
              <Input
                label="Улица"
                helperText={errors.street?.message}
                error={Boolean(errors.street?.message && touchedFields.street)}
                {...register("street", { required: true })}
                value={watch("street")}
                fullWidth
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 4">
              <Input
                label="Дом"
                {...register("house", { required: false })}
                value={watch("house")}
                fullWidth
              />
            </Box>
            <Box gridColumn="span 4">
              <Input
                fullWidth
                label="Корпус/строение"
                {...register("hull_building", { required: false })}
                value={watch("hull_building")}
              />
            </Box>
            <Box gridColumn="span 4">
              <Input
                label="Квартира/офис"
                fullWidth
                {...register("apartment_office", { required: false })}
                value={watch("apartment_office")}
              />
            </Box>
          </Row>
          <Row other={{marginTop: "0"}}>
            <Box gridColumn="span 6"> 
              <DeliveryMethodSelect
                label="Способ доставки"
                value={watch("delivery_method")}
                helperText={errors.delivery_method?.message}
                error={Boolean(errors.delivery_method?.message && touchedFields.delivery_method)}
                {...register("delivery_method", { required: true })}
              />
            </Box>
            <Box gridColumn="span 6">
              <TransportCompanySelect
                label="Транспортная компания"
                value={watch("delivery_services")}
                disabled={deliveryMethod !== DeliveryMethod.delivery}
                helperText={errors.delivery_services?.message}
                error={Boolean(errors.delivery_services?.message && touchedFields.delivery_services)}
                {...register("delivery_services")}
              />
            </Box>
          </Row>
          <Row other={{marginTop: "0"}}>
            <Box gridColumn="span 12">
              <ShopSelect 
                style={{marginTop: "0"}}
                label="Магазин"
                helperText={errors.shop?.message}
                error={Boolean(errors.shop?.message && touchedFields.shop)}
                {...register("shop", { required: true })}
                value={watch("shop")}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 12">
              <Input
                fullWidth
                label="Пункт выдачи"
                {...register("pick_up_point", { required: false })}
                value={watch("pick_up_point")}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 12">
              <Input
                fullWidth
                label="Промокод"
                {...register("promo_code", { required: false })}
                value={watch("promo_code")}
              />
            </Box>
          </Row>
          <Row>
            <Box gridColumn="span 12">
              <Input
                {...register("comment_to_the_courier", { required: false })}
                value={watch("comment_to_the_courier")}
                rows={3}
                multiline
                fullWidth
                label="Комментарий для курьера"
              />
            </Box>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Отмена</Button>
          <Button
            type="submit"
            disabled={isSubmitting}
            color="primary"
            variant="contained">
            Добавить заказ
          </Button>
        </DialogActions> 
      </form>
    )
}

export default OrderCreateModal