import React from "react";
import Layout from "../components/Common/Layout";
import CashlessPaid from "../components/CashlessPaid";

const CashlessPaidPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <CashlessPaid />
        </Layout>
    );
};

export default CashlessPaidPage;
