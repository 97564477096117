import React from "react";
import { useQuery } from "react-query";
import api from "../../api";
import { useAuth } from "../../hooks";

const withAuth = (WrappedComponent: React.ComponentType) => (props: any) => {
    const { setUser, setPermissions, authenticate } = useAuth();

    const refreshToken = api.getRefreshToken();

    const { isFetched, isFetching: isTokenFetching } = useQuery(
        ["rtoken", refreshToken],
        () => api.refresh({ refresh_token: refreshToken }),
        {
            enabled: !!refreshToken,
        }
    );

    const { isFetching } = useQuery(["user", isFetched], api.currentUser, {
        enabled: isFetched,
        onSuccess: (data) => {
            if (data) {
                setUser(data);
                authenticate();
            }
        },
    });

    const { isFetching: isPermissionsFetching } = useQuery(
        ["permissions", isFetched],
        api.getPermissions,
        {
            enabled: isFetched,
            onSuccess: (data) => {
                setPermissions(data);
            },
        }
    );

    if (isFetching || isTokenFetching || isPermissionsFetching)
        return <div>Pls, wait a second</div>;

    return <WrappedComponent {...props} />;
};

export default withAuth;
