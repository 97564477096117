import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";

type ModalProps = { email: string; onUpdate(): void };

type AssembleOrderModalProps = WithModalComponentProps<ModalProps>;

const AssembleOrderModal: React.FunctionComponent<AssembleOrderModalProps> = ({
    onClose,
    modalProps,
}: AssembleOrderModalProps) => {
    const { id, email, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (!id) return;

        try {
            await api.transitionAssemble(id);
            enqueueSnackbar("Успешно отправлено на сборку", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Отправить на сборку заказ пользователя <b>{email}</b> с номером <b>{id}</b>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Отправить на сборку
                </Button>
            </DialogActions>
        </>
    );
};

export default AssembleOrderModal;
