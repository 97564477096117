import {Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";

const DeliveryPriceTotal = ({data}) => {
    if(!data) {
        return null;
    }
    return (
        <TableContainer>
            <Table sx={{ marginBottom: 2 }}>
                <TableBody>
                        <TableRow>
                            {data.map((row) => (
                                <TableCell key={row.title} component="th" scope="row">
                                {row.title} - {row.price} руб.
                                </TableCell>
                            ))}
                        </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DeliveryPriceTotal;