import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";

type ModalProps = { email: string; onUpdate(): void };

type SubmittedOrderModalProps = WithModalComponentProps<ModalProps>;

const SubmittedOrderModal: React.FunctionComponent<SubmittedOrderModalProps> = ({
    onClose,
    modalProps,
}: SubmittedOrderModalProps) => {
    const { id, email, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (!id) return;

        try {
            await api.transitionSubmitted(id);
            enqueueSnackbar("Успешно передано в доставку", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Передать в доставку заказ пользователя <b>{email}</b> с номером <b>{id}</b>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Передать в доставку
                </Button>
            </DialogActions>
        </>
    );
};

export default SubmittedOrderModal;
