import React from "react";
import { Dialog, DialogTitle, Typography } from "@material-ui/core";
import { useStoreon } from "storeon/react";
import {
    ModalModuleEvents,
    ModalModuleState,
    Modals,
    ModalProps,
} from "../../../store/StoreModules/ModalModule";

const Modal = () => {
    const { open, type, props, dispatch } = useStoreon<ModalModuleState, ModalModuleEvents>(
        "open",
        "type",
        "props"
    );

    const closeModal = () => {
        dispatch("modal/toggle", false);
        dispatch("modal/set/type", null);
        dispatch("modal/set/props", null);
    };

    if (!type) return null;

    const modal = Modals[type];
    const Content = modal.component;

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => reason === "backdropClick" && closeModal}
            fullWidth
        >
            <DialogTitle>
                <Typography variant="h6">{modal.title}</Typography>
            </DialogTitle>

            <Content onClose={closeModal} modalProps={props || ({} as ModalProps<any>)} />
        </Dialog>
    );
};

export default Modal;
