import React from 'react'
import { Formik, Form } from "formik";
import { Button, Grid } from "@mui/material";
import Input from '../../Common/Input/Input';

interface Search {
  search: string;
}

interface SearchProps {
  helpText: string;
  onSubmit: any;
}

const SearchForm: React.FunctionComponent<SearchProps> = ({ helpText, onSubmit }) => {
  return (
    <Formik<Search>
      initialValues={{
        search: ""
      }}
      onSubmit={onSubmit}
      validateOnMount>
        {({ touched, errors, handleChange, handleSubmit, values, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid sx={{ marginBottom: "20px" }} alignItems="flex-start" container columnSpacing={2}>
                <Grid item xs={8}>
                  <Input
                    name="search"
                    type="text"
                    value={values.search}
                    onChange={handleChange}
                    fullWidth
                    margin="none"
                    label="Поиск"
                    size="small"
                    helperText={helpText}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    sx={{ height: "40px" }}
                    fullWidth
                    variant="contained"
                    type="submit"
                    size="large"
                    disabled={isSubmitting || values.search === ""}
                  >
                    Искать
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
    </Formik>
  )
}

export default SearchForm