import React from "react";
import Layout from "../components/Common/Layout";
import SuperManager from "../components/SuperManager";

const SuperManagerPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <SuperManager />
        </Layout>
    );
};

export default SuperManagerPage;
