import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialTable, {
    MaterialTableProps,
    MTableAction,
    Column,
    Action,
    Query,
    Filter,
} from "@material-table/core";
import { Icon, IconButton, Tooltip, PropTypes } from "@mui/material";
import { tableIcons, tableLocalization } from "./TableModules";
import { useTable } from "../../../hooks";
import { PaginatedQueryResult } from "../../../api/models";

type FetchResult<DataType> = PaginatedQueryResult<DataType>;
export interface DataTableColumn<DataType extends {}> extends Column<DataType> {}

export interface RowAction<DataType extends {}> extends Action<DataType> {
    name: string;
    color?: PropTypes.Color;
    isDisabled?(rowData: DataType): boolean;
}

export type DataTableProps<DataType extends object> = Omit<
    MaterialTableProps<DataType>,
    "columns" | "data"
> & {
    title?: string;
    columns?: DataTableColumn<DataType>[];
    data?: DataType[];
    customActions?: RowAction<DataType>[];
    dynamicColumns?: boolean;
    fetchFn: ({ ...params }: any) => Promise<FetchResult<DataType>>;
    displayField?: keyof DataType | "id";
    toolbar?: boolean;
    search?: boolean;
    grouping?: boolean;
    selection?: boolean;
    pageSize?: number;
    filtering?: boolean;
    sorting?: boolean;
    onRowClick?: (e, rowData?: DataType) => void;
    onEditClick?: (rowData: DataType | DataType[]) => void;
    onDeleteClick?: (rowData: DataType) => Promise<void>;
    onMassDeleteClick?: (rowData: DataType | DataType[]) => Promise<void>;
};

export const getFiltersObjectFromTable = <T extends {}>(filtersArr: Filter<T>[]) => {
    return filtersArr.reduce((obj, filter) => {
        const field = filter.column.field?.toString();
        const type = filter.column.type;

        if (!field) return obj;

        if (type === "boolean") {
            obj[field] = filter.value === "checked" ? true : false;
            return obj;
        }

        obj[field + "__in"] =
            filter.value instanceof Array ? filter.value.map((el) => el).join(",") : filter.value;

        return obj;
    }, {});
};

const DataTable = <T extends object>({
    title,
    columns = [],
    dynamicColumns = false,
    fetchFn,
    customActions = [],
    displayField = "id",
    search = true,
    toolbar = true,
    grouping = false,
    selection = false,
    pageSize = 50,
    filtering = true,
    sorting = false,
    onRowClick,
    onEditClick,
    onDeleteClick,
    onMassDeleteClick,
    ...other
}: DataTableProps<T>): React.ReactElement => {
    const tableRef = useRef<any>();
    const { setRef } = useTable();
    const [state, setState] = useState({
        isDelete: false,
        hideSelection: selection,
    });

    useEffect(() => {
        if (tableRef.current) {
            setRef(tableRef);
        }

        return () => setRef(null);
    }, [setRef]);

    const fetch = async (q: Query<T>) => {
        const { page, pageSize, orderDirection, orderBy, totalCount, search, filters } = q;
        let nextPage = page + 1;
        if (totalCount === pageSize * page + 1 && page !== 0 && state.isDelete) {
            nextPage = 1;
            setState((prevState) => ({
                ...prevState,
                isDelete: false,
            }));
        }
        const response = await fetchFn({
            page: nextPage,
            page_size: pageSize,
            search: search ? search : "",
            ordering:
                orderDirection === "asc"
                    ? orderBy?.field?.toString()
                    : "-" + orderBy?.field?.toString(),
            ...getFiltersObjectFromTable(filters),
        });

        const data = response.results || [];

        setState((prevState) => ({
            ...prevState,
            hideSelection: !data.length,
        }));

        return {
            data,
            page: nextPage - 1,
            totalCount: response.count,
        };
    };

    const getActions = useMemo((): RowAction<T>[] => {
        const actions = [] as RowAction<T>[];

        actions.push(...customActions);

        if (onEditClick) {
            actions.push({
                name: "cedit",
                color: "primary",
                icon: "edit",
                tooltip: "Редактировать",
                iconProps: {
                    fontSize: "small",
                },
                position: "row",
                onClick: (event, rowData) => {
                    onEditClick && onEditClick(rowData);
                },
                disabled: false,
            });
        }

        return actions;
    }, [onEditClick, customActions]);

    return (
        <MaterialTable
            {...other}
            title={title || ""}
            columns={columns}
            tableRef={tableRef}
            icons={tableIcons}
            data={fetch}
            onRowClick={onRowClick}
            localization={tableLocalization}
            onPageChange={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            components={{
                Action: (props) => {
                    if (!props.action.name) return <MTableAction {...props} />;

                    return (
                        <Tooltip title={props.action.tooltip}>
                            <span>
                                <IconButton
                                    style={{ marginRight: 5 }}
                                    color={props.action.color}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.action.onClick(e, props.data);
                                    }}
                                    size={props.size}
                                    disabled={props.action?.isDisabled?.(props.data)}
                                >
                                    <Icon {...props.action.iconProps}>{props.action.icon}</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    );
                },
            }}
            options={{
                columnsButton: true,
                debounceInterval: 500,
                emptyRowsWhenPaging: false,
                tableLayout: "auto",
                pageSizeOptions: [10, 50, 100, 200],
                actionsColumnIndex: -1,
                selection: selection && !state.hideSelection,
                filtering,
                search,
                toolbar,
                pageSize,
                grouping,
                sorting,
                padding: "dense",
                thirdSortClick: false,
                ...other.options,
            }}
            actions={getActions}
            editable={
                onDeleteClick && {
                    onRowDelete: (rowData) =>
                        new Promise(async (resolve, reject) => {
                            await onDeleteClick(rowData);
                            setState((prevState) => ({
                                ...prevState,
                                isDelete: true,
                            }));
                            resolve(rowData);
                        }),
                }
            }
        />
    );
};

export default DataTable;
