import React from "react";
import Layout from "../components/Common/Layout";
import OrderDetailLayout from "../components/Common/Layout/OrderDetailLayout";
import OrderPayment from "../components/OrderPayment";

const OrderPaymentPage: React.FunctionComponent = () => {
  return (
    <Layout>
      <OrderDetailLayout>
        <OrderPayment />
      </OrderDetailLayout>
    </Layout>
  );
};

export default OrderPaymentPage;