import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";
import { useAuth } from "../../../hooks";

type ModalProps = {
    email: string;
    type: "manager" | "logistician";
    onUpdate(): void;
};

type TakeToWorkOrderModalProps = WithModalComponentProps<ModalProps>;

const TakeToWorkOrderModal: React.FunctionComponent<TakeToWorkOrderModalProps> = ({
    onClose,
    modalProps,
}: TakeToWorkOrderModalProps) => {
    const { user } = useAuth();
    const { id, email, onUpdate, type } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (!id || !user) return;

        try {
            if (type === "manager") {
                await api.setManager(id, user.id);
            } else {
                await api.setLogistician(id, user.id);
            }
            enqueueSnackbar("Успешно взято в работу", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Принять в работу заказ пользователя <b>{email}</b> с номером <b>{id}</b>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Взять в работу
                </Button>
            </DialogActions>
        </>
    );
};

export default TakeToWorkOrderModal;
