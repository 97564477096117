import React from 'react';
import {Button,} from '@mui/material';
import {useModal, useTable} from '../../../hooks';
import {Courier} from '../../../api/models';
import api from '../../../api';
import {TableHeadStyles} from '../../../styles';
import DataTable, {DataTableColumn, RowAction} from '../../Common/DataTable';


const Couriers: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();

    const handleCancel: RowAction<Courier>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("DELETE_COURIER", {id: rowData.id, name: rowData.name, onUpdate: updateData});
    };

    const columns: DataTableColumn<Courier>[] = [
        {
            field: "id",
            filtering: false,
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "name",
            filtering: false,
            title: "Имя",
            render: (data) => data.name,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        }
    ];

    const openCreateModal = () => {
        openModal("ADD_COURIER", {
            onUpdate: updateData,
        });
    }

    const toCourierDetail: RowAction<Courier>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("UPDATE_COURIER", {id: rowData.id, name: rowData.name, onUpdate: updateData});
    };

    return (
        <>
            <Button onClick={openCreateModal} variant="contained" sx={{ marginBottom: "20px"}}>Добавить нового курьера</Button>
            <DataTable<Courier>
                title="Курьеры"
                columns={columns}
                fetchFn={api.getCouriersList}
                filtering={false}
                sorting={false}
                options={{
                    headerStyle: { ...TableHeadStyles }
                }}
                customActions={[
                    {
                        tooltip: "Уточнить",
                        name: "take",
                        onClick: toCourierDetail,
                        icon: "settings_backup_restore",
                        color: "primary",
                    },
                    {
                        tooltip: "Отменить",
                        name: "cancel",
                        onClick: handleCancel,
                        icon: "cancel",
                        color: "secondary",
                    },
                ]}
            />
        </>
    );
};

export default Couriers;
