import React from "react";
import Layout from "../components/Common/Layout";
import CashlessInvoicesDetail from "../components/CashlessInvoicesDetail";

const CashlessInvoicesDetailPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <CashlessInvoicesDetail />
        </Layout>
    );
};

export default CashlessInvoicesDetailPage;
