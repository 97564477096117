import React from "react";
import Layout from "../components/Common/Layout";
import ReturnRequests from "../components/ReturnRequests";

const ReturnRequestsPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <ReturnRequests />
        </Layout>
    );
};

export default ReturnRequestsPage;
