import { StoreonModule } from "storeon";

export interface TableModuleState {
    ref: any;
}

export interface TableModuleEvents {
    "table/set/ref": any | null;
}

export const tableModule: StoreonModule<TableModuleState, TableModuleEvents> = (store) => {
    store.on("@init", () => ({
        ref: false,
    }));

    store.on("table/set/ref", (state, ref) => ({ ref }));
};
