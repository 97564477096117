import React, { useEffect, useState } from "react";
import { SelectProps } from "@mui/material/Select/Select";
import BaseSelect from "./BaseSelect";
import api from "../../api";
import FormControl from "../Common/FormControl";

interface PropsTypes extends SelectProps {
    helperText?: string;
    error?: any;
}

const ShopSelect: React.FunctionComponent<PropsTypes> = ({
    onChange,
    value,
    label,
    name,
    error,
    helperText,
    ...props
}) => {
    const [shopsList, setShopsList] = useState<any>([])
    useEffect(() => {
        api.getAllShops()
            .then(res => {
                const { results } = res;
                const data = [...results].map(item => ({
                    id: item.id,
                    name: item.title,
                    value: item.id
                }))
                setShopsList(data)
            })
            .catch(err =>setShopsList([]))
    }, [])
    return (
        <FormControl error={error} name={name} label={label} helperText={helperText}>
            <BaseSelect
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                options={shopsList}
                {...props}
            />
        </FormControl>
    )
}

export {
  ShopSelect
}