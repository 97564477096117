export const TableHeadStyles = {
  padding: "15px 10px",
  backgroundColor: '#1976d247',
  fontSize: "14px",
}

export const TableCellStyles = {
  padding: "15px 10px",
  fontSize: "14px",
}

export const TextNowrapStyle = {
  "whiteSpace": "nowrap"
}