import {Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../../api";
import { ReturnRequest } from "../../../api/models";
import { useModal, useTable } from "../../../hooks";
import DataTable, { DataTableColumn, DataTableProps, RowAction } from "../../Common/DataTable";
import {Paths} from "../../../constants";
import { RequestStatus } from "../../../api/models/ReturnRequest.model";
import { TableHeadStyles } from "../../../styles";
import {numToRubFormat} from '../../../utils';

const ReturnRequestsTable: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();

    const handleReturned: RowAction<ReturnRequest>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("RETURNED_ORDER", {
            id: rowData.order.id,
            email: rowData.user.email,
            returnRequestId: rowData.id,
            onUpdate: updateData,
        });
    };

    const handleClose: RowAction<ReturnRequest>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CLOSE_RETURN_REQUEST", {
            id: rowData.order.id,
            email: rowData.user.email,
            returnRequestId: rowData.id,
            onUpdate: updateData,
        });
    };

    const handleRejected: RowAction<ReturnRequest>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CANCEL_RETURN_REQUEST", {
            id: rowData.order.id,
            email: rowData.user.email,
            returnRequestId: rowData.id,
            onUpdate: updateData,
        });
    };

    const handleEdit: RowAction<ReturnRequest>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        history.push({ pathname: Paths.RETURN_REQUESTS_CREATE.path, state: { id: rowData.id }})
    }

    const columns: DataTableColumn<ReturnRequest>[] = [
        {
            field: "id",
            filtering: false,
            sorting: true,
            title: "#",
            render: (rowData) => rowData.id,
            hidden: false
        },
        {
            field: "order",
            filtering: false,
            title: "Номер заказа",
            render: (rowData) => rowData.order.orderNumber,
            defaultSort: "asc",
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "user",
            filtering: false,
            title: "Клиент",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.user.fullName}</div>
                        <div>{rowData.user.email}</div>
                        <div>
                            <b>{rowData.user.phone}</b>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "status",
            filtering: false,
            title: "Статус запроса",
            render: (rowData) => ReturnRequest.getStatusName(rowData.status),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "order.status",
            filtering: false,
            title: "Статус заказа",
            render: (rowData) => rowData.order.statusName,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "address",
            filtering: false,
            title: "Адрес",
            sorting: false,
            render: (rowData) => rowData.order.address,
        },
        {
            field: "total_price",
            filtering: false,
            title: "Общая цена",
            render: (rowData) => numToRubFormat(rowData.order.totalPrice),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "refund_amount",
            filtering: false,
            title: "Сумма возврата",
            render: (rowData) => numToRubFormat(rowData.refundAmount),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "is_partial_return",
            filtering: false,
            title: "Частичный возврат",
            render: (rowData) => rowData.isPartialReturn ? 'Да' : 'Нет',
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "is_exchanged",
            filtering: false,
            title: "Обмен",
            render: (rowData) => rowData.isExchanged ? 'Да' : 'Нет',
        },
        {
            field: "comment",
            filtering: false,
            title: "Комментарий",
            render: (rowData) => rowData.comment,
        },
        {
            field: "created_at",
            filtering: false,
            title: "Дата создания",
            render: (rowData) => ReturnRequest.dateFormat(rowData.createdAt),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "sum_past_orders",
            filtering: false,
            title: "sum_past_orders",
            render: (rowData) => numToRubFormat(rowData.order.sumPastOrders),
            hidden: true,
        },
    ];

    const DetailPanel: DataTableProps<ReturnRequest>["detailPanel"] = ({ rowData }) => {
        return (
            <>
                {rowData.items?.length ? (
                    <>
                        {rowData.order.items.map((item) => (
                            <>
                                {rowData.items.includes(item.id) ? (
                                    <React.Fragment key={item.id}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                                <img
                                                    src={item.product?.attr?.images?.display?.[0]}
                                                    height="100px"
                                                    width="100px"
                                                    alt="Фото товара"
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                Кол-во: {item.count}
                                            </Grid>
                                            <Grid item xs={2}>
                                                Артикул: {item.product?.attr?.sku?.value || ""}
                                            </Grid>
                                            <Grid item xs={2}>
                                                Размер: {item.product?.attr?.size?.value || ""}
                                            </Grid>
                                            <Grid item xs={2}>
                                                Цвет: {item.product?.attr?.color?.value || ""}
                                            </Grid>
                                            <Grid item xs={2}>
                                                Скидка: 0
                                            </Grid>
                                            <Grid item xs={2}>
                                                Цена продажи: {item.salePrice}
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </React.Fragment>
                                ) : null}
                            </>
                        ))}
                    </>
                ) : (
                    <React.Fragment>
                        <Grid sx={{ padding: "30px 10px"}} container alignItems="center" spacing={2}>
                            <Grid item>
                                <div>Нет товаров</div>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                {rowData.order?.commentaryForManager && rowData.order?.commentaryForManager !== "" ? (
                    <Grid container sx={{ marginBottom: "10px", marginTop: "5px" }} alignItems="center" spacing={1}>
                        <Grid item xs={2}>
                            <Typography>
                                <b>Комментарий приемки:</b>
                            </Typography>
                        </Grid>
                        <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={2}>
                            {rowData.order?.commentaryForManager}
                        </Grid>
                    </Grid>
                ) : null}
            </>
        )
    }

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Link to={Paths.RETURN_REQUESTS_CREATE.path}>Оформить заявление на возврат</Link>
            </Box>
            <DataTable<ReturnRequest>
                title="Запросы на возврат"
                columns={columns}
                fetchFn={api.getReturnRequests}
                detailPanel={DetailPanel}
                sorting
                filtering={false}
                options={{
                    headerStyle: { ...TableHeadStyles }
                }}
                customActions={[
                    {
                        tooltip: "Редактировать возврат",
                        name: "edit",
                        onClick: handleEdit,
                        icon: "edit",
                        color: "primary",
                        isDisabled: (rowData) => rowData.status !== RequestStatus.new,
                    },
                    {
                        tooltip: "Оформить возврат",
                        name: "return",
                        onClick: handleReturned,
                        icon: "assignment_return",
                        color: "primary",
                        isDisabled: (rowData) => rowData.status !== RequestStatus.new,
                    },
                    {
                        tooltip: "Закрыть заявление",
                        name: "closed",
                        onClick: handleClose,
                        icon: "check",
                        color: "primary",
                        isDisabled: (rowData) => rowData.status !== RequestStatus.package_sent,
                    },
                    {
                        tooltip: "Отклонить заявление",
                        name: "rejected",
                        onClick: handleRejected,
                        icon: "do_disturb_on",
                        color: "secondary",
                        isDisabled: (rowData) => rowData.status !== RequestStatus.package_sent,
                    },
                ]}
            />
        </>
    );
};

export default ReturnRequestsTable;
