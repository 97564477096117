import * as React from 'react';
import MaterialTable from "@material-table/core";
import { TablePagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useModal } from "../../../hooks";
import api from '../../../api';
import { TableHeadStyles } from '../../../styles';

function createData(
  title: string,
  skuValue: string,
  salePrice: number,
  count: number,
  total: number,
  id: number,
) {
  return { title, skuValue, salePrice, count, total, id };
}

export type DataTableProps<DataType extends object> = {
  items: any;
  orderId?: any;
  updateGoodsList?: any;
};

const OrderDetailTable = <T extends object>({ items, updateGoodsList, orderId }: DataTableProps<T>): React.ReactElement => {
  const [dataRows, setDataRows] = React.useState<any>([])
  const [totalPrice, setTotalPrice] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const handleErrorRequest = (err) => {
    const { data } = err?.response;
    enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
  }

  const getItemsTotalPrice = () => {
    const itemsPrices = [...dataRows].map(el => Number(el.salePrice) * Number(el.count))
    const totalPriceItems = itemsPrices.reduce((prevValue: number, currentValue: number): any => prevValue + currentValue)
    setTotalPrice(totalPriceItems || 0)
  }

  React.useEffect(() => {
    if(dataRows?.length) {
      getItemsTotalPrice()
    }
  }, [dataRows])

  React.useEffect(() => {
    getData()
  }, [items])

  const getData = () => {
    const data = items.map(el => createData(el?.product?.product_obj?.title || "",
      el?.product?.attr?.sku?.value || "",
      el?.salePrice || 0,
      el?.count || 0,
      (el?.salePrice || 0) * (el?.count || 0),
      el.id)
    )
    setDataRows([...data])
  }

  const deleteItem = (rowData) => {
    const { id } = rowData;
    api.deleteOrderItem(id)
      .then(res => {
        const newData = [ ...dataRows ];
        const currentIndex = newData.findIndex(el => el.id === id)
        const leftSide = newData.slice(0, currentIndex)
        const rightSide = newData.slice(currentIndex + 1)
        const arr = leftSide.concat(rightSide)
        setDataRows(arr)
      }).then(updateGoodsList)
      .catch(err => handleErrorRequest(err))
  }

  const editItem = (rowData) => {
    openModal("EDIT_ORDER_PRODUCT", {
      item: rowData,
      orderId,
      onUpdate: updateGoodsList
    });
  }
  
  return (
    <MaterialTable
      title=""
      columns={[
        { title: 'Наименование', field: 'title' },
        { title: 'Артикул', field: 'skuValue' },
        { title: 'Цена', field: 'salePrice' },
        { title: 'Количество', field: 'count' },
        { title: 'Итого', field: 'total' },
      ]}
      data={dataRows}
      actions={[
        rowData => ({
          name: 'edit',
          icon: 'edit',
          color: 'primary',
          tooltip: 'Редактировать',
          onClick: (event, rowData) => editItem(rowData),
        }),
        rowData => ({
          name: 'delete',
          icon: 'delete',
          tooltip: 'Удалить',
          color: 'primary',
          onClick: (event, rowData) => deleteItem(rowData),
        }),
      ]}
      // components={{
      //   Pagination: (props) => {
      //     return (
      //       <>
      //         <TablePagination {...props} />
      //         <div style={{ padding: "10px 15px", marginTop: "10px" }}>
      //           <b>Итоговая сумма:</b>&nbsp;
      //           <span>{totalPrice}</span>
      //         </div>
      //       </>
      //     )
      //   },
      // }}
      options={{
        actionsColumnIndex: -1,
        toolbar: false,
        search: false,
        padding: "dense",
        headerStyle: { ...TableHeadStyles }
      }}
    />
  );
}

export default OrderDetailTable