import {Exclude, Expose} from "class-transformer";

@Exclude()
class Shop {
  @Expose() id: number;
  @Expose() title: string;
  @Expose() city: string;
  @Expose({ name: "is_active" }) isActive: boolean;
  @Expose() address: string;
  @Expose() comment: string;
  @Expose({ name: "how_get_there" }) howGetThere: string;
  @Expose() phones: any;
  @Expose() coordinates: any;
  @Expose({ name: "work_hours" }) workHours: string;
}

export default Shop;