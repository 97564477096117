import {Button, DialogActions, DialogContent} from "@material-ui/core";
import {Box, Typography} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Row from "../../Common/Row";
import api from "../../../api";
import {useQuery} from "react-query";
import Input from "../../Common/Input/Input";

interface IFormInputs {
    height: number
    length: number
    width: number
    weight: number
}

const schema = yup.object({
    height: yup.string().required(),
    length: yup.string().required(),
    width: yup.string().required(),
    weight: yup.string().required(),
}).required();

const ReadyDeliveryModal = ({
    onClose,
    modalProps,
}) => {
    const { data: currentOrder, isSuccess } = useQuery(
        ["order"],
        () => api.getOrderDetail(modalProps.id),
    )

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: IFormInputs) => {
        const id = currentOrder?.deliveryObj?.id;
        console.log(id, 'id');
        if(id) {
            await api.updateDelivery(currentOrder?.deliveryObj?.id, data)
            await api.transitionReadyDelivery(currentOrder?.id);
            onClose();
            modalProps.onUpdate();
        } else {
            await api.createDelivery({
                order: currentOrder?.id,
                ...data
            })
            await api.transitionReadyDelivery(currentOrder?.id);
            onClose();
            modalProps.onUpdate();
        }
    };

    if(!isSuccess) {
        return null;
    }

    console.log(errors, 'errors')
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Typography>
                    Введите информацию о размерах упаковки
                </Typography>
                <Row>
                    <Box gridColumn="span 12" sx={{ marginBottom: 2 }}>
                        <Input
                            label="Высота (мм)"
                            {...register("height")}
                            error={Boolean(errors.height?.message)}
                        />
                    </Box>
                    <Box gridColumn="span 12" sx={{ marginBottom: 2 }}>
                        <Input
                            label="Длина (мм)"
                            {...register("length")}
                            error={Boolean(errors.length?.message)}
                        />
                    </Box>
                    <Box gridColumn="span 12" sx={{ marginBottom: 2 }}>
                        <Input
                            label="Ширина (мм)"
                            {...register("width")}
                            error={Boolean(errors.width?.message)}
                        />
                    </Box>
                    <Box gridColumn="span 12" sx={{ marginBottom: 2 }}>
                        <Input
                            label="Вес (г)"
                            {...register("weight")}
                            error={Boolean(errors.weight?.message)}
                        />
                    </Box>
                </Row>
             </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button type={"submit"} color="primary" variant="contained">
                    Отправить
                </Button>
            </DialogActions>
        </form>
    )
}

export default ReadyDeliveryModal;