import React from "react";
import Layout from "../components/Common/Layout";
import ReturnRequestForm from "../components/Forms/ReturnRequestForm";

const ReturnRequestCreatePage: React.FunctionComponent = () => {
    return (
        <Layout>
            <ReturnRequestForm />
        </Layout>
    );
};

export default ReturnRequestCreatePage;
