import {Exclude, Expose} from "class-transformer";

@Exclude()
class ContextHelp {
    @Expose() id: number;
    @Expose() url: string;
    @Expose() content:string;
    @Expose({name:"created_at"}) createdAt:Date;
    @Expose({name:"updated_at"}) updatedAt:Date;
}

export default ContextHelp;