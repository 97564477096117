import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { Box, Typography, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Row from '../Common/Row';
import CircularPreloader from '../Common/CircularPreloader';
import ConsignmentNote from '../Common/ConsignmentNote';
import dayjs from '../../utils/dayjs';
import api from '../../api';

const CashlessInvoicesDetail: React.FunctionComponent = () => {
    const { id = null } : any = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = useState<any>(null)
    const [showBtn, setShowBtn] = useState(true)

    const handleErrorRequest = (err) => {
        const { data } = err?.response;
        enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
    }

    useEffect(() => {
        if(id && !order) {
            api.getOrderDetail(id)
                .then(res => setOrder(res))
                .catch(err => handleErrorRequest(err))
        }
    }, [id])

    const setNewOrderStatus = (e) => {
        api.transitionPaid(order.id)
            .then(res => {
                setShowBtn(false)
                enqueueSnackbar("Заказ успешно отправлен на сборку", { variant: "success" });
            })
            .catch(err => handleErrorRequest(err))
    }

    return (
        <>
            {order ? (
                <>
                    <Row>
                        <Box gridColumn="span 12">
                            <Typography variant='h5'>
                                Заказ № {order.orderNumber} {order.shop ? <>магазин {order?.shop?.title}</> : null}
                            </Typography>
                        </Box>
                        <Box gridColumn="span 12">
                            Доставка: {dayjs(order.deliveryDate).format("DD.MM.YYYY")} {dayjs(order.deliveryTimeFrom).format("HH:mm")} - {dayjs(order.deliveryTimeTo).format("HH:mm")}
                        </Box>
                    </Row>
                    <TableContainer sx={{ marginTop: "20px" }} component={Card}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Артикул</TableCell>
                                    <TableCell>Наименование</TableCell>
                                    <TableCell>Выдано</TableCell>
                                    <TableCell>Возврат</TableCell>
                                    <TableCell>Остаток в заказе</TableCell>
                                    <TableCell>Цена</TableCell>
                                    <TableCell>Сумма</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order.items && order.items.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row?.product?.attr?.sku?.value}</TableCell>
                                        <TableCell>{row?.product?.attr?.product?.value}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{row.count}</TableCell>
                                        <TableCell>{row.salePrice} руб.</TableCell>
                                        <TableCell>{(Number(row.count) * Number(row.salePrice))} руб.</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    {order.promoCode && (
                        <Card variant="outlined" sx={{ backgroundColor: "#ffffbc", marginTop: "20px", padding: "10px" }}>
                            <b style={{ fontSize: "20px" }}>
                                Внимание! Полученная сумма меньше суммы по заказу
                            </b>
                            <Row>
                                <Box gridColumn="span 12">
                                    <b>Итого по заказу: {order.totalPrice} руб.</b>
                                </Box>
                                <Box gridColumn="span 12">
                                    <b>Принята оплата в размере: {order.totalPaid} руб.</b>
                                </Box>
                            </Row>
                        </Card>
                    )}
                    <ConsignmentNote order={order}>
                        {showBtn && (
                            <Row>
                                <Box gridColumn="span 7">
                                    <Button variant='contained' onClick={setNewOrderStatus}>Счет выставлен и отправлен на почту клиента</Button>
                                </Box>
                            </Row>
                        )}
                    </ConsignmentNote>
                </>
            ) : <CircularPreloader />}
        </>
        
    )
}

export default CashlessInvoicesDetail;
