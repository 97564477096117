import { AllInbox, AssignmentReturn, CheckCircle, FiberNew, IntegrationInstructions, Money, ReceiptLong, Assessment, LocalShipping, RoomService, Backpack, Badge } from "@material-ui/icons";
import React from "react";

export class Settings {
    static readonly APP_NAME = "ДС-СИТИ / Менеджер";
    static readonly DRAWER_WIDTH = 240;
}

interface Path {
    path: string;
}

type PathType = "LOGIN"
    | "HOME"
    | "MANAGER"
    | "LOGISTIC"
    | "RETURN_REQUESTS"
    | "RETURN_REQUESTS_CREATE"
    | "SUPER_MANAGER"
    | "CORE_CMS"
    | "ORDER_DETAIL"
    | "ORDER_CHANGELOG"
    | "ORDER_PAYMENT"
    | "ACCEPTANCE"
    | "ASSEMBLY"
    | "READY_DELIVERY"
    | "IN_DELIVERY"
    | "CASHLESS_PAID"
    | "CASHLESS_PAID_ORDER"
    | "PAYMENT_INVOICES"
    | "PAYMENT_INVOICES_DETAIL"
    | "IN_DELIVERY_DELIVERY_SERVICES"
    | "IN_DELIVERY_PICKUP"
    | "COURIER"
    | "CASHLESS";

export const Paths: Record<PathType, Path> = {
    LOGIN: {
        path: "/login",
    },
    HOME: {
        path: "/",
    },
    MANAGER: {
        path: "/manager",
    },
    LOGISTIC: {
        path: "/logistic",
    },
    RETURN_REQUESTS: {
        path: "/return-requests",
    },
    RETURN_REQUESTS_CREATE: {
        path: "/return-requests/create",
    },
    SUPER_MANAGER: {
        path: "/super-manager",
    },
    CORE_CMS: {
        path: "/core-cms",
    },
    CASHLESS: {
        path: "/cashless"
    },
    CASHLESS_PAID: {
        path: "/cashless/paid"
    },
    CASHLESS_PAID_ORDER: {
        path: "/cashless/paid/:id"
    },
    PAYMENT_INVOICES: {
        path: "/cashless/payment-invoices"
    },
    PAYMENT_INVOICES_DETAIL: {
        path: "/cashless/payment-invoices/:id"
    },
    ORDER_DETAIL: {
        path: "/order-detail/:id"
    },
    ORDER_CHANGELOG: {
        path: "/order-changelog/:id"
    },
    ORDER_PAYMENT: {
        path: "/order-payment/:id"
    },
    ACCEPTANCE: {
        path: "/acceptance"
    },
    ASSEMBLY: {
        path: "/assembly"
    },
    READY_DELIVERY: {
        path: "/ready_delivery"
    },
    IN_DELIVERY: {
        path: "/in_delivery"
    },
    IN_DELIVERY_DELIVERY_SERVICES: {
        path: "/in_delivery/delivery_services"
    },
    IN_DELIVERY_PICKUP: {
        path: "/in_delivery/pickup"
    },
    COURIER: {
        path: "/courier"
    }
};

interface MenuItem {
    title: string;
    icon?: React.ComponentType;
    url: string;
    isSuperuser: boolean;
    permission: string;
}

export const Menu: MenuItem[] = [
    {
        title: "Новые заказы",
        icon: FiberNew,
        url: Paths.MANAGER.path,
        isSuperuser: false,
        permission: "user.manager_page.view",
    },
    {
        title: "Безнал",
        icon: Money,
        url: Paths.CASHLESS.path,
        isSuperuser: false,
        permission: "user.manager_page.view",
    },
    {
        title: "Проверка и заказ сборки",
        icon: CheckCircle,
        url: Paths.LOGISTIC.path,
        isSuperuser: false,
        permission: "user.logistic_page.view",
    },
    {
        title: "Приемка",
        icon: ReceiptLong,
        url: Paths.ACCEPTANCE.path,
        isSuperuser: false,
        permission: "user.manager_page.view",
    },
    {
        title: "Сборка",
        icon: Backpack,
        url: Paths.ASSEMBLY.path,
        isSuperuser: false,
        permission: "user.manager_page.view",
    },
    {
        title: "Готов к доставке",
        icon: RoomService,
        url: Paths.READY_DELIVERY.path,
        isSuperuser: false,
        permission: "user.manager_page.view",
    },
    {
        title: "В доставке",
        icon: LocalShipping,
        url: Paths.IN_DELIVERY.path,
        isSuperuser: false,
        permission: "user.manager_page.view",
    },
    {
        title: "Запросы на возврат",
        icon: AssignmentReturn,
        url: Paths.RETURN_REQUESTS.path,
        isSuperuser: false,
        permission: "user.returns_manager_page.view",
    },
    {
        title: "Все заказы",
        icon: AllInbox,
        url: Paths.SUPER_MANAGER.path,
        isSuperuser: false,
        permission: "user.super_manager_page.view",
    },
    {
        title: "Интеграция",
        icon: IntegrationInstructions,
        url: Paths.CORE_CMS.path,
        permission: "user.super_manager_page.view",
        isSuperuser: true,
    },
    {
        title: "Курьеры",
        icon: Badge,
        url: Paths.COURIER.path,
        permission: "user.super_manager_page.view",
        isSuperuser: true,
    }
];

export interface SelectItem {
    id: string;
    name: string;
    value: number | string;
}

export const PaymentMethods: SelectItem[] = [
    {
        id: "PAYMENT_UPON_RECEIPT",
        name: "Оплата при получении",
        value: 0
    },
    {
        id: "PAYMENT_BY_CARD",
        name: "Оплата картой на сайте",
        value: 1
    },
    {
        id: "PAYMENT_BY_INVOICE_FROM_A_LEGAL_ENTITY",
        name: "Оплата по счету от юридического лица",
        value: 2
    },
    {
        id: "PAYMENT_BY_INVOICE_FROM_AN_INDIVIDUAL",
        name: "Оплата по счету от физического лица",
        value: 3
    },
    {
        id: "PAYMENT_ALREADY_MADE",
        name: "Оплата уже произведена",
        value: 4
    },
]

export const PaymentMethodsFull: SelectItem[] =[
    {
        id: "ACCOUNT_LEGAL_ENTITY",
        name: "Оплата по счету от юридического лица",
        value: "ACCOUNT_LEGAL_ENTITY"
    },
    {
        id: "ACCOUNT_FL",
        name: "Оплата по счету от физического лица",
        value: "ACCOUNT_FL"
    },
    {
        id: "ONLINE",
        name: "Оплата банковской картой на сайте",
        value: "ONLINE"
    },
    {
        id: "CASHLESS",
        name: "Безналичный рассчет",
        value: "CASHLESS"
    },
    {
        id: "UPON_RECEIPT",
        name: "При получении",
        value: "UPON_RECEIPT"
    },
]

export const PaymentMethodsValues = {
    ACCOUNT_LEGAL_ENTITY: "ACCOUNT_LEGAL_ENTITY",
    ACCOUNT_FL: "ACCOUNT_FL",
    CASHLESS: "CASHLESS",
    ONLINE: "ONLINE",
    UPON_RECEIPT: "UPON_RECEIPT"
}

export const PaymentMethodsShort: SelectItem[] = [
    {
        id: "ACCOUNT_LEGAL_ENTITY",
        name: "Оплата по счету от юридического лица",
        value: "ACCOUNT_LEGAL_ENTITY"
    },
    {
        id: "ACCOUNT_FL",
        name: "Оплата по счету от физического лица",
        value: "ACCOUNT_FL"
    }
]

export const Companies: SelectItem[] = [
    {
        id: "UNDEFINED",
        name: "Курьерская служба не указана",
        value: 0
    },
    {
        id: "CDEK",
        name: "СДЭК",
        value: 1
    },
    {
        id: "POST_RUSSIA",
        name: "Почта России",
        value: 2
    },
    {
        id: "DALLI",
        name: "DALLI",
        value: 3
    },
    {
        id: "COURIER",
        name: "Курьер",
        value: 4
    }
]

export const OrderStatuses: SelectItem[] = [
    {
        id: "NEW",
        name: "Новый",
        value: 0
    },
    {
        id: "PAID_UP",
        name: "Оплачен",
        value: 1
    },
    {
        id: "WAIT_PROCESSING",
        name: "Ожидает обработки",
        value: 3
    },
    {
        id: "CONFIRMED",
        name: "Подтвержден",
        value: 4
    },
    {
        id: "CANCELED",
        name: "Отменён",
        value: 5
    },
    {
        id: "ASSEMBLING",
        name: "Сборка заказа",
        value: 6
    },
    {
        id: "ON_WAY",
        name: "Доставка",
        value: 8
    },
    {
        id: "RETURN",
        name: "Возврат",
        value: 10
    },
    {
        id: "CLOSED",
        name: "Завершен",
        value: 11
    },
    {
        id: "PENDING_PAYMENT",
        name: "Ожидает оплаты",
        value: 12
    },
    {
        id: "PENDING_PRODUCT",
        name: "Ожидание товара",
        value: 13
    },
    {
        id: "PROCESSING",
        name: "Обработка",
        value: 14
    },
    {
        id: "READY_DELIVERY",
        name: "Готов к доставке",
        value: 15
    },
    {
        id: "EXCHANGE",
        name: "Обмен",
        value: 16
    },
    {
        id: "UNDELIVERED",
        name: "Не доставлен",
        value: 17
    },
]

export const PaymentStatuses: SelectItem[] = [
    {
        id: "WAITING",
        name: "Ожидает оплаты",
        value: 'waiting'
    },
    {
        id: "PAID",
        name: "Оплачен",
        value: 'paid'
    },
    {
        id: "CANCELLATION",
        name: "Отмена",
        value: "cancellation"
    },
]

export const DeliveryMethod: SelectItem[] = [
    {
        id: "UNDEFINED",
        name: "Способ доставки не указан",
        value: 0
    },
    {
        id: "PICKUP",
        name: "Самовывоз",
        value: 1
    },
    {
        id: "DELIVERY",
        name: "Доставка",
        value: 2
    },
]

export const City: SelectItem[] = [
    {
        id: "NOT_CHOICE",
        name: "Город не выбран",
        value: 0
    },
    {
        id: "MOSCOW",
        name: "Москва",
        value: 1
    },
    {
        id: "ST_PETERSBURG",
        name: "Санкт–Петербург",
        value: 2
    },
    {
        id: "KRASNAYA_POLYANA",
        name: "г. Сочи Красная поляна",
        value: 3
    },
]

export const LoadingStatuses = {
    failed: "failed",
    loading: "loading",
    loaded: "loaded",
}


export const OrderDetailTabs: any[] = ["Состояние заказа", "Информация о пользователе"]
