import React from "react";
import { generatePath, useHistory } from "react-router";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import DataTable, { DataTableColumn, DataTableProps, RowAction } from "../../Common/DataTable";
import { useModal, useTable } from "../../../hooks";
import { Paths } from "../../../constants";
import { Order } from "../../../api/models";
import api from "../../../api";
import { TableHeadStyles, TextNowrapStyle } from "../../../styles";
import {numToRubFormat} from '../../../utils';
import Row from "../../Common/Row";

const NewOrdersTable: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();

    const handleConfirm: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CONFIRM_ORDER", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
        });
    };

    const handleTakeToWork: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("TAKE_TO_WORK", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
            type: "manager",
        });
    };

    const openCreateModal = () => {
        openModal("CREATE_ORDER");
    }

    const toOrderDetail = async (e, rowData) => {
        const path = generatePath(Paths.ORDER_DETAIL.path, { id: rowData.id})
        history.push(path)
    }

    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            filtering: false,
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "user_info",
            filtering: false,
            title: "Клиент",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.fullName}</div>
                        <div>{rowData.email}</div>
                        <div>
                            <b>{rowData.phone}</b>
                        </div>
                        <div>{rowData.address}</div>
                    </div>
                );
            },
        },
        {
            field: "email",
            filtering: false,
            title: "Email",
        },
        {
            field: "full_name",
            filtering: false,
            title: "Полное имя",
            render: (rowData) => rowData.fullName,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "order_number",
            filtering: false,
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "status",
            filterCellStyle: {
                width: 300,
                maxWidth: 300
            },
            filtering: true,
            lookup: { 0: "Новый", 1: "Оплачен", 3: "Ожидает обработки", 4: "Подтвержден", 5: "Отменён", 6: "Сборка заказа", 8: "Доставка", 10: "Возврат", 11: "Завершен", 12: "Ожидает оплаты", 13: "Ожидание товара", 14: "Обработка", 15: "Готов к доставке", 16: "Обмен", 17: "Не доставлен"},
            defaultFilter: ["3"],
            title: "Статус",
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        {
            field: "payment_method",
            filtering: false,
            title: "Способ оплаты",
            render: (rowData) => rowData.paymentMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "phone",
            filtering: false,
            title: "Телефон",
        },
        {
            field: "delivery_method",
            filtering: false,
            title: "Способ доставки",
            render: (rowData) => rowData.deliveryMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            filtering: false,
            title: "Сервис доставки",
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "promo_code",
            filtering: false,
            title: "Промокод",
            render: (rowData) => rowData?.promoCode || "Не указан",
        },
        {
            field: "amount_discount",
            filtering: false,
            title: "Скидка",
            render: (rowData) => numToRubFormat(rowData?.amountDiscount || 0),
        },
        {
            field: "total_price",
            filtering: false,
            title: "Общая цена",
            render: (rowData) => numToRubFormat(rowData.totalPrice),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "created_at",
            filtering: false,
            title: "Дата создания",
            render: (rowData) => Order.dateFormat(rowData.createdAt),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "sum_past_orders",
            filtering: false,
            title: "sum_past_orders",
            render: (rowData) => numToRubFormat(rowData.sumPastOrders),
            hidden: true,
        },
    ];

    const DetailPanel: DataTableProps<Order>["detailPanel"] = ({ rowData }) => {
        return (
            <>
                {rowData.items.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <img
                                        src={item.product?.attr?.images?.display?.[0]}
                                        height="100px"
                                        width="100px"
                                        alt="Фото товара"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    Кол-во: {item.count}
                                </Grid>
                                <Grid item xs={2}>
                                    Артикул: {item.product?.attr?.sku?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Размер: {item.product?.attr?.size?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Цвет: {item.product?.attr?.color?.value || ""}
                                </Grid>
                                <Grid item xs={2}>
                                    Скидка: 0
                                </Grid>
                                <Grid item xs={2}>
                                    Цена продажи: {item.salePrice}
                                </Grid>
                            </Grid>
                            <Divider />
                        </React.Fragment>
                    );
                })}
                <Row>
                    <Box gridColumn="span 12">
                        <Typography>
                            <b>Ранее было куплено на сумму: {numToRubFormat(rowData.sumPastOrders)}</b>
                        </Typography>
                    </Box>
                </Row>
                <Row>
                    <Box gridColumn="span 12">
                        <Typography>
                            <b>Комментарии:</b>
                        </Typography>
                    </Box>
                </Row>
                <Divider />
                <Grid container sx={{ marginBottom: "10px", marginTop: "5px" }} alignItems="center" spacing={1}>
                    <Grid item xs={2}>
                        <Typography>
                            Комментарий для менеджера
                        </Typography>
                    </Grid>
                    <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={2}>
                        {rowData.commentaryForManager}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container sx={{ marginBottom: "10px", marginTop: "5px" }} alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <Typography>
                            Комментарий для клиента
                        </Typography>
                    </Grid>
                    <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={2}>
                        {rowData.commentaryForClient}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container sx={{ marginBottom: "10px", marginTop: "5px" }} alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <Typography>
                            Комментарий для курьера
                        </Typography>
                    </Grid>
                    <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={2}>
                        {rowData.commentToTheCourier}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container sx={{ marginBottom: "10px", marginTop: "5px" }} alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <Typography>
                            Комментарий для магазина
                        </Typography>
                    </Grid>
                    <Grid sx={{ whiteSpace: 'pre-wrap' }} item xs={2}>
                        {rowData.commentaryForMagazine}
                    </Grid>
                </Grid>
            </>
        )
    };


    return (
        <>
            <Button onClick={openCreateModal} variant="contained" sx={{ marginBottom: "20px"}}>Добавить новый заказ</Button>
            <DataTable<Order>
                title="Новые заказы"
                columns={columns}
                fetchFn={api.getAllOrders}
                detailPanel={DetailPanel}
                onRowClick={toOrderDetail}
                sorting
                options={{
                    rowStyle: (rowData) => ({
                        backgroundColor: Order.getStatusColor(rowData.createdAt, !!rowData.manager),
                    }),
                    headerStyle: { ...TableHeadStyles }
                }}
                customActions={[
                    {
                        tooltip: "Взять в работу",
                        name: "take",
                        onClick: handleTakeToWork,
                        icon: "add_task",
                        color: "primary",
                        isDisabled: (rowData) => !!rowData.manager,
                    },
                    {
                        tooltip: "Подтвердить",
                        name: "confirm",
                        onClick: handleConfirm,
                        icon: "check",
                        color: "primary",
                        isDisabled: (rowData) => rowData.status !== 3,
                    },
                ]}
            />
        </>
    );
};

export default NewOrdersTable;
