import React from "react";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Alert, MenuItem, Select } from "@mui/material";
import { Button } from "@material-ui/core";
import { SiteSelect } from "../Select/Site";
import { Core } from "./Core";
import FormControl from "../Common/FormControl";
import Input from "../Common/Input/Input";
import api, { Integration } from "../../api";
import BrandAutocomplete from "../Autocomplete/Brand";

interface IntegrationFormProps {
    initData?: Integration;
    handleSuccess: () => void;
}

interface Errors {
    non_field_errors?: string[]
}

const validationSchema = yup.object({
    content_type: yup.string().required('Обязательное поле'),
    core_id: yup.number().required('Обязательное поле'),
    site: yup.number().required('Обязательное поле'),
});

const contentTypes = [['catalog', 'CATALOG'], ['product', 'PRODUCT'], ['product_sku', 'SKU']];

const IntegrationForm: React.FunctionComponent<IntegrationFormProps> = ({
    initData,
    handleSuccess
}) => {

    const handleSubmit = async (values, { setErrors }) => {
        try {
            if(initData?.id) {
                await api.updateIntegration(initData?.id, values);
            } else {
                await api.createIntegration(values);
            }
            handleSuccess();
        } catch (error: any) {
            const { data } = error?.response;
            setErrors(data)
        }
    }

    const formik = useFormik({
        initialValues: initData ? initData : {
            content_type: '',
            core_id: '',
            attrs: [],
            site: '',
            brands: []
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    const errors = formik.errors as Errors;
    return (
        <form onSubmit={formik.handleSubmit}>
            <FormControl label="Модель в CMS" name="content_type">
                <Select
                    disabled={Boolean(initData?.id)}
                    label={'Модель в CMS'}
                    name={"content_type"}
                    value={formik.values.content_type}
                    onChange={formik.handleChange}
                >
                    {contentTypes.map((item) => <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>)}
                </Select>
            </FormControl>
            <SiteSelect
                disabled={Boolean(initData?.id)}
                value={formik.values.site}
                onChange={formik.handleChange}
                name={'site'}
                label="Сайт"
            />
            <Input
                disabled={Boolean(initData?.id)}
                margin={'normal'}
                fullWidth
                id="core_id"
                name="core_id"
                label="ID типа сущности"
                value={formik.values.core_id}
                onChange={formik.handleChange}
            />
            <BrandAutocomplete name="brands" label="Бренды" value={formik.values.brands} onChange={formik.setFieldValue} />
            <Core
                id={formik.values.core_id}
                handleChange={(value) => formik.setFieldValue('attrs', value)}
                initUsed={formik.values.attrs}
            />
            {errors?.non_field_errors ? (
                <Alert severity="error">
                    {errors?.non_field_errors?.[0]}
                </Alert>
            ) : null}
            <Button disabled={formik.isSubmitting} variant="contained" fullWidth sx={{ mt: 2 }} type={"submit"}>
                {initData?.id ? 'Обновить' : 'Создать'}
            </Button>
        </form>
    );
};

export default IntegrationForm;
