import React from "react";
import Authorization from "../components/Authorization";
import Layout from "../components/Common/Layout";

const LoginPage: React.FunctionComponent = () => {
    return (
        <Layout disableSidebar>
            <Authorization />
        </Layout>
    );
};

export default LoginPage;
