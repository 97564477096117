import BaseApi from "@garpix/base-api";
import { setCookie, getCookie, removeCookie, getData } from "../utils";
import {
    ClassConstructor,
    ClassTransformOptions, Exclude, Expose,
    plainToClass,
    plainToClassFromExist,
    Transform,
} from "class-transformer";
import {
    Order,
    CreateOrder,
    PaginatedQueryResult,
    User,
    ReturnRequest,
    Site,
    Permissions,
    GoodsItem,
    CreatePayment,
    Courier,
    OrderSet,
    Shop,
    ContextHelp
} from './models';
import { OrderItem } from "./models/Order.model";

interface QueryParams {
    page?: number;
    page_size?: number;
    q?: string;
    sorting?: string;
    ids_list?: number[] | string | number;
    [key: string]: any;
}

@Exclude()
export class Integration {
    @Expose() id?: number;
    @Expose() content_type: string;
    @Expose() core_id: string;
    @Expose() attrs: any;
    @Expose() site: string;
    @Expose() site_name?: string;
    @Expose() 
    @Transform(({ value }) => Array.isArray(value) ? value : [])
    brands: Brand[];
}

export type REQUIRED_TYPE = "not" | "full" | "part";

interface EntityTypeItem {
    id: number;
    type_id: number;
    atype_id: number;
    required: REQUIRED_TYPE;
    sorting: number;
}

interface EntityType {
    id: number;
    name: string;
    notes?: string;
    // is_active: boolean;
}

interface AttributeTypeItem {
    id: number;
    sorting: number;
    type_id: number;
    vtype_id: number;
    restrictions: object;
}

export interface AttributeType {
    id: number;
    name: string;
    notes: string;
    display_name: string;
    multiple_choice: boolean;
    composite_choice: boolean;
    items: AttributeTypeItem[];
}

export interface AttributeTypeMini {
    id: number;
    name: string;
    display_name: string;
}

export interface Brand {
    id: number;
    title: string;
}

class ApiCore extends BaseApi {
    token: string = '';

    getAuthToken = () => this.token

    axiosOverride = (axios) => {
        const token = this.getAuthToken();
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        axios.defaults.headers.common["Accept-Language"] = 'ru';
        return axios;
    };

    login = async () => {
        const user = 'superuser';
        const password = 'password';
        const { data } = await this.post("/login", {
            user,
            password,
        });
        this.token = data.data.token;

        return data;
    }


    getEntityTypes = async (
        params: QueryParams = {}
    ): Promise<{result: {
        items: EntityType[]
    }}> => {
        const { data } = await this.get("/nsi_meta/etype", params);

        return data;
    };

    getAttributeTypes = async (
        params: QueryParams = {}
    ): Promise<{result: {
        items: AttributeType[]
    }}> => {
        const { data } = await this.get("/nsi_meta/atype", params);

        return data;
    };

    getNsiMetaEtypeItem = async (
        params: QueryParams = {}
    ): Promise<{result: {
        items: EntityTypeItem[]
    }}> => {
        const { data } = await this.get("/nsi_meta/etype_item", params);

        return data;
    };

    getAttrs = async (
        params: QueryParams = {}
    ): Promise<AttributeTypeMini[]> => {
        const resMeta = await this.getNsiMetaEtypeItem(params);
        const meta = resMeta?.result?.items || [];
        const values = await Promise.all(
            meta.map((item) => this.getAttributeTypes({id: item.atype_id}))
        );
        const data = values.map((item) => item.result.items).flat();
        return data.map((item) => {
            return {
                id: item.id,
                name: item.name,
                display_name: item.display_name
            }
        });
    }

    getBrands = async (params = {}): Promise<{ result: Brand[] }> => {
        const { data } = await this.get("/staff_brand_bind", params);
        return data;
    }
}

class Api extends BaseApi {
    AUTH_TOKEN_KEY = "auth_token";
    REFRESH_TOKEN_KEY = "refresh_token";

    getLanguage = () => {
        return "ru";
    };

    axiosOverride = (axios) => {
        const Token = this.getAuthToken();
        const language = this.getLanguage();
        axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
        axios.defaults.headers.common["Accept-Language"] = language;
        return axios;
    };

    getAuthToken = () => {
        return getCookie(this.AUTH_TOKEN_KEY);
    };

    setAuthToken = (token) => {
        setCookie(this.AUTH_TOKEN_KEY, token, 365);
        return true;
    };

    getRefreshToken = () => {
        return getCookie(this.REFRESH_TOKEN_KEY);
    };

    setRefreshToken = (token) => {
        setCookie(this.REFRESH_TOKEN_KEY, token, 365);
        return true;
    };

    removeAuthToken = () => {
        try {
            removeCookie(this.AUTH_TOKEN_KEY);
            return true;
        } catch {
            return false; // no key
        }
    };

    removeRefreshToken = () => {
        try {
            removeCookie(this.REFRESH_TOKEN_KEY);
            return true;
        } catch {
            return false; // no key
        }
    };

    plainToClass<M, V = {}>(
        Model: ClassConstructor<M>,
        data: V,
        options?: ClassTransformOptions
    ): M {
        return plainToClass<M, V>(Model, data, options);
    }

    plainToClassArray<M, V = {}>(
        Model: ClassConstructor<M>,
        data: V[],
        options?: ClassTransformOptions
    ): M[] {
        return plainToClass<M, V>(Model, data, options);
    }

    plainToClassFromExist<M, V = unknown>(Model: M, data: V): M {
        return plainToClassFromExist<M, unknown>(Model, data);
    }

    login = async (params) => {
        const { data } = await this.post("/auth/login/", params);
        this.setAuthToken(data.access_token);
        this.setRefreshToken(data.refresh_token);
        return data;
    };

    refresh = async (params) => {
        const { data } = await this.post("/auth/refresh/", params);
        this.setAuthToken(data.access_token);
        return data;
    };

    getGoodsItems = async (params) => {
        const { data } = await this.get("/v1/catalog/product_sku/", params)
        return this.plainToClassFromExist(new PaginatedQueryResult(GoodsItem), data);
    }

    createOrder = async (params): Promise<Order> => {
        params.city_for_admin = params.city_name;
        const { data } = await this.post("/v1/order/", params);
        return this.plainToClass(Order, data);
    };

    getAllOrders = async (params): Promise<PaginatedQueryResult<Order>> => {
        const { data } = await this.get("/v1/order/", params);

        return this.plainToClassFromExist(new PaginatedQueryResult(Order), data);
    };

    getManagers = async (params): Promise<PaginatedQueryResult<Order>> => {
        const { data } = await this.get("/v1/order/managers/manager/", params);

        return this.plainToClassFromExist(new PaginatedQueryResult(Order), data);
    };

    getSuperManagers = async (params): Promise<PaginatedQueryResult<Order>> => {
        const { data } = await this.get("/v1/order/managers/supermanager/", params);

        return this.plainToClassFromExist(new PaginatedQueryResult(Order), data);
    };

    setManager = async (orderId: number, userId: number): Promise<Order> => {
        const { data } = await this.post(`/v1/order/${orderId}/set_manager/`, { manager: userId });

        return this.plainToClass(Order, data);
    };

    getLogistics = async (params): Promise<PaginatedQueryResult<Order>> => {
        const { data } = await this.get("/v1/order/managers/logistic/", params);

        return this.plainToClassFromExist(new PaginatedQueryResult(Order), data);
    };

    setLogistician = async (orderId: number, userId: number): Promise<Order> => {
        const { data } = await this.post(`/v1/order/${orderId}/set_logistician/`, {
            logistician: userId,
        });

        return this.plainToClass(Order, data);
    };

    getReturnRequests = async (params): Promise<PaginatedQueryResult<ReturnRequest>> => {
        const { data } = await this.get("/v1/order/return_request/", params);

        return this.plainToClassFromExist(new PaginatedQueryResult(ReturnRequest), data);
    };

    getReturnRequest = async (id: number): Promise<ReturnRequest> => {
        const { data } = await this.get(`/v1/order/return_request/${id}/`, {});

        return this.plainToClass(ReturnRequest, data);
    };

    createReturnRequests = async (params): Promise<any> => {
        const { data } = await this.post("/v1/order/return_request/", params);
        return data
    };

    updateReturnRequests = async (id, params): Promise<any> => {
        const { data } = await this.patch(`/v1/order/return_request/${id}/`, params);
        return data
    };

    transitionReturnRequestPackageSent = async (id): Promise<any> => {
        const { data } = await this.post(`/v1/order/return_request/${id}/transition_package_sent/`, {});
        return data;
    }

    transitionReturnRequestClose = async (id): Promise<any> => {
        const { data } = await this.post(`/v1/order/return_request/${id}/transition_close/`, {});
        return data;
    }

    transitionReturnRequestRejected = async (id): Promise<any> => {
        const { data } = await this.post(`/v1/order/return_request/${id}/rejected/`, {});
        return data;
    }

    getOrderDetail = async (orderId: number): Promise<Order> => {
        const { data } = await this.get(`/v1/order/${orderId}/`, {})
        return this.plainToClass(Order, data);
    }

    updateOrderDetail = async (orderId: number, order: any): Promise<any> => {
        const params = this.plainToClass(CreateOrder, order);
        const { data } = await this.patch(`/v1/order/${orderId}/`, {...params})
        return data;
    }

    getOrderSets = async (params): Promise<PaginatedQueryResult<OrderSet>>  => {
        const { data } = await this.get(`/v1/order/set_order/`, params)
        return data;
    }

    setNewSet = async () => {
        const { data } = await this.post(`/v1/order/set_order/`, {})
        return data;
    }

    getCouriersList =  async (params): Promise<PaginatedQueryResult<Courier>> => {
        const { data } = await this.get("/v1/order/courier/", params);
        return this.plainToClassFromExist(new PaginatedQueryResult(Courier), data);
    }

    createCourier = async (name:string): Promise<Courier> => {
        const { data } = await this.post("/v1/order/courier/", { name })
        return data
    }

    updateCourier = async (id:number,name:string): Promise<Courier> => {
        const { data } = await this.patch(`/v1/order/courier/${id}/`, { name })
        return data
    }

    deleteCourier = async (id:number) => {
        const { data } = await this.delete(`/v1/order/courier/${id}/`, {})
        return data
    }

    getContextHelpList = async (params): Promise<PaginatedQueryResult<ContextHelp>> => {
        const { data } = await this.get("/v1/multisite/context_help/", params);
        return this.plainToClassFromExist(new PaginatedQueryResult(ContextHelp), data);
    }

    updateContextHelp = async (id:number, content:string) : Promise<ContextHelp> => {
        const { data } = await this.patch(`/v1/multisite/context_help/${id}/`, { content });
        return data
    }

    deleteContextHelp = async (id:number): Promise<any> => {
        return await this.delete(`/v1/multisite/context_help/${id}/`, {});
    }

    createContextHelp = async (params:{url:string, content:string}) : Promise<ContextHelp> => {
        const { data } = await this.post( '/v1/multisite/context_help/', params)
        return data
    }

    transitionAssemble = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_assemble/`, {});
        const data = res.data;
        return data;
    };

    transitionCancel = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_cancel/`, {});
        const data = res.data;
        return data;
    };

    transitionConfirm = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_confirm/`, {});
        const data = res.data;
        return data;
    };

    transitionNew = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_new/`, {});
        const data = res.data;
        return data;
    };

    transitionOnWay = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_on_way/`, {});
        const data = res.data;
        return data;
    };

    transitionPaid = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_paid/`, {});
        const data = res.data;
        return data;
    };

    transitionReceived = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_received/`, {});
        const data = res.data;
        return data;
    };

    transitionReturned = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_returned/`, {});
        const data = res.data;
        return data;
    };

    transitionSubmitted = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_submitted/`, {});
        const data = res.data;
        return data;
    };

    transitionWaitProcess = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_wait_process/`, {});
        const data = res.data;
        return data;
    };

    transitionClosed = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_closed/`, {});
        const data = res.data;
        return data;
    };

    transitionReadyDelivery = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_ready_delivery/`, {});
        return res.data;
    }

    transitionDelivery = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_delivery/`, {});
        return res.data;
    }

    transitionUndelivered = async (id) => {
        const res = await this.post(`/v1/order/${id}/transition_undelivered/`, {});
        return res.data;
    }

    getOrderPaymentList = async (id) => {
        const res = await this.get(`/v1/order/payment/`, { order: id });
        const data = res.data;
        return data;
    }

    getAllPaymentList = async (params): Promise<PaginatedQueryResult<any>> => {
        const res = await this.get(`/v1/order/payment/`, params);
        const data = res.data;
        return data;
    }

    createOrderPayment = async (paymentData) => {
        const params = this.plainToClass(CreatePayment, paymentData);
        const res = await this.post(`/v1/order/payment/`, params);
        const data = res.data;
        return data;
    }

    deleteOrderPayment = async (id) => {
        const res = await this.delete(`/v1/order/payment/${id}/`, {});
        return res.data;
    }

    getOrderChangelog = async (params): Promise<PaginatedQueryResult<any>> => {
        const res = await this.get(`/v1/changelog/`, params);
        const data = res.data;
        data.results = data.results.map(item => getData(item))

        return data;
    }

    getOrderGoodsList = async (params): Promise<PaginatedQueryResult<OrderItem>> => {
        const { data } = await this.get(`/v1/order/item/`, params)
        return this.plainToClassFromExist(new PaginatedQueryResult(OrderItem), data);
    }

    updateOrderProduct = async ({ id, ...params }) => {
        const res = await this.patch(`/v1/order/item/${id}/`, { ...params })
        return res.data;
    }

    createOrderProduct = async (params) => {
        const res = await this.post(`/v1/order/item/create_item_order/`, params)
        return res.data;
    }

    deleteOrderItem = async (id) => {
        const res = await this.delete(`/v1/order/item/${id}/`, {});
        const data = res.data;
        return data;
    }

    getUsersList = async (params?): Promise<PaginatedQueryResult<User>> => {
        const { data } = await this.get("/v1/user/", params);
        return this.plainToClassFromExist(new PaginatedQueryResult(User), data);
    }

    currentUser = async () => {
        const { data } = await this.get("/v1/user/current/", {});

        return this.plainToClass(User, data);
    };

    getPermissions = async (): Promise<Permissions> => {
        const { data } = await this.get("/v1/user/user_permissions/", {});

        return data;
    };

    getAllShops = async (): Promise<PaginatedQueryResult<Shop>> => {
        const { data } = await this.get('/v1/multisite/shop/', { page_size: 100 });
        return data;
    }

    getAllSite = async (): Promise<PaginatedQueryResult<Site>> => {
        const { data } = await this.get('/v1/multisite/site/', { page_size: 100 });
        return data;
    }

    createIntegration = async (params): Promise<any> => {
        const { data } = await this.post('/v1/multisite/integration/', params);
        return data;
    }

    deleteIntegration = async (id: string | number): Promise<any> => {
        const { data } = await this.delete(`/v1/multisite/integration/${id}/`, {});
        return data;
    }

    updateIntegration = async (id, params): Promise<any> => {
        const { data } = await this.patch(`/v1/multisite/integration/${id}/`, params);
        return data;
    }

    getIntegrations = async (params): Promise<PaginatedQueryResult<Integration>> => {
        const { data } = await this.get("/v1/multisite/integration/", params);

        return this.plainToClassFromExist(new PaginatedQueryResult(Integration), data);
    }

    getSkuForInvoices = async (): Promise<any> => {
        const { data } = await this.get('/v1/order/sku_for_invoices/', {});
        return data
    }

    manyExpectGoods = async (): Promise<any> => {
        const { data } = await this.post('/v1/order/many_expect_goods/', {});
        return data;
    }

    getInvoiceDates = async (): Promise<any> => {
        const { data } = await this.get('/v1/order/invoice_dates/', {})
        return data;
    }

    updateDelivery = async (id: number, params: any): Promise<any> => {
        const { data } = await this.patch(`/v1/order/delivery/${id}/`, params);
        return data;
    }

    createDelivery = async (params: any): Promise<any> => {
        const { data } = await this.post(`/v1/order/delivery/`, params);
        return data;
    }

    getDeliveryPriceTotal = async (params?): Promise<any> => {
        const { data } = await this.get(`/v1/order/delivery_price_total/`, params);
        return data;
    }
}

const MAIN_URL = process.env.REACT_APP_API_URL;
const CORE_URL = process.env.REACT_APP_CORE_PROXY_URL;

const api = new Api(`${MAIN_URL}`);
const apiCore = new ApiCore(`${CORE_URL}`);

export default api;
export {
    apiCore
}
