import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";

type ModalProps = { email: string; onUpdate(): void };

type WaitProcessingOrderModalProps = WithModalComponentProps<ModalProps>;

const WaitProcessingOrderModal: React.FunctionComponent<WaitProcessingOrderModalProps> = ({
    onClose,
    modalProps,
}: WaitProcessingOrderModalProps) => {
    const { id, email, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (!id) return;

        try {
            await api.transitionWaitProcess(id);
            enqueueSnackbar("Успешно переведено в ожидание обработки", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Перевести в ожидание обработки заказ пользователя <b>{email}</b> с номером{" "}
                    <b>{id}</b>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Перевести в ожидание обработки
                </Button>
            </DialogActions>
        </>
    );
};

export default WaitProcessingOrderModal;
