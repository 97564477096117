import React from "react";
import {
  FormControl as Control,
  FormHelperText,
  InputLabel
} from "@mui/material";

const FormControl: React.FunctionComponent<any> = ({
  children = null,
  error = false,
  name = "",
  label = null,
  helperText = null,
  ...props
}) => {
  return (
    <Control
      size="small"
      margin={'normal'}
      fullWidth
      error={error}
      {...props}
    >
      {label ? <InputLabel id={name}>{label}</InputLabel> : null}
      {children}
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </Control>
  )
}

export default FormControl