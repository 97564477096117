import React from "react";
import Layout from "../components/Common/Layout";
import Cashless from "../components/Cashless";

const CashlessPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <Cashless />
        </Layout>
    );
};

export default CashlessPage;
