import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams, generatePath } from 'react-router';
import { Box, Button, List, ListItem, ListItemText } from "@mui/material";
import { ArrowBack } from '@material-ui/icons';
import { OrderPaymentTable } from '../Tables';
import Row from '../Common/Row';
import useModal from '../../hooks/useModal';
import { Paths } from '../../constants';
import api from '../../api';

const OrderPayment: React.FunctionComponent<any> = ({ order, getOrder }) => {
  const { id } : any = useParams();
  const [paymentList, setPaymentList] = useState<any>([]);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleErrorRequest = (err) => {
    const { data } = err?.response;
    enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
  }

  const getList = () => {
    api.getOrderPaymentList(id)
      .then(res => setPaymentList(res.results))
      .catch(err => handleErrorRequest(err))
  }

  const updateData = () => {
    getOrder();
    getList();
  }

  useEffect(() => {
    getList();
  }, [])

  const changePaymentMethod = (e) => {
    openModal("ADD_ORDER_PAYMENT", { id, onUpdate: updateData });
  }

  if(order) {
    const { orderNumber, totalPaid, balanceOrder } = order;

    return (
      <>
        <Row>
          <Box gridColumn="span 12">
            <Button
              href={generatePath(Paths.ORDER_DETAIL.path, { id })}
              sx={{ marginBottom: "10px" }}
              variant="outlined"
              startIcon={<ArrowBack />}
            >
              Вернуться к заказу
            </Button>
          </Box>
          
          <Box gridColumn="span 6">
            <h2 style={{ margin: "0" }}>Заказ {orderNumber}. Оплата</h2>
          </Box>
        </Row>
        <Row columnGap={3}>
          <Box gridColumn="span 6">
            <Button variant="contained" onClick={changePaymentMethod}>Добавить платеж</Button>
          </Box>
          <Box gridColumn="span 6">
            <List sx={{ border: "1px solid grey", padding: "0"}} dense>
                <ListItem sx={{ paddingTop: "0", paddingBottom: "0" }}>
                  <ListItemText primary={`Всего уплачено`} />
                  <ListItemText sx={{ textAlign: "right" }} primary={`${totalPaid} руб.`} />
                </ListItem>
                <ListItem sx={{ borderTop: "1px solid grey", paddingTop: "0", paddingBottom: "0" }}>
                  <ListItemText primary={`Баланс заказа`} />
                  <ListItemText sx={{ textAlign: "right" }} primary={`${balanceOrder} руб.`} />
                </ListItem>
            </List>
          </Box>
        </Row>
        {paymentList && paymentList.length > 0 && (
          <Row>
            <Box gridColumn="span 12">
              <OrderPaymentTable data={paymentList} updateData={updateData} />
            </Box>
          </Row>
        )}
      </>
    )
  }
  return null
}

export default OrderPayment;