import React from "react";
import Layout from "../components/Common/Layout";
import Acceptance from "../components/Tables/Acceptance";
import {useModal} from '../hooks';
import {Button} from '@mui/material';

const AcceptancePage: React.FunctionComponent = () => {
    return (
        <Layout>
            <Acceptance />
        </Layout>
    );
};

export default AcceptancePage;
