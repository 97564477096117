import React from "react";
import { SelectProps } from "@mui/material/Select/Select";
import BaseSelect from "./BaseSelect";
import FormControl from "../Common/FormControl";
import { DeliveryMethod } from "../../constants";

interface PropsTypes extends SelectProps {
    helperText?: string;
    error?: any;
}

const DeliveryMethodSelect: React.FunctionComponent<PropsTypes> = ({
    onChange,
    value,
    label,
    name,
    error,
    helperText,
    ...props
}) => {
    return (
        <FormControl error={error} name={name} label={label} helperText={helperText}>
            <BaseSelect
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                options={DeliveryMethod}
                {...props}
            />
        </FormControl>
    )
}

export {
  DeliveryMethodSelect
}