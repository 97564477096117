import React from "react";
import Layout from "../components/Common/Layout";
import Assembly from "../components/Tables/Assembly";

const AssemblyPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <Assembly />
        </Layout>
    );
};

export default AssemblyPage;
