import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Circle, Delete } from "@material-ui/icons";
import dayjs from "../../../utils/dayjs";
import api from '../../../api';
import { TableCellStyles, TableHeadStyles } from '../../../styles';

const STATUS_COLORS = {
  waiting: "#fefe77",
  paid: "#1fbe1f",
  cancellation: "red"
}

const METHOD_DISPLAY = {
  ACCOUNT_LEGAL_ENTITY: "Оплата по счету от юридического лица",
  ACCOUNT_FL: "Оплата по счету от физического лица",
  ONLINE: "Оплата банковской картой на сайте",
  CASHLESS: "Безналичный рассчет",
  UPON_RECEIPT: "При получении",
}

const OrderPaymentTable: React.FunctionComponent<any> = ({ data, updateData }) => {

  const [rows, setRows] = useState(data);
  const { enqueueSnackbar } = useSnackbar();

  const handleErrorRequest = (err) => {
    const { data } = err?.response;
    enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
  }
  const deletePayment = (e, id) => {
    api.deleteOrderPayment(id)
      .then(res => updateData())
      .catch(err => handleErrorRequest(err))
  }

  useEffect(() => {
    if(data) {
      setRows(data)
    }
  }, [data])

  return (
    <TableContainer component={Card}>
      <Table>
        <TableHead sx={{ ...TableHeadStyles }}>
          <TableRow>
            <TableCell sx={{ ...TableCellStyles }}>Статус</TableCell>
            <TableCell sx={{ ...TableCellStyles }}>Дата создания</TableCell>
            <TableCell sx={{ ...TableCellStyles }}>Дата оплаты</TableCell>
            <TableCell sx={{ ...TableCellStyles }}>Способ оплаты</TableCell>
            <TableCell sx={{ ...TableCellStyles }}>Удаленный ID</TableCell>
            <TableCell sx={{ ...TableCellStyles }}>Сообщение о результате</TableCell>
            <TableCell sx={{ ...TableCellStyles }}>Сумма</TableCell>
            <TableCell sx={{ ...TableCellStyles }} align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Circle sx={{ color: STATUS_COLORS[row.status], width: "1.5em", height: "1.5em" }} />
              </TableCell>
              <TableCell>{dayjs(row.created_at).format("DD.MM.YYYY")}</TableCell>
              <TableCell>{row.date_of_payment}</TableCell>
              <TableCell>{METHOD_DISPLAY[row.payment_method]}</TableCell>
              <TableCell>{row.payment_uuid}</TableCell>
              <TableCell>{row.notes}</TableCell>
              <TableCell>{row.price} руб.</TableCell>
              <TableCell align="right">
                <Button onClick={(e) => deletePayment(e, row.id)}>
                  <Delete />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OrderPaymentTable
