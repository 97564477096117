import { Formik, FormikProps, Form, FormikConfig } from "formik";
import * as Yup from "yup";
import React, { forwardRef } from "react";
import { Button } from "@mui/material";
import Input from "../../Common/Input/Input";

interface LoginUser {
    username: string;
    password: string;
}

const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Пожалуйста, укажите ваше имя"),
    password: Yup.string().required("Пожалуйста, укажите пароль"),
});

export type LoginFormProps = Pick<FormikConfig<LoginUser>, "onSubmit">;

const LoginForm = forwardRef<FormikProps<LoginUser>, LoginFormProps>(
    ({ onSubmit }: LoginFormProps, ref) => {
        return (
            <Formik<LoginUser>
                initialValues={{
                    username: "",
                    password: "",
                }}
                innerRef={ref}
                validationSchema={LoginSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values, isSubmitting }) => {
                    return (
                        <Form>
                            <Input
                                name="username"
                                type="text"
                                value={values.username}
                                onChange={handleChange}
                                helperText={touched.username ? errors.username : ""}
                                error={touched.username && !!errors.username}
                                fullWidth
                                margin="normal"
                                label="Логин"
                                size="small"
                                required
                            />
                            <Input
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password ? errors.password : ""}
                                error={touched.password && !!errors.password}
                                fullWidth
                                margin="normal"
                                label="Пароль"
                                size="small"
                                required
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Входим..." : "Войти"}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default LoginForm;
