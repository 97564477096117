import React from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import { TransportCompanySelect } from "../../Select/TransportCompany";
import Row from "../../Common/Row";
import { DateInput } from "../../Common/Input/Date";
import { TimeInput } from "../../Common/Input/Time";
import Input from "../../Common/Input/Input";
import { ShopSelect } from "../../Select/Shop";

interface ValuesTypes {
  apartmentOffice: string;
  cityName: string;
  commentaryForClient: string;
  commentToTheCourier: string;
  commentaryForManager: string;
  costOfDelivery: number;
  deliveryDate: any;
  deliveryServices: number;
  deliveryTimeFrom: any;
  deliveryTimeTo: any;
  house: number;
  hullBuilding: string;
  index: string;
  region: string;
  shop: number;
  street: string | number;
}

interface DeliveryProps {
  handleChange: any;
  setFieldValue?: any;
  values: ValuesTypes;
  errors: object;
  touched: object;
}

const DeliveryForm: React.FunctionComponent<DeliveryProps> = ({ handleChange, setFieldValue, values, errors, touched}) => {
  const {
    apartmentOffice,
    cityName,
    commentaryForClient,
    commentToTheCourier,
    commentaryForManager,
    costOfDelivery,
    deliveryDate,
    deliveryServices,
    deliveryTimeFrom,
    deliveryTimeTo,
    house,
    hullBuilding,
    index,
    region,
    shop,
    street,
  } = values;

  const setDateTime = (value: string, name: string) => {
    setFieldValue(name, value)
  }

  return (
    <>
      <Box gridColumn="span 12">
        <h3 style={{marginBottom: "0"}}>Информация о доставке</h3>
      </Box>
      <Box gridColumn="span 12">
        <h4>Адрес доставки</h4>
      </Box>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Индекс</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            fullWidth
            name="index"
            value={index}
            onChange={handleChange}
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Регион</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            value={region}
            name="region"
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Город</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            value={cityName}
            name="cityName"
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Улица</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            value={street}
            name="street"
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Дом</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            value={house}
            name="house"
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Корпус/строение</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            fullWidth
            value={hullBuilding}
            onChange={handleChange}
            name="hullBuilding"
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Квартира/офис</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            value={apartmentOffice}
            name="apartmentOffice"
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </Row>

      <Box gridColumn="span 12">
        <h4>Детали доставки</h4>
      </Box>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Магазин</InputLabel> 
        </Box>
        <Box gridColumn="span 6">
          <ShopSelect
            name="shop"
            onChange={handleChange}
            value={shop}
          />
        </Box>
      </Row>

      <Row other={{ sx: { marginTop: "0px" }}}>
        <Box gridColumn="span 3">
          <InputLabel>Транспортная компания</InputLabel> 
        </Box>
        <Box gridColumn="span 6">
          <TransportCompanySelect
            name="deliveryServices"
            onChange={handleChange}
            value={deliveryServices}
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Дата доставки</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <DateInput
            fullWidth
            name="deliveryDate"
            onChange={val => setDateTime(val, "deliveryDate")}
            value={deliveryDate || null}
          />
        </Box>
      </Row>
    
      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Время доставки</InputLabel>
        </Box>
        <Box gridColumn="span 3">
          <TimeInput
            value={deliveryTimeFrom || null}
            name="deliveryTimeFrom"
            fullWidth
            onChange={val => setDateTime(val, "deliveryTimeFrom")}
            label="От"
          />
        </Box>
        <Box gridColumn="span 3">
          <TimeInput
            onChange={val => setDateTime(val, "deliveryTimeTo")}
            value={deliveryTimeTo || null}
            name="deliveryTimeTo"
            fullWidth
            label="До"
          />
        </Box>
      </Row>

      <Row>
        <Box gridColumn="span 3">
          <InputLabel>Стоимость доставки</InputLabel>
        </Box>
        <Box gridColumn="span 6">
          <Input
            name="costOfDelivery"
            type="number"
            inputProps={{ inputMode: 'numeric' }}
            value={costOfDelivery}
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </Row>
    
      <Box gridColumn="span 9">
        <TextField
          value={commentToTheCourier}
          name="commentToTheCourier"
          onChange={handleChange}
          rows={3}
          multiline
          fullWidth
          label="Комментарий для курьера"
        />
      </Box>
      <Box gridColumn="span 9">
        <TextField
          rows={3}
          multiline
          fullWidth
          onChange={handleChange}
          label="Комментарий для менеджера"
          name="commentaryForManager"
          value={commentaryForManager}
        />
      </Box>
      <Box gridColumn="span 9">
        <TextField
          rows={3}
          name="commentaryForClient"
          value={commentaryForClient}
          multiline
          onChange={handleChange}
          fullWidth
          label="Комментарий для клиента"
        />
      </Box>
    </>
  )
}

export default DeliveryForm