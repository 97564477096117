import React from "react";
import Layout from "../components/Common/Layout";
import OrderDetailLayout from "../components/Common/Layout/OrderDetailLayout";
import OrderDetail from "../components/Common/OrderDetail";

const OrderDetailPage: React.FunctionComponent = () => {
  return (
    <Layout>
      <OrderDetailLayout>
        <OrderDetail />
      </OrderDetailLayout>
    </Layout>
  );
};

export default OrderDetailPage;