import React from "react";
import Layout from "../components/Common/Layout";
import Logistic from "../components/Logistic";

const LogisticPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <Logistic />
        </Layout>
    );
};

export default LogisticPage;
