import React from "react";
import Layout from "../components/Common/Layout";
import CashlessPaidInvoicesTable from "../components/Tables/CashlessPaidInvoicesTable";

const CashlessPaidInvoicesPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <CashlessPaidInvoicesTable />
        </Layout>
    );
};

export default CashlessPaidInvoicesPage;
