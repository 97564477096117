import React from 'react'
import NumberFormat from 'react-number-format';
import Input from "./Input";

interface PhoneProps {
  value?: string;
  name?: string;
  onChange?: any;
  error?: boolean | undefined;
  helperText?: string | undefined;
  label?: string;
  InputProps?: object; 
}

const CustomInput = (props) => (
  <Input
    {...props}
  />
)

const PhoneInput = React.forwardRef<any, any>(({ value, name, onChange, error, helperText, label, InputProps }, ref) => {
  return (
    <NumberFormat
      ref={ref}
      error={error}
      helperText={helperText}
      InputProps={InputProps}
      value={value}
      name={name}
      onValueChange={onChange}
      customInput={CustomInput}
      label={label}
      fullWidth
      type="tel"
      format="+###########"
      mask="_"
    />
  )
})

export {
  PhoneInput
}