import { Exclude, Expose, Transform, Type } from "class-transformer";

@Exclude()
class GoodsItem {
  @Expose() id: number;

  @Expose() attr: object;

  @Expose({ name: "sale_price" }) salePrice: number;
}

export default GoodsItem;