import { Avatar, Button, Fade, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { useState } from "react";

interface UserMenuProps {
    email: string;
    onLogout(): void;
}

const UserMenu: React.FunctionComponent<UserMenuProps> = ({ email, onLogout }: UserMenuProps) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <>
            <Button
                endIcon={<ArrowDropDown />}
                onClick={handleMenuOpen}
                color="inherit"
                startIcon={<Avatar alt="user">{email.charAt(0)}</Avatar>}
            >
                {email}
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    onClick={() => {
                        onLogout();
                        handleClose();
                    }}
                >
                    Выйти
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
