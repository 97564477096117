import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { NavLink } from "react-router-dom";

interface SidebarListItemProps {
    text: string;
    href: string;
    selected: boolean;
    icon?: React.ComponentType;
}

const SidebarListItem: React.FunctionComponent<SidebarListItemProps> = ({
    text,
    href,
    icon: Icon,
    selected,
}: SidebarListItemProps) => {
    return (
        <ListItem button component={NavLink} to={href || "/"} selected={selected}>
            {Icon ? (
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
            ) : null}
            <ListItemText primary={text} sx={{ wordWrap: "break-word" }} />
        </ListItem>
    );
};

export default SidebarListItem;
