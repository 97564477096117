import React from "react";
import { generatePath, useHistory } from "react-router";
import {Box, Divider, Grid } from "@mui/material";
import DataTable, { DataTableColumn, DataTableProps, RowAction } from "../../Common/DataTable";
import { useModal, useTable } from "../../../hooks";
import {Companies, Paths} from "../../../constants";
import { Order } from "../../../api/models";
import api from "../../../api";
import {OrderStatus} from "../../../api/models/Order.model";
import dayjs from "../../../utils/dayjs";
import DeliveryPriceTotal from "../../DeliveryPriceTotal";
import useDeliveryPriceTotal from "../../../hooks/useDeliveryPriceTotal";
import { TableHeadStyles } from "../../../styles";
import {numToRubFormat} from '../../../utils';

const InDelivery: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();
    const {deliveryPrice, refetch} = useDeliveryPriceTotal();

    const handleWaitProcessing: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("WAIT_PROCESSING_ORDER", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: () => {
                updateData();
                refetch()
            },
        });
    };

    const handleClosed: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CLOSED_ORDER", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: () => {
                updateData();
                refetch()
            },
        });
    };

    const handleCancel: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("UNDELIVERED_ORDER", {
            id: rowData.id,
            orderNumber: rowData.orderNumber,
            onUpdate: () => {
                updateData();
                refetch()
            }
        });
    };

    const toOrderDetail = async (e, rowData) => {
        const path = generatePath(Paths.ORDER_DETAIL.path, { id: rowData.id})
        history.push(path)
    }

    const columns: DataTableColumn<Order>[] = [
        {
            field: "order_number",
            filtering: false,
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "user_info",
            filtering: false,
            title: "Клиент",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.fullName}</div>
                        <div>{rowData.email}</div>
                        <div>
                            <b>{rowData.phone}</b>
                        </div>
                        <div>{rowData.address}</div>
                    </div>
                );
            },
        },
        {
            field: "status",
            filtering: false,
            title: "Статус",
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        {
            field: "delivery_method",
            filtering: false,
            title: "Способ доставки",
            render: (rowData) => rowData.deliveryMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            filtering: true,
            title: "Сервис доставки",
            lookup: Companies.reduce((acc,curr)=> (acc[curr.value] = curr.name,acc), {}),
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "total_price",
            filtering: false,
            title: "Общая цена",
            render: (rowData) => numToRubFormat(rowData.totalPrice),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "departure_date",
            filtering: false,
            title: "Дата отправки",
            render: (rowData) => {
                const date = dayjs(rowData.departureDate)
                if(!date.isValid()) {
                    return '';
                }
                return dayjs(rowData.departureDate).format('DD.MM.YYYY');
            },
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_date",
            filtering: false,
            title: "Дата доставки",
            render: (rowData) => {
                const date = dayjs(rowData.deliveryDate)
                if(!date.isValid()) {
                    return '';
                }
                return dayjs(rowData.deliveryDate).format('DD.MM.YYYY');
            },
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery__uuid",
            filtering: false,
            title: "Идентификатор",
            render: (rowData) => rowData.deliveryObj.uuid,
        },
    ];

    const DetailPanel: DataTableProps<Order>["detailPanel"] = ({ rowData }) => {
        return rowData.items.map((item) => {
            return (
                <React.Fragment key={item.id}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <img
                                src={item.product?.attr?.images?.display?.[0]}
                                height="100px"
                                width="100px"
                                alt="Фото товара"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            Кол-во: {item.count}
                        </Grid>
                        <Grid item xs={2}>
                            Артикул: {item.product?.attr?.sku?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Размер: {item.product?.attr?.size?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Цвет: {item.product?.attr?.color?.value || ""}
                        </Grid>
                        <Grid item xs={2}>
                            Скидка: 0
                        </Grid>
                        <Grid item xs={2}>
                            Цена продажи: {item.salePrice}
                        </Grid>
                    </Grid>
                    <Divider />
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <DeliveryPriceTotal data={deliveryPrice} />
            <DataTable<Order>
                title="В доставке"
                columns={columns}
                fetchFn={(params) => api.getAllOrders({...params, status__in: [OrderStatus.on_way].join(',')})}
                detailPanel={DetailPanel}
                onRowClick={toOrderDetail}
                sorting
                options={{
                    headerStyle: { ...TableHeadStyles }
                }}
                customActions={[
                    {
                        tooltip: "Уточнить",
                        name: "take",
                        onClick: toOrderDetail,
                        icon: "settings_backup_restore",
                        color: "primary",
                    },
                    {
                        tooltip: "Доставлен",
                        name: "confirm",
                        onClick: handleClosed,
                        icon: "check",
                        color: "primary",
                    },
                    {
                        tooltip: "Не доставлен",
                        name: "cancel",
                        onClick: handleCancel,
                        icon: "cancel",
                        color: "secondary",
                    },
                ]}
            />
        </>
    );
};

export default InDelivery;
