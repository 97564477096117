import React, { useEffect, useState } from "react";
import { Autocomplete } from '@mui/material';
import Input from "../Common/Input/Input";
import api from "../../api";
import { OrderStatus } from "../../api/models/Order.model";

interface OptionTypes {
  label: string,
  id: any,
  items: any
}

const OrderAutocomplete: React.FunctionComponent<any> = (props) => {
  const [options, setOptions] = useState<any>([]);
  const { setValue, value = "", name, label = "", id, user, error, helperText, status = OrderStatus.closed } = props;
  useEffect(() => {
    if(user) {
      api.getAllOrders({ user, status })
        .then(res => {
          const { results } = res;
          const list = [...results].map(el => {
            if(!el) return false
            return {
              label: `${el.id}`,
              id: el.id,
              items: el.items
            }
          })
          setOptions(list)
        })
        .catch(err => setOptions([]))
    }
  }, [user])

  const handleChange = (event: any, newValue: OptionTypes | null) => {
    if(newValue) {
      setValue(name, `${newValue.id}`)
      const products = [...newValue.items].map(el => ({
        label: el?.product?.attr?.sku?.value,
        id: el.id
      }))
      setValue("orderItems", products)
      setValue("items", [])
    }
  }

  return (
    <Autocomplete
      disablePortal
      id={id}
      options={options}
      // getOptionLabel={option => {
      //   return `${option.label}`
      // }}
      size="small"
      disabled={!user}
      value={value}
      onChange={handleChange}
      fullWidth
      disableClearable
      renderInput={(params) => (
        <Input
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          fullWidth
        />
      )}
    />
  )
}

export default OrderAutocomplete;