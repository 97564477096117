import React from "react";
import Layout from "../components/Common/Layout";
import InDelivery from "../components/Tables/InDelivery";

const InDeliveryPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <InDelivery />
        </Layout>
    );
};

export default InDeliveryPage;
