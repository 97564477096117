import React from "react";
import Layout from "../components/Common/Layout";
import InDelivery from "../components/Tables/InDelivery";
import InDeliveryPickup from "../components/Tables/InDelivery/Pickup";

const InDeliveryPickupPage: React.FunctionComponent = () => {
    return (
        <Layout>
            <InDeliveryPickup />
        </Layout>
    );
};

export default InDeliveryPickupPage;
