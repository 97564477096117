import { Box, Grid } from "@material-ui/core";
import { Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router";
import api from "../../api";
import { Paths } from "../../constants";
import { useAuth } from "../../hooks";
import LoginForm, { LoginFormProps } from "../Forms/LoginForm";

const Authorization: React.FunctionComponent = () => {
    const history = useHistory();
    const { authenticate } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const handleLogin: LoginFormProps["onSubmit"] = async (values) => {
        try {
            await api.login(values);
            authenticate();
            history.push(Paths.HOME.path);
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.non_field_errors || "Не удалось войти", { variant: "error" });
        }
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "calc(100vh - 150px)" }}
        >
            <Grid xs={12} sm={8} md={6} lg={5} xl={3} item>
                <Box component={Paper} p={3} borderRadius={5}>
                    <Typography variant="h5" component="p" align="center" p={2}>
                        Вход
                    </Typography>
                    <LoginForm onSubmit={handleLogin} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Authorization;
