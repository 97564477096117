import {useQuery} from "react-query";
import api from "../../api";
import {OrderStatus} from "../../api/models/Order.model";

const useDeliveryPriceTotal = () => {
    const { data, refetch } = useQuery(
        ["delivery_price_total"],
        () => api.getDeliveryPriceTotal({
            status__in: [OrderStatus.on_way].join(',')
        })
    )
    return {
        deliveryPrice: data,
        refetch
    }
}

export default useDeliveryPriceTotal;