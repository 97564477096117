import React from "react";
import { ModalProps as MuiModalProps } from "@material-ui/core";
import { StoreonModule } from "storeon";
import {
    TakeToWorkOrderModal,
    ConfirmOrderModal,
    CancelOrderModal,
    AssembleOrderModal,
    SubmittedOrderModal,
    ReturnedOrderModal,
    WaitProcessingOrderModal,
    PaidUpOrderModal,
    AddGoodsOrder,
    AddOrderPayment,
    DeletePaymentModal,
    OrderSetModal,
    OrderCreateModal,
    ClosedReturnRequestModal,
    ClosedOrderWithCommentModal,
    CancelReturnRequestModal,
    AddCourier, DeleteCourier, EditGoodsOrderModal, ContextHelp
} from "../../components/Modals";
import ClosedOrderModal from "../../components/Modals/ClosedOrder";
import ReturnPositiveOrder from "../../components/Modals/ReturnPositiveOrder";
import ReadyDeliveryModal from "../../components/Modals/ReadyDeliveryModal";
import DeliveryModal from "../../components/Modals/DeliveryModal";
import UndeliveredOrderModal from "../../components/Modals/UndeliveredOrder";

interface Modal<TProps> {
    title: string;
    component: React.ComponentType<WithModalComponentProps<TProps>>;
    options?: MuiModalProps;
}

export type ModalType =
    | "TAKE_TO_WORK"
    | "CONFIRM_ORDER"
    | "CANCEL_ORDER"
    | "ASSEMBLE_ORDER"
    | "SUBMITTED_ORDER"
    | "RETURNED_ORDER"
    | "WAIT_PROCESSING_ORDER"
    | "PAID_UP_ORDER"
    | "ADD_GOODS_ORDER"
    | "READY_DELIVERY"
    | "ADD_ORDER_PAYMENT"
    | "DELETE_PAYMENT"
    | "DELIVERY"
    | "UNDELIVERED_ORDER"
    | "CLOSED_ORDER"
    | "CLOSED_ORDER_WITH_COMMENT"
    | "BIND_TO_SET"
    | "CREATE_ORDER"
    | "CLOSE_RETURN_REQUEST"
    | "CANCEL_RETURN_REQUEST"
    | "ADD_COURIER"
    | "DELETE_COURIER"
    | "UPDATE_COURIER"
    | "CONTEXT_HELPER"
    | "RETURN_POSITIVE_COMMENT"
    | "EDIT_ORDER_PRODUCT";

type ModalConfig<T extends Record<ModalType, Modal<any>>> = {
    [P in keyof T]: Modal<T[P] extends Modal<infer P> ? P : never>;
};

const createModals = <T extends Record<ModalType, Modal<any>>>(modals: T): ModalConfig<T> => {
    return modals as any;
};

export const Modals = createModals({
    ADD_GOODS_ORDER: {
        title: "Добавить товар",
        component: AddGoodsOrder,
    },
    CONTEXT_HELPER: {
      title: "Контекстная справка",
      component: ContextHelp,

    },
    ADD_COURIER: {
        title: "Добавить курьера",
        component: AddCourier,
    },
    UPDATE_COURIER: {
        title: "Уточнить курьера",
        component: AddCourier
    },
    DELETE_COURIER: {
      title: "Удалить курьера",
      component: DeleteCourier,
    },
    TAKE_TO_WORK: {
        title: "Заказ: Взять в работу",
        component: TakeToWorkOrderModal,
    },
    CONFIRM_ORDER: {
        title: "Заказ: Подтвердить",
        component: ConfirmOrderModal,
    },
    CANCEL_ORDER: {
        title: "Заказ: Отменить",
        component: CancelOrderModal,
    },
    ASSEMBLE_ORDER: {
        title: "Заказ: Сборка",
        component: AssembleOrderModal,
    },
    SUBMITTED_ORDER: {
        title: "Заказ: Доставка",
        component: SubmittedOrderModal,
    },
    RETURNED_ORDER: {
        title: "Заказ: Возврат",
        component: ReturnedOrderModal,
    },
    WAIT_PROCESSING_ORDER: {
        title: "Заказ: Ждать обработки",
        component: WaitProcessingOrderModal,
    },
    PAID_UP_ORDER: {
        title: "Заказ: Подтвердить оплату",
        component: PaidUpOrderModal,
    },
    ADD_ORDER_PAYMENT: {
        title: "Добавление оплаты",
        component: AddOrderPayment
    },
    DELETE_PAYMENT: {
        title: "Удаление оплаты",
        component: DeletePaymentModal
    },
    CLOSED_ORDER: {
        title: "Заказ: Закрыть",
        component: ClosedOrderModal
    },
    RETURN_POSITIVE_COMMENT: {
        title: "Заказ: Товар принят",
        component: ReturnPositiveOrder
    },
    CLOSED_ORDER_WITH_COMMENT: {
        title: "Заказ: Товар не принят",
        component: ClosedOrderWithCommentModal
    },
    UNDELIVERED_ORDER: {
        title: "Заказ: Не доставлен",
        component: UndeliveredOrderModal
    },
    READY_DELIVERY: {
        title: "Готов к доставке",
        component: ReadyDeliveryModal
    },
    DELIVERY: {
        title: "Заказ: Передать в доставку",
        component: DeliveryModal
    },
    BIND_TO_SET: {
        title: "Заказ: Привязать к сету",
        component: OrderSetModal
    },
    CREATE_ORDER: {
        title: "Заказ: Создание",
        component: OrderCreateModal
    },
    CLOSE_RETURN_REQUEST: {
        title: "Возврат: Закрыть заявление",
        component: ClosedReturnRequestModal
    },
    CANCEL_RETURN_REQUEST: {
        title: "Возврат: Отклонить заявление",
        component: CancelReturnRequestModal
    },
    EDIT_ORDER_PRODUCT: {
        title: "Редактирование товара",
        component: EditGoodsOrderModal
    }
});

export type ModalProps<TProps = unknown> = Partial<{ id: number }> & TProps;

export type ModalPropsTypeMap<TKey extends ModalType> = ModalProps<
    ExtractModalProps<typeof Modals[TKey]["component"]>
>;

type ExtractModalProps<TModalProps> = TModalProps extends React.ComponentType<
    WithModalComponentProps<infer S>
>
    ? S
    : never;

export type WithModalComponentProps<TProps = unknown> = {
    onClose(): void;
    modalProps: ModalProps<TProps>;
};

export interface ModalModuleState {
    open: boolean;
    type: ModalType | null;
    props: ModalPropsTypeMap<keyof typeof Modals> | null;
}

export interface ModalModuleEvents {
    "modal/toggle": boolean;
    "modal/set/type": ModalType | null;
    "modal/set/props": ModalPropsTypeMap<keyof typeof Modals> | null;
}

export const modalModule: StoreonModule<ModalModuleState, ModalModuleEvents> = (store) => {
    store.on("@init", () => ({
        open: false,
        type: null,
        props: null,
    }));

    store.on("modal/toggle", (state, value) => ({ open: value }));
    store.on("modal/set/type", (state, value) => ({ type: value }));
    store.on("modal/set/props", (state, value) => ({ props: value }));
};
