import CashlessPaidTable from "../Tables/CashlessPaidTable";

const CashlessPaid: React.FunctionComponent<any> = () => {
    return (
        <div>
            <CashlessPaidTable />
        </div>
    )
}

export default CashlessPaid;
