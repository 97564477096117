import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {useQuery} from "react-query";
import api from "../../api";
import React from "react";
import {SelectProps} from "@mui/material/Select/Select";

const CourierSelect: React.FunctionComponent<SelectProps> = ({ onChange, value, label, name, ...props }) => {
    const { data } = useQuery(
        [],
        () => api.getCouriersList({ page_size: 200 })
    );
    return (
        <FormControl margin={'normal'} fullWidth>
            {label ? <InputLabel id={name}>{label}</InputLabel> : null}
            <Select
                sx={{ fontSize: "12px", maxHeight: "45px" }}
                margin='dense'
                labelId={name}
                id={name}
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                {...props}
            >
                {data?.results.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export {
    CourierSelect
}