import { Exclude, Expose, Transform, Type } from "class-transformer";
import { Order, User } from ".";
import dayjs from "../../utils/dayjs";

export enum RequestStatus {
    new = 0,
    closed = 1,
    package_sent = 2,
    canceled = 3
}

@Exclude()
class ReturnRequest {
    @Expose() id: number;

    @Expose() comment: string;

    @Expose() sum: string;

    @Expose() status: RequestStatus;

    @Expose({ name: "track_number" }) trackNumber: string;

    @Expose() items: any;

    @Expose({ name: "new_order" }) newOrder: any;

    @Expose({ name: 'refund_amount' }) refundAmount: number;

    @Expose()
    public static getStatusName(statusId: number): string {
        const statuses: { [key in RequestStatus]: string } = {
            0: "Новый",
            1: "Закрыт",
            2: "Посылка отправлена",
            3: "Отменен"
        };
        return statuses[statusId];
    }

    @Expose()
    get statusName(): string {
        return ReturnRequest.getStatusName(this.status);
    }

    @Expose({ name: "created_at" })
    @Transform(({ value }) => new Date(value))
    createdAt: Date;

    @Expose({ name: "updated_at" })
    @Transform(({ value }) => new Date(value))
    updatedAt: Date;

    public static dateFormat(date: Date) {
        return dayjs(date).format("DD MMMM YYYY HH:mm");
    }

    @Expose({ name: "user_obj" })
    @Type(() => User)
    user: User;

    @Expose({ name: "order_obj" })
    @Type(() => Order)
    order: Order;

    @Expose({ name: "is_partial_return" })
    isPartialReturn: boolean;

    @Expose({ name: "is_exchanged" })
    isExchanged: boolean;
}

export default ReturnRequest;
