import React from "react";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { Box, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import { OrderStatus } from "../../../api/models/Order.model";
import api from "../../../api";

type ModalProps = { email: string; onUpdate(): void };

const schema = yup.object({
    commentaryForManager: yup.string().required("Обязательное поле"),
}).required();

type ClosedOrderModalProps = WithModalComponentProps<ModalProps>;

const ClosedOrderWithCommentModal: React.FunctionComponent<ClosedOrderModalProps> = ({
    onClose,
    modalProps,
}: ClosedOrderModalProps) => {
    const { id, email, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const {
        register,
        handleSubmit,
        formState:{ errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            commentaryForManager: "",
            status: OrderStatus.closed
        }
    });
    const handleSave = async (data) => {
        if (!id) return;
        try {
            await api.updateOrderDetail(id, { ...data })
            enqueueSnackbar("Причина отказа в приемке товара успешно сохранена", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Товар пользователя <b>{email}</b> с номером заказа <b>{id}</b> не принят. 
                </Typography>
                <Box gridColumn="span 9" sx={{ marginTop: "30px" }}>
                    <TextField
                        rows={3}
                        multiline
                        fullWidth
                        label="Комментарий для менеджера"
                        {...register("commentaryForManager", { required: true })}
                        error={Boolean(errors.commentaryForManager?.message)}
                        helperText="Укажите причину отказа в приемке товара"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSubmit(handleSave)} color="primary" variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default ClosedOrderWithCommentModal;
