import SunEditor, { SunEditorReactProps } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { forwardRef } from "react";

type RichTextEditorProps = SunEditorReactProps & {
    value: string;
};

const RichTextEditor = forwardRef<HTMLElement, RichTextEditorProps>(
    ({ name, value, onChange, height = 300, ...props }, ref) => {
        const handleChange = (value) => {
            if (onChange) onChange(value);
        };

        return (
            <SunEditor
                {...props}
                lang="ru"
                name={name}
                width="100%"
                height={height}
                defaultValue={value}
                onChange={handleChange}
                setOptions={{
                    colorList: ["#ff0000", "#ff5e00", "#ffe400", "#abf200"],
                    buttonList: [
                        [
                            "undo",
                            "redo",
                            "font",
                            "fontSize",
                            "formatBlock",
                            "paragraphStyle",
                            "blockquote",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "textStyle",
                            "removeFormat",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "lineHeight",
                            "table",
                            "link",
                            "image",
                            "video",
                            "audio",
                            "imageGallery",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                            "print",
                            "save",
                        ],
                    ],
                }}
            />
        );
    }
);

export default RichTextEditor;
