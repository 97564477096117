import React from "react";
import { generatePath, useHistory } from "react-router";
import {Box, Divider, Grid } from "@mui/material";
import DataTable, { DataTableColumn, DataTableProps, RowAction } from "../../Common/DataTable";
import { useModal, useTable } from "../../../hooks";
import { Paths } from "../../../constants";
import { Order } from "../../../api/models";
import { OrderStatus, DeliveryMethod, DeliveryServices } from "../../../api/models/Order.model"
import api from "../../../api";
import { Link } from "react-router-dom";
import Row from '../../Common/Row';
import dayjs from "../../../utils/dayjs";
import { TableHeadStyles, } from "../../../styles";
import {numToRubFormat} from '../../../utils';

const InDeliveryDeliveryServices: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const history = useHistory();

    const handleConfirm: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("DELIVERY", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
        });
    };

    const handleCancel: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CANCEL_ORDER", { id: rowData.id, email: rowData.email, onUpdate: updateData });
    };

    const toOrderDetail = async (e, rowData) => {
        const path = generatePath(Paths.ORDER_DETAIL.path, { id: rowData.id})
        history.push(path)
    }

    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            filtering: false,
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "order_number",
            filtering: false,
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "user_info",
            filtering: false,
            title: "Получатель",
            sorting: false,
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.fullName}</div>
                        <div>{rowData.email}</div>
                        <div>
                            <b>{rowData.phone}</b>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "address",
            filtering: false,
            title: "Адрес",
            render: (rowData) => {
                return (
                    <div>
                        <div>{rowData.cityDisplay}</div>
                        <div>{rowData.street}</div>
                        <div>{rowData.house} {rowData.hullBuilding} {rowData.apartmentOffice}</div>
                    </div>
                )
            }
        },
        {
            field: "orders",
            filtering: false,
            title: "Другие заказы",
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "status",
            filtering: false,
            title: "Статус",
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        {
            field: "total_price",
            filtering: false,
            title: "Сумма товаров",
            render: (rowData) => numToRubFormat(rowData.totalPrice),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_services",
            filtering: true,
            lookup: { 0: "Курьерская служба не указана", 1: "СДЭК", 2: "Почта России", 3: "DALLI", 4: "Курьер"},
            title: "Сервис доставки",
            render: (rowData) => rowData.deliveryServicesDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_cost",
            filtering: false,
            title: "Сумма доставки",
            render: (rowData) => numToRubFormat(rowData?.deliveryObj?.cost),
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_date",
            filtering: false,
            title: "Дата доставки",
            render: (rowData) => {
                const date = dayjs(rowData.deliveryDate);
                if(date.isValid()) {
                    return date.format("DD.MM.YYYY")
                }
                return null
            },
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
    ];

    const getOrders = (params) => api.getAllOrders({
        ...params,
        status__in: [OrderStatus.ready_delivery, OrderStatus.paid_up].join(','),
        delivery_method: DeliveryMethod.delivery,
    })

    return (
        <>
            <DataTable<Order>
                title="Службы доставки"
                columns={columns}
                fetchFn={getOrders}
                sorting={false}
                options={{
                    headerStyle: { ...TableHeadStyles }
                }}
                customActions={[
                    {
                        tooltip: "Отправить",
                        name: "send",
                        onClick: handleConfirm,
                        icon: "check",
                        color: "primary",
                    },
                    {
                        tooltip: "Уточнить",
                        name: "check",
                        onClick: toOrderDetail,
                        icon: "visibility",
                        color: "primary",
                    },
                    {
                        tooltip: "Отменить",
                        name: "cancel",
                        onClick: handleCancel,
                        icon: "cancel",
                        color: "primary",
                    },
                ]}
            />
        </>
    );
};

export default InDeliveryDeliveryServices;
