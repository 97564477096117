import React, { useEffect, useState } from "react";
import DataTable, { DataTableColumn, RowAction } from "../../Common/DataTable";
import { useModal, useTable } from "../../../hooks";
import { Order } from "../../../api/models";
import api from "../../../api";
import {OrderItem, OrderStatus} from "../../../api/models/Order.model";
import { TableCellStyles, TableHeadStyles, } from "../../../styles";
import { Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Check } from "@material-ui/icons";

const InDeliveryPickup: React.FC = () => {
    const { openModal } = useModal();
    const { updateData } = useTable();
    const [goodsList, setGoodsList] = useState<any>([]);

    const getOrdersItems = (params = {}) => {
        api.getAllOrders({ ...params, status__in: [OrderStatus.ready_delivery, OrderStatus.paid_up].join(','), delivery_method: 1 })
            .then(res => {
                const { results } = res;
                const goods: any = []
                results.map(el => el.items.map((item: any) => goods.push({ ...item, orderNumber: el.orderNumber })));
                setGoodsList(goods)
            })
            .catch(err => setGoodsList([]))
    }

    useEffect(() => {
        getOrdersItems()
    }, [])

    const handleReceivedConfirm: RowAction<Order>["onClick"] = async (e, rowData) => {
        if (rowData instanceof Array) return;
        openModal("CLOSED_ORDER", {
            id: rowData.id,
            email: rowData.email,
            onUpdate: updateData,
        });
    };

    const columns: DataTableColumn<Order>[] = [
        {
            field: "id",
            filtering: false,
            title: "#",
            defaultSort: "desc",
        },
        {
            field: "site_name",
            filtering: false,
            title: "Сайт",
            sorting: false,
            render: (rowData) => rowData.siteName,
        },
        {
            field: "order_number",
            filtering: false,
            title: "№ Заказа",
            render: (rowData) => rowData.orderNumber,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "status",
            filtering: false,
            title: "Статус",
            render: (rowData) => Order.getStatusName(rowData.status),
        },
        {
            field: "city_name",
            filtering: false,
            title: "Город",
            render: (rowData) => rowData.cityName,
        },
        {
            field: "payment_method",
            filtering: false,
            title: "Способ оплаты",
            render: (rowData) => rowData.paymentMethodDisplay,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        },
        {
            field: "delivery_method",
            filtering: false,
            title: "Доставка",
            render: (rowData) => rowData.deliveryMethodDisplay,
        },
        {
            field: "phone",
            filtering: false,
            title: "Телефон",
        },
        {
            field: "full_name",
            filtering: false,
            title: "Полное имя",
            render: (rowData) => rowData.fullName,
            headerStyle: {
                whiteSpace: "nowrap"
            }
        }
    ];

    const getOrders = (params) => api.getAllOrders({ ...params, status__in: [OrderStatus.ready_delivery, OrderStatus.paid_up].join(','), delivery_method: 1 })

    return (
        <>
            <DataTable<Order>
                title="Самовывоз"
                columns={columns}
                fetchFn={getOrders}
                sorting
                filtering={false}
                customActions={[
                    {
                        tooltip: "Получен",
                        name: "received",
                        onClick: handleReceivedConfirm,
                        icon: "check",
                        color: "primary",
                    },
                ]}
                options={{
                    headerStyle: { ...TableHeadStyles }
                }}
            />
            <div style={{ marginTop: "40px" }}>
                <TableContainer sx={{ marginTop: "20px" }} component={Card}>
                    <Table>
                        <TableHead sx={{ ...TableHeadStyles }}>
                            <TableRow>
                                <TableCell sx={{ ...TableCellStyles }}>Артикул</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Количество</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Номер заказа</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {goodsList?.length ? (
                                <>
                                    {goodsList.map(el => (
                                        <TableRow key={el?.id}>
                                            <TableCell>{el?.product?.attr?.sku?.value}</TableCell>
                                            <TableCell>{el?.count}</TableCell>
                                            <TableCell>{el?.orderNumber}</TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">Ничего не найдено</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default InDeliveryPickup;
