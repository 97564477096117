import { Exclude, Expose, Transform, Type } from "class-transformer";
import dayjs from "../../utils/dayjs";

@Exclude()
class CreateOrder {
    @Expose({ name: "fullName" }) full_name: string;

    @Expose() phone: string;

    @Expose() status: number;

    @Expose({ name: "cityName" }) city_name: string;

    @Expose({ name: "hullBuilding" }) hull_building: string;

    @Expose({ name: "totalPrice" }) total_price: string;

    @Expose({ name: "cityForAdmin"})
    @Transform(({ obj }) => {
      return obj.cityName
    })
    city_for_admin: string;

    @Expose() index: string;

    @Expose() region: string;

    @Expose() street: number;

    @Expose() shop: number;

    @Expose() house: number;

    @Expose() courier: number;

    @Expose() set: number;

    @Expose({ name: "apartmentOffice" }) apartment_office: string;

    @Expose({ name: "paymentMethodDisplay" }) payment_method_display: string;

    @Expose({ name: "paymentMethod" }) payment_method: number;

    @Expose({ name: "deliveryServices" }) delivery_services: number;

    @Expose({ name: "costOfDelivery" }) cost_of_delivery: number;

    @Expose({ name: "deliveryDate" })
    @Transform(({ value }) => {
      if(value) {
        const date = new Date(value)
        return dayjs(date).format("DD-MM-YYYY")
      }
      return null
    })
    delivery_date: string | null;

    @Expose({ name: "commentToTheCourier" }) comment_to_the_courier: string;

    @Expose({ name: "commentaryForClient" }) commentary_for_client: string;

    @Expose({ name: "commentaryForManager"}) commentary_for_manager: string;

    @Expose({ name: "commentaryForMagazine" }) commentary_for_magazine: string;

    @Expose({ name: "deliveryMethod" }) delivery_method: number;

    @Expose({ name: "deliveryTimeFrom" })
    @Transform(({ value }) => {
      if(value) {
        return dayjs(value).format("HH:mm")
      }
      return null
    })
    delivery_time_from: string | null;

    @Expose({ name: "deliveryTimeTo" })
    @Transform(({ value }) => {
      if(value) {
        return dayjs(value).format("HH:mm")
      }
      return null
    })
    delivery_time_to: string | null;
}

export default CreateOrder;
