import React, { useEffect, useState } from "react";
// import { Autocomplete } from '@mui/material';
import AsyncSelect from 'react-select/async';
import api from "../../api";
import Input from "../Common/Input/Input";

interface OptionTypes {
  address: string | null,
  email: string,
  firstName: string,
  fullName: string,
  id: number,
  label: string,
  lastName: string,
  phone: string | null,
  username: string,
}

const UserAutocomplete: React.FunctionComponent<any> = (props) => {
  const {
    setValue,
    value,
    name,
    label = "",
    id,
    error,
    helperText,
    handleChange
  } = props;

  const promiseOptions = (inputValue: string) => { 
    return new Promise<any[]>((resolve) => {
      api.getUsersList({ search: inputValue })
        .then((data) => { 
            resolve(data.results.map((item) => ({
              label: `${item.id} - ${item.firstName} ${item.lastName} (${item.phone})`,
              value: {
                ...item,
                /**
                 * The method above is to avoid React Select from spamming
                 * the console with key errors since it is using this object
                 * as a value for every select option
                 **/
                toString: () => item.id,
              }
              
            })));
        })
    });
  }

  const handleChangeUser = (value) => {
    handleChange(value)
    if(setValue) {
      setValue(name, value.value.fullName)
    }
  }

  return (
    <>
      <AsyncSelect
        styles={{ menuPortal: base => ({ ...base, zIndex: 1400 }) }}
        menuPortalTarget={document.body}
        value={value}
        placeholder={label}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        onChange={handleChangeUser}
      />
      {error ? error : ''}
      {helperText ? helperText : ''}
    </>
  )
}

export default UserAutocomplete;