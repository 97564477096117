import React from "react";
import { Autocomplete, Chip } from '@mui/material';
import Input from "../Common/Input/Input";
import { useQuery } from "react-query";
import { apiCore, Brand } from "../../api";

interface BrandAutocompleteProps {
    name: string;
    label?: string;
    value?: Brand[];
    onChange(name: string, value: Brand[]): void
}

const BrandAutocomplete: React.FunctionComponent<BrandAutocompleteProps> = (props: BrandAutocompleteProps) => {
  const { value = [], onChange, label, name } = props;

  const { data: options, isFetching } = useQuery(['brands'], () => apiCore.getBrands())

  const handleChange = (_, newValue: Brand | any): void => {
    if (newValue) {
        onChange(name, newValue)
    }
  }

  const handleDelete = (index: number): void => {
    const val = [...value];
    const firstPart = val.slice(0, index)
    const secondPart = val.slice(index + 1);
    const newValue = firstPart.concat(secondPart)
    onChange(name, newValue)
  }

  return (
    <Autocomplete
      multiple
      size="small"
      options={options?.result ?? []}
      loading={isFetching}
      value={value}
      getOptionLabel={option => option?.title}
      onChange={handleChange}
      fullWidth
      disableClearable
      renderTags={(value: readonly Brand[], getTagProps) =>
        value.map((option: Brand | any, index: number) => {
          return (
            <Chip
              variant="outlined"
              label={option.title}
              {...getTagProps({ index })}
              onDelete={() => handleDelete(index)}
            />
          )
        })
      }
      renderInput={(params) => (
        <Input
          {...params}
          margin="normal"
          label={label}
          fullWidth
        />
      )}
    />
  )
}

export default BrandAutocomplete;