import React from "react";
import { generatePath, useHistory } from "react-router";
import { Card, Table, TableContainer, TableBody, TableHead, TableCell, TableRow, Box, Button } from "@mui/material";
import DataTable, { DataTableColumn, DataTableProps, RowAction } from "../../Common/DataTable";
import { useModal, useTable } from "../../../hooks";
import { Paths } from "../../../constants";
import { Order } from "../../../api/models";
import api from "../../../api";
import { Link } from "react-router-dom";
import Row from '../../Common/Row';
import { useSnackbar } from "notistack";
import { DeliveryMethod, DeliveryServices, OrderStatus } from "../../../api/models/Order.model";
import { useQuery } from "react-query";
import { CourierSelect } from "../../Select/Courier";
import { Check } from "@material-ui/icons";
import { TableCellStyles, TableHeadStyles } from "../../../styles";

const InDelivery: React.FC = () => {
    const { openModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { data: ordersDalii, refetch: refetchDalli } = useQuery(
        ["order", DeliveryServices.dalli],
        () => api.getAllOrders({
            ordering: "set",
            status__in: [OrderStatus.ready_delivery, OrderStatus.paid_up].join(','),
            delivery_method: DeliveryMethod.delivery,
            delivery_services: DeliveryServices.dalli
        })
    )

    const { data: ordersCourier, refetch: refetchCourier } = useQuery(
        ["order", DeliveryServices.courier],
        () => api.getAllOrders({
            ordering: "set",
            status__in: [OrderStatus.ready_delivery, OrderStatus.paid_up].join(','),
            delivery_method: DeliveryMethod.delivery,
            delivery_services: DeliveryServices.courier
        })
    )

    const setCourier = (e, id) => {
        api.updateOrderDetail(id, { courier: e.target.value})
            .then(res => {
                refetchCourier()
                enqueueSnackbar("Курьер успешно назначен", { variant: "success" });
            })
            .catch(err => {
                const { data } = err?.response;
                enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
            })
    }

    const changeStatusOrder = (id, email, onUpdate) => {
        openModal("DELIVERY", {
            id,
            email,
            onUpdate,
        });
    }

    const addSet = () => {
        api.setNewSet()
            .then(res => {
                enqueueSnackbar(`Сет ${res.id} успешно создан`, { variant: "success" });
            })
            .catch(err => {
                const { data } = err?.response;
                enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
            })
    }

    const onUpdate = () => {
        refetchDalli();
        refetchCourier();
    }

    const openSetModal = (e, {id, email}) => {
        openModal("BIND_TO_SET", {
            id,
            email,
            onUpdate,
        });
    }

    return (
        <>
            <Box sx={{ marginBottom: 3 }}>
                <Row>
                    <Box gridColumn="span 2">
                        <Link to={Paths.IN_DELIVERY_DELIVERY_SERVICES.path}>Службы доставки</Link>
                    </Box>
                    <Box gridColumn="span 2">
                        <Link to={Paths.IN_DELIVERY_PICKUP.path}>Самовывоз</Link>
                    </Box>
                    <Box gridColumn="span 2">
                        <Button variant="contained" onClick={addSet}>Добавить сет</Button>
                    </Box>
                </Row>
            </Box>
            <h4>Новые сеты для доставки по Москве DALLI</h4>
            <TableContainer sx={{ marginTop: "20px" }} component={Card}>
                <Table>
                    <TableHead sx={{ ...TableHeadStyles }}>
                        <TableRow>
                            <TableCell sx={{ ...TableCellStyles }}>Номер сета</TableCell>
                            <TableCell sx={{ ...TableCellStyles }}>Заказ</TableCell>
                            <TableCell sx={{ ...TableCellStyles }}>Сумма</TableCell>
                            <TableCell sx={{ ...TableCellStyles }}>Статус</TableCell>
                            <TableCell sx={{ ...TableCellStyles }}>Документы</TableCell>
                            <TableCell sx={{ ...TableCellStyles }}>Статус для доставки</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ordersDalii?.results?.length ? (
                            <>
                                {ordersDalii?.results?.map(el => (
                                    <TableRow key={el.id}>
                                        <TableCell>{el?.set}</TableCell>
                                        <TableCell>
                                            <Button onClick={e => openSetModal(e, { id: el.id, email: el.email})}>
                                                {el.orderNumber}
                                            </Button>
                                        </TableCell>
                                        <TableCell>{el.totalPrice}</TableCell>
                                        <TableCell>{Order.getStatusName(el.status)}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Button onClick={() => changeStatusOrder(el.id, el.email, refetchDalli)} title="В доставке">
                                                <Check />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">Ничего не найдено</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ marginTop: "40px" }}>
                <h4>Новые сеты для доставки курьером по Москве</h4>
                <TableContainer sx={{ marginTop: "20px" }} component={Card}>
                    <Table>
                        <TableHead sx={{ ...TableHeadStyles }}>
                            <TableRow>
                                <TableCell sx={{ ...TableCellStyles }}>Номер сета</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Заказ</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Сумма</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Статус</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Курьер</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Документы</TableCell>
                                <TableCell sx={{ ...TableCellStyles }}>Статус для доставки</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ordersCourier?.results?.length ? (
                                <>
                                    {ordersCourier?.results?.map(el => (
                                        <TableRow key={el.id}>
                                            <TableCell>{el?.set}</TableCell>
                                            <TableCell>{el.orderNumber}</TableCell>
                                            <TableCell>{el.totalPrice}</TableCell>
                                            <TableCell>{Order.getStatusName(el.status)}</TableCell>
                                            <TableCell>
                                                <CourierSelect
                                                    onChange={(e) => setCourier(e, el.id)}
                                                    label="Курьер"
                                                    name="courier"
                                                    value={el.courier}
                                                />
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                <Button onClick={() => changeStatusOrder(el.id, el.email, refetchCourier)} title="В доставке">
                                                    <Check />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">Ничего не найдено</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default InDelivery;