import React, { useEffect, useState } from 'react';
import { useParams, generatePath } from 'react-router';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import DataTable from '../../Common/DataTable';
import dayjs from "../../../utils/dayjs";
import { Paths } from '../../../constants';
import api from '../../../api';
import { TableHeadStyles, } from '../../../styles';

const OrderChangelogTable: React.FunctionComponent = () => {
  const { id } : any = useParams();
  const [orderPath, setOrderPath] = useState('');

  useEffect(() => {
    if(id) {
      const path = generatePath(Paths.ORDER_DETAIL.path, { id })
      setOrderPath(path)
    }
  }, [id])

  if(id) {
    const fetchFn = (params) => {
      return api.getOrderChangelog({ object_id: Number(id), page: params.page, page_size: params.page_size })
    }

    return (
      <>
        <Button
          href={orderPath}
          sx={{ marginBottom: "30px" }}
          variant="outlined"
          startIcon={<ArrowBack />}
          >
          Вернуться к заказу
        </Button>
        <DataTable
          title="Журнал"
          fetchFn={fetchFn}
          filtering={false}
          sorting={false}
          search={false}
          columns={[
            {
              field: "changed",
              filtering: false,
              title: "Дата изменения",
              render: (data) => {
                if(data.changed) {
                  return (
                    <span>{dayjs(data.changed).format("DD.MM.YYYY HH:mm")}</span>
                  )
                } else {
                  return <span>Данные не найдены</span>
                }
              },
              headerStyle: {
                whiteSpace: "nowrap"
              }
            },
            {
              field: "user",
              filtering: false,
              title: "Пользователь",
              render: (data) => {
                if(data.user) {
                  return (
                    <span>{data.user}</span>
                  )
                } else {
                  return <span>Данные не найдены</span>
                }
              }
            },
            {
              field: "changeFormat",
              filtering: false,
              title: "Изменения",
              render: (data) => {
                if(data.changeFormat) {
                  return (
                    <span dangerouslySetInnerHTML={{ __html: data.changeFormat }} />
                  )
                } else {
                  return <span>Данные не найдены</span>
                }
              }
            }
          ]}
          options={{
            headerStyle: { ...TableHeadStyles }
          }}
        />
      </>
    )
  }
  return null;
}

export default OrderChangelogTable