import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";

type ModalProps = { email: string; returnRequestId?: any; onUpdate(): void };

type CancelOrderModalProps = WithModalComponentProps<ModalProps>;

const CancelReturnRequestModal: React.FunctionComponent<CancelOrderModalProps> = ({
    onClose,
    modalProps,
}: CancelOrderModalProps) => {
    const { id, email, returnRequestId, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (!returnRequestId) return;

        try {
            await api.transitionReturnRequestRejected(returnRequestId);
            enqueueSnackbar("Успешно отменено", { variant: "success" });
            onUpdate();
            onClose();
        } catch (err: any) {
            const { data } = err?.response;
            enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
        }
    };

    return (
        <>
            <DialogContent>
                <Typography>
                    Отменить заявку пользователя <b>{email}</b> с номером заказа <b>{id}</b>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Отменить
                </Button>
            </DialogActions>
        </>
    );
};

export default CancelReturnRequestModal;
