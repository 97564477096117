import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { WithModalComponentProps } from "../../../store/StoreModules/ModalModule";
import api from "../../../api";
import { Typography } from "@mui/material";
import { useAuth } from "../../../hooks";
import { OrderSetsSelect } from "../../Select/OrderSets";

type ModalProps = { email: string; onUpdate(): void };

type DeliveryModalProps = WithModalComponentProps<ModalProps>;

const OrderSetModal: React.FunctionComponent<DeliveryModalProps> = ({
    onClose,
    modalProps,
}: DeliveryModalProps) => {
    const { user } = useAuth();
    const { id, email, onUpdate } = modalProps;
    const { enqueueSnackbar } = useSnackbar();
    const [orderSet, setOrderSet] = useState<any>(null)

    const handleSave = async () => {
        if (!id || !user) return;
        api.updateOrderDetail(id, { set: orderSet.value })
            .then(res => {
                enqueueSnackbar("Заказ успешно привязан к сету", { variant: "success" });
                onUpdate();
                onClose();
            })
            .catch(err => {
                const { data } = err?.response;
                enqueueSnackbar(data?.detail || "Произошла ошибка", { variant: "error" });
            })
    };

    const handleChange = (value) => {
        setOrderSet(value);
    }

    return (
        <>
            <DialogContent>
                <OrderSetsSelect label="Сет" onChange={handleChange} name="set" value={orderSet} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Привязать
                </Button>
            </DialogActions>
        </>
    );
};

export default OrderSetModal;
