import { createTheme } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

const LinkBehavior = React.forwardRef<
    any,
    Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
    const { href, ...other } = props;
    // Map href (Material-UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme(
    {
        typography: {
            fontSize: 12,
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "4px 8px",
                    },
                    head: {
                        padding: "4px 8px",
                    },
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
            },
        },
    },
    ruRU
);

export default theme;
