import React from "react";
import { TextField } from "@mui/material";

const Input = React.forwardRef<any, any>((props, ref) => {
  return (
      <TextField size="small" ref={ref} {...props}>
        {props.children}
      </TextField>
  )
})

export default Input